import { combineReducers } from "redux";

import auth from "./auth";
import register from "./register";
import clinic from "./clinic";
import user from "./user";
import careProgram from "./careProgram";
import chat from "./chat"
import call from "./pickup"
import instantVideo from './instantVideo'

const reducers = combineReducers({ auth, register, clinic, user, careProgram,chat ,call,instantVideo});

export default reducers;
