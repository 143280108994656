export const styles = (theme) => ({
    root: {
        width: "100%",
        backgroundColor: "#F4F4F4",
        borderRadius: "8px",
        "& label": {
            color: `${theme.palette.input.labelMain} !important`,
            transform: "translate(14px, 18px) scale(1)",
            "&.MuiInputLabel-shrink": {
                transform: "translate(14px, 6px) scale(0.75)"
            }
        },
        "& input": {
            padding: "22px 15px 12px",
            color: theme.palette.input.textMain,
            fontSize: "16px",
            fontWeight: "600",
            "&::placeholder":{
                ['@media(max-width:1366px)']:{
                    fontSize:14
                }
            }
        },
        "& fieldset, &:hover fieldset": {
            borderRadius: "8px",
            borderColor: `#F4F4F4 !important`
        }
    }
});