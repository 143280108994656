import * as actionTypes from "../actions/actionTypes";
const initialstate={
instantdata:{},
bookedId:'',
onCall:false,
}
const instantVideo =(state = initialstate, action)=>{
    switch(action.type)
    {
        case actionTypes.UPDATE_INST_INFO :
        return {...state,instantdata:action.payload} ;

        case actionTypes.UPDATE_BOOK_ID :
        return {...state,bookedId:action.payload}

        case actionTypes.UPDATE_CALL_INFO:
        return {...state,onCall:action.payload}
        
        default :
        return state ;
    }
}

export default instantVideo;