export const SEND_OTP_URL = "/api/v1/register";
export const VERIFY_OTP_URL = "/api/v1/verify-otp";
export const CLINIC_SERVICE_URL = process.env.REACT_APP_CLINIC_BASEURL + "/api/v1/clinics/";
export const DOCTOR_SEARCH_URL = process.env.REACT_APP_DOCTORS_BASEURL + "/api/v1/users/";
export const DIABETIC_COACH_BASEURL = process.env.REACT_APP_DIABETIC_COACH_BASEURL + "/api/v1/diabetic-coaches/";
export const DIETITIAN_BASEURL = process.env.REACT_APP_DIETITIAN_BASEURL + "/api/v1/dietitians/";
export const DIETITIAN_AUTHURL = process.env.REACT_APP_DIETITIAN_AUTHURL + "/api/v1/dietitians/";
export const CLINIC_DIABETIC_COACH_SERVICE = process.env.REACT_APP_CLINIC_DIABETIC_COACH_BASEURL + "/api/v1/clinics/";
export const CLINIC_DIETITIAN_SERVICE = process.env.REACT_APP_CLINIC_DIETITIAN_BASEURL + "/api/v1/clinics/";
export const CHAT_ROOM_SERVICE_URL = process.env.REACT_APP_CHAT_BASEURL + "/api/v1/chat-rooms/";
export const CHAT_USERS_SERVICE_URL = process.env.REACT_APP_CHAT_BASEURL + "/api/v1/chat-users/";
export const CONSULTATION_SERVICE_BASEURL = process.env.REACT_APP_CONSULTATION_BASEURL + "/api/v1/consultations/";
export const MEDICINE_SERVICE_BASEURL =
  process.env.REACT_APP_CONSULTATION_BASEURL + "/api/v1/prescriptions/autocomplete/medicines/brands";
export const PRESCRIPTION_SERVICE_BASEURL =
  process.env.REACT_APP_PRSC_SERVICE_URL + "/api/v1/health-os/autocomplete/medicines/brands";
export const CONSULTATION_PATIENT_SERVICE_BASEURL = process.env.REACT_APP_CONSULTATION_BASEURL + "/api/v1/users/";
export const CONSULTATION_SERVICE_URL = process.env.REACT_APP_CONSULTATION_BASEURL + "/api/v1/clinics/";
export const CONSULTATION_REQUEST_SERVICE_BASEURL =
  process.env.REACT_APP_CONSULTATION_BASEURL + "/api/v1/consultation-requests/";
export const CLINIC_DOCTOR_BASEURL = process.env.REACT_APP_CLINIC_BASEURL + "/api/v1/clinic-doctors/";
export const CLINIC_ADMINISTRATOR_BASEURL = process.env.REACT_APP_CLINIC_BASEURL + "/api/v1/clinic-administrators/";
export const MEDALLTESTS_SERVICE_BASEURL = process.env.REACT_APP_CLINIC_BASEURL + "/api/v1/clinics";
export const PATIENT_CARETAKER_BASEURL = process.env.REACT_APP_CARETAKER_BASEURL + "/api/v1/users/";
export const CARE_PROGRAM_SERVICE_BASEURL =
  process.env.REACT_APP_CARE_PROGRAM_SERVICE_BASEURL + "/api/v1/care-programmes/";
export const UPDATE_CARE_PROGRAM_PLAN_SERVICE_BASEURL =
  process.env.REACT_APP_CARE_PROGRAM_SERVICE_BASEURL + "/api/v1/care-programme-plans/";
export const CARE_PROGRAM_PLAN_TIER_SERVICE_URL =
  process.env.REACT_APP_CARE_PROGRAM_SERVICE_BASEURL + "/api/v1/care-programme-plan/tier";
export const CLINIC_CARE_PROGRAM_SERVICE_BASEURL =
  process.env.REACT_APP_CARE_PROGRAM_SERVICE_BASEURL + "/api/v1/clinics/";
export const USER_CARE_PROGRAM_PLAN_SERVICE_BASEURL =
  process.env.REACT_APP_USER_CARE_PROGRAM_PLAN_SERVICE_BASEURL + "/api/v1/users/";
export const CARE_PROGRAM_PLAN_SERVICE_BASEURL =
  process.env.REACT_APP_USER_CARE_PROGRAM_PLAN_SERVICE_BASEURL + "/api/v1/user-care-programme-plans/";
export const DATA_PLATFORM_SERVICE_BASEURL = process.env.REACT_APP_DATA_PLATFORM_SERVICE + "/api/v1/vitals";
export const DATA_PLATFORM_QUERY_SERVICE_BASEURL =
  process.env.REACT_APP_DATA_PLATFORM_SERVICE + "/api/v1/generic-vitals/query";
export const DATA_PLATFORM_QUERY_SERVICE_CHARTS_BASEURL =
  process.env.REACT_APP_DATA_PLATFORM_SERVICE + "/api/v1/generic-vitals/query/chart";
export const PATIENT_ONBOARDING_BASEURL = process.env.REACT_APP_ONBOARDING_SERVICE + "/api/v1/";
export const DEPARTMENT_SERVICE_BASEURL = process.env.REACT_APP_DEPARTMENT_SERVICE + "/api/v1/departments";
export const QUESTIONS_SERVICE_BASEURL = process.env.REACT_APP_QUESTIONS_SERVICE_BASEURL + "/api/v1/questions";
export const CARE_PROGRAM_QUESTION_SERVICE_BASEURL = process.env.REACT_APP_CARE_PROGRAM_SERVICE_BASEURL + "/api/v1/";
export const ONBOARDING_QUESTION_BULK_CREATE_BASEURL =
  process.env.REACT_APP_ONBOARDING_SERVICE + "/api/v1/onboarding-questions/bulk-create";
export const ONBOARDING_QUESTION_BULK_EDIT_BASEURL =
  process.env.REACT_APP_ONBOARDING_SERVICE + "/api/v1/onboarding-questions/bulk-edit";
export const QUESTION_BULK_CREATE_BASEURL =
  process.env.REACT_APP_CARE_PROGRAM_SERVICE_BASEURL + "/api/v1/care-programmes/";
export const PRESCRIPTION_BASEURL = process.env.REACT_APP_PATIENT_BASEURL + "/api/v1/users/";
export const QUICK_CHECK_BASEURL =
  process.env.REACT_APP_CARE_PROGRAM_SERVICE_BASEURL + "/api/v1/care-programme-quick-checks/";
export const VERIFY_USER_BASEURL = process.env.REACT_APP_CLINIC_BASEURL + "/api/v1/clinic-users/";
export const RESEND_OTP_BASEURL = process.env.REACT_APP_AUTH_BASEURL + "/api/v1/resend-otp";
export const OTP_BASEURL = process.env.REACT_APP_AUTH_BASEURL + "/api/v1/account/password/reset-otp";
export const UPDATE_PRESCRIPTION = process.env.REACT_APP_CONSULTATION_BASEURL + "/api/v1/prescriptions/";
export const FORGOT_PASSWORD_BASEURL = process.env.REACT_APP_AUTH_BASEURL + "/api/v1/account/password/";
export const TAG_BASEURL = process.env.REACT_APP_QUESTIONS_SERVICE_BASEURL + "/api/v1/questionnaires";
export const TAG_QUESTION_BULK_EDIT_BASEURL =
  process.env.REACT_APP_QUESTIONS_SERVICE_BASEURL + "/api/v1/questionnaire-questions/bulk-edit";
export const USR_TAG_BASEURL = process.env.REACT_APP_QUESTIONS_SERVICE_BASEURL + "/api/v1/users";
export const QUESTIONNAIRE_QUESTION_BASEURL =
  process.env.REACT_APP_QUESTIONS_SERVICE_BASEURL + "/api/v1/questionnaire-questions/";
export const TELE_CONSULTATION_REQUEST_SERVICE_BASEURL =
  process.env.REACT_APP_CONSULTATION_BASEURL + "/api/v1/clinics/";
export const CARE_PROGRAM_ORDERS_SERVICE_BASEURL =
  process.env.REACT_APP_CARE_PROGRAM_SERVICE_BASEURL + "/api/v1/payment/care-program-orders";
export const CARE_PROGRAM_INVOICE_MAIL_BASEURL =
  process.env.REACT_APP_CARE_PROGRAM_SERVICE_BASEURL + "/api/v1/payment/send-email/";
export const FSL_PATIENT_SERVICE_BASEURL = process.env.REACT_APP_FSL_SERVICE_BASEURL + "/api/v1/";
export const FSL_PATIENT_FILE_SERVICE_BASEURL = process.env.REACT_APP_FSL_FILE_SERVICE_BASEURL + "/api/v1/";
export const ADHOC_PAYMRNT_SERVICE_BASEURL =
  process.env.REACT_APP_ADHOC_PAYMENT_SERVICE_BASEURL + "/api/v1/ad-hoc-payments";
export const ADD_ADMIN_SERVICE_BASEURL = process.env.REACT_APP_ADMINISTRATORS_BASEURL + "/api/v1/administrators";
export const NEW_CHAT_PARTICIPANTS = process.env.REACT_APP_CLINIC_BASEURL + "/api/v1/clinics";
export const SPECIALITY_SERVICE_BASEURL = process.env.REACT_APP_COUNTRY_STATE_CITY_BASEURL + "/api/v1/speciality";
export const QUALIFICATION_SERVICE_BASEURL = process.env.REACT_APP_COUNTRY_STATE_CITY_BASEURL + "/api/v1/qualification";

// Facility URLs
export const FACILTY_SERVICE_BASEURL = process.env.REACT_APP_FACILITY_SERVICE_BASEURL + "/api/v1";
export const CONSULTATION_BASEURL = process.env.REACT_APP_CONSULTATION_BASEURL + "/api/v1";
export const AVAILABLE_TIMESLOT_BASEURL = process.env.REACT_APP_SLOT_SERVICE_BASEURL + "/api/v1/availableTimeSlot";
// AHP URLS
export const AHP_BASEURL = `${process.env.REACT_APP_AHP_BASEURL}/api/v1/ahps/`;
export const AHP_SERVICE = `${process.env.REACT_APP_AHP_BASEURL}/api/v1/clinics/`;
export const CLINIC_AHP_FEEURL = `${process.env.REACT_APP_CLINIC_AHP_BASEURL}/api/v1/ahps/`;
export const CLINIC_AHP_BASEURL = `${process.env.REACT_APP_CLINIC_AHP_BASEURL}/api/v1/clinics/`;
export const CLINIC_AHP_UPDATEURL = `${process.env.REACT_APP_CLINIC_AHP_BASEURL}/api/v1/clinic-ahps/`;

//UserNotes
export const USER_NOTES_URL = process.env.REACT_APP_USER_NOTES + "/api/v1/user-notes";

//country,state,city
export const COUNTRY_STATE_CITY_BASEURL = `${process.env.REACT_APP_MASTER_DATA_SERVICE}/api/v1`;

export const GUEST_PROFILE_BASEURL = `${process.env.REACT_APP_GUEST_USER_BASEURL}/api/v1`;
export const URL = {
  administrators: {
    BASE_URL: process.env.REACT_APP_ADMINISTRATORS_BASEURL + "/api/v1/administrators/",
    LOGIN_URL: process.env.REACT_APP_AUTH_BASEURL + "/api/v1/administrators/login",
    REGISTER_URL: process.env.REACT_APP_AUTH_BASEURL + "/api/v1/administrators/register",
    CLINIC_SERVICE_URL: process.env.REACT_APP_CLINIC_BASEURL + "/api/v1/administrators/",
    BASE_AUTH_URL: process.env.REACT_APP_AUTH_BASEURL + "/api/v1/administrators/",
    VERIFY_MOBILE: "/api/v1/administrators/verify-mobile",
  },
  doctors: {
    BASE_URL: process.env.REACT_APP_DOCTORS_BASEURL + "/api/v1/doctors/",
    LOGIN_URL: process.env.REACT_APP_AUTH_BASEURL + "/api/v1/doctors/login",
    REGISTER_URL: process.env.REACT_APP_AUTH_BASEURL + "/api/v1/doctors/register",
    CLINIC_SERVICE_URL: process.env.REACT_APP_CLINIC_BASEURL + "/api/v1/doctors/",
    BASE_AUTH_URL: process.env.REACT_APP_AUTH_BASEURL + "/api/v1/doctors/",
    VERIFY_MOBILE: "/api/v1/doctors/verify-mobile",
  },
  patients: {
    BASE_URL: process.env.REACT_APP_PATIENT_BASEURL + "/api/v1/users",
    BASE_URL_V2: process.env.REACT_APP_PATIENT_BASEURL + "/api/v2/users",
    BASE_URL_OPD : process.env.REACT_APP_SLOT_MANAGEMENT_BASE_URL + "/user-service/api/v1/users"
  },
  
  dietitians: {
    BASE_URL: AHP_BASEURL,
    LOGIN_URL: process.env.REACT_APP_AHP_AUTH_BASEURL + "/api/v1/ahp/login",
    REGISTER_URL: process.env.REACT_APP_AHP_AUTH_BASEURL + "/api/v1/ahp/register",
    CLINIC_SERVICE_URL: process.env.REACT_APP_CLINIC_BASEURL + "/api/v1/dietitians/",
    BASE_AUTH_URL: process.env.REACT_APP_AHP_AUTH_BASEURL + "/api/v1/ahp/",
    CLINIC_USER_URL: process.env.REACT_APP_CLINIC_DIETITIAN_BASEURL + "/api/v1/clinic-dietitians/",
  },
  "diabetic-coaches": {
    BASE_URL: AHP_BASEURL,
    LOGIN_URL: process.env.REACT_APP_AHP_AUTH_BASEURL + "/api/v1/ahp/login",
    REGISTER_URL: process.env.REACT_APP_AHP_AUTH_BASEURL + "/api/v1/ahp/register",
    CLINIC_SERVICE_URL: process.env.REACT_APP_CLINIC_BASEURL + "/api/v1/diabetic-coaches/",
    BASE_AUTH_URL: process.env.REACT_APP_AHP_AUTH_BASEURL + "/api/v1/ahp/",
    CLINIC_USER_URL: process.env.REACT_APP_CLINIC_DIABETIC_COACH_BASEURL + "/api/v1/clinic-diabetic-coaches/",
  },
  ahp: {
    BASE_URL: AHP_BASEURL,
    LOGIN_URL: process.env.REACT_APP_AHP_AUTH_BASEURL + "/api/v1/ahp/login",
    REGISTER_URL: process.env.REACT_APP_AHP_AUTH_BASEURL + "/api/v1/ahp/register",
    BASE_AUTH_URL: process.env.REACT_APP_AHP_AUTH_BASEURL + "/api/v1/ahps/",
  },
};
export const TERMS_OF_USE_URL =
  "https://www.starhealth.in/blog/wp-content/uploads/2021/10/Wellnessprogram-Terms-of-use.pdf";
export const PRIVACY_POLICY_URL = "https://securra.com/privacy-policy";
export const VITAL_QUERY_URL = process.env.REACT_APP_DATA_PLATFORM_SERVICE + "/api/v1/vitals/query/";
export const SOS_CASE_SERVICE_BASEURL = process.env.REACT_APP_SOS_CASE_SERVICE_BASEURL + "/api/v1/sos-cases";

//Lab reports
export const LAB_REPORT_BASE_URL = process.env.REACT_APP_LAB_REPORT_SERVICE_URL;
export const LAB_REPORT = process.env.REACT_APP_LAB_REPORT_SERVICE_URL + "/labreport";
export const LAB_BATCH = process.env.REACT_APP_LAB_REPORT_SERVICE_URL + "/labbatch";
export const CARE_PROGRAMS_GROUPS = process.env.REACT_APP_GROUP_CHAT + "/api/v1";

//User Locker URLS
export const USER_LOCKER_BASE_URL = process.env.REACT_APP_USER_LOCKER_SERVICE;
export const USER_LOCKER_FILES_URL = process.env.REACT_APP_USER_LOCKER_SERVICE + "/api/v1/users";
export const LOCKER_FILES_URL = process.env.REACT_APP_USER_LOCKER_SERVICE + "/api/v1/locker/files";

//Lab Partner
export const LAB_PARTNER_BASE_URL = process.env.REACT_APP_LAB_PARTNER_BASEURL + "/v1";

//Dashboard
export const DASHBOARD_BASHURL = process.env.REACT_APP_DASHBOARD_BASEURL + "/api/v1/clinics/";

//Health Profiler
export const DATA_PARAMETER_URL = process.env.REACT_APP_DATA_PARAMETER_URL+'/v1/dataparameter';
export const HEALTH_PROFILER_CONSOLE_URL = process.env.REACT_APP_QUESTION_URL+'/v1/console';
export const UOM_URL = process.env.REACT_APP_DATA_PARAMETER_URL+'/v1/uom';
export const QUESTIONS_URL = process.env.REACT_APP_QUESTION_URL+'/v1';
export const QUESTION_SET_URL = process.env.REACT_APP_QUESTION_URL+'/v1';
export const TAG_URL = process.env.REACT_APP_TAG_URL+'/v1/tag';
export const ASSOCIATIONS_URL = process.env.REACT_APP_QUESTION_URL+'/v1/association';


//Consultation
export const CREATE_CONSULTATION = process.env.REACT_APP_CONSULTATION_URL + '/v1/Prescription';
export const CREATE_MEDICAL_HISTORY = process.env.REACT_APP_MEDICAL_HISTORY_URL + '/v1';
export const CREATE_CASESHEET=process.env.REACT_APP_CONSULTATION_URL +"/v1/";
//Transcriber 
export const TRANSCRIBER_URL = process.env.REACT_APP_TRANSCRIBER_BASEURL+'/v1';
// Slot Management
const SLOT_MANAGEMENT_BASEURL = process.env.REACT_APP_SLOT_MANAGEMENT_BASE_URL
const SLOT_FACILITIES_BASEURL = `${SLOT_MANAGEMENT_BASEURL}facility-service/api/v1/all/`
const SLOT_SERVICE_API = `${SLOT_MANAGEMENT_BASEURL}slot-service/api/v1/`

export const CREATE_SLOT = `${SLOT_SERVICE_API}creator`
export const GET_ALL_FACILITIES = `${SLOT_FACILITIES_BASEURL}facilities/names`
export const apiRolesByFacility = (facilityId) => `${SLOT_FACILITIES_BASEURL}practitioners/roles/facility/${facilityId}`
export const apiPractitionersByRoleAndFacility = (roleId, facilityId) => `${SLOT_FACILITIES_BASEURL}practitioners/names/role/${roleId}/facility/${facilityId}`
export const GET_DOCTORS_SLOT_BY_FACILITY = `${SLOT_SERVICE_API}slots/all/doctors`
export const getRolesForConsultation = (settingId)=>`${SLOT_MANAGEMENT_BASEURL}master-data-service/api/v1/applicable/roles/${settingId}`
export const GET_SLOTS_BY_PRACTITIONER = `${SLOT_SERVICE_API}slot/practitioner`
export const DELETE_SLOT = `${SLOT_SERVICE_API}delete/appointments/slot`

//FacilityEmpanelment
const BASE_EMPANEL_FACILITY_URL = process.env.REACT_APP_EMPANELMENT_BASEURL+'/facility-service/api/v1/facility/';
export const FACILITY_EMPANELMENT_LIST =`${BASE_EMPANEL_FACILITY_URL}empanelment/`
export const FACILITY_EMPANELMENT_DETAIL_PAGE= process.env.REACT_APP_EMPANELMENT_BASEURL+'/facility-service/api/v1/facilities/'

//Doctorempanelment
export const BASE_EMPANEL_URL=process.env.REACT_APP_EMPANELMENT_BASEURL+'/practitioner-service/api/v1/practitioners/'
export const DOCTOR_EMPANELMENT_LIST= `${BASE_EMPANEL_URL}empanelment/doctor/`
export const DOCTOR_EMPANELMENT_DETAIL_PAGE= `${BASE_EMPANEL_URL}id/`
export const PRACTITIONER_INFO = `${BASE_EMPANEL_URL}/mobile`
export const AUTH_SERVICE =  process.env.REACT_APP_AUTH_SERVICE+'/api/v1/practitioners/loginWithIdentityToken';
export const INVITE_USER = `${BASE_EMPANEL_URL}quick/add`;
//Meal and Water Loggers
export const LOGGER_URL = process.env.REACT_APP_LOGGERS+'/api/v1';
//Signup
export const FACILITY_SIGNUP_URL=process.env.REACT_APP_FACILITY_SIGNUP+'/api/v1';


export const PRACTITIONER_SERVICE = process.env.REACT_APP_PRACTITIONER_SERVICE +'/api/v1'
export const MASTER_DATA_SERVICE = process.env.REACT_APP_MASTER_DATA_SERVICE + '/api/v1'

// Consultation
export const GET_JOIN_CONSULTATION = `${process.env.REACT_APP_SLOT_MANAGEMENT_BASE_URL}consultation-service/api/v1/joinConsultation`
export const GET_PAST_CONSULTATION = `${process.env.REACT_APP_SLOT_MANAGEMENT_BASE_URL}consultation-service/api/v1/`
export const GET_CONSULT_ARRAY=`${GET_PAST_CONSULTATION}consultation-type`
export const apiGetSymptoms = (pageNumber, pageSize, limit = 25, skip = 0) => `${process.env.REACT_APP_SLOT_MANAGEMENT_BASE_URL}master-data-service/api/v1/symptom?pageSize=${pageSize}&pageNumber=${pageNumber}&limit=${limit}&skip=${skip}`
export const apiGetInvestigations = (search) => `${process.env.REACT_APP_SLOT_MANAGEMENT_BASE_URL}master-data-service/api/v1/lab-investigation/?search=${search}`
export const apiUpdateCaseSheet = (caseSheetId) => `${process.env.REACT_APP_SLOT_MANAGEMENT_BASE_URL}consultation-service/api/v1/consultation/update-case-sheet/${caseSheetId}`
export const apiGetMedicalRecords = (userId) => `${process.env.REACT_APP_SLOT_MANAGEMENT_BASE_URL}user-locker-service/api/v1/users/${userId}/locker/files?sortBy=created`
export const APPOINTMENT_SERVICE = process.env.REACT_APP_APPOINTMENT_URL + '/api/v1'

export const GET_SLOT_LIST_SERVICE = `${SLOT_SERVICE_API}availableTimeSlot`

export const GET_USER_SERVICE = `${SLOT_MANAGEMENT_BASEURL}user-service/api/v1/`

export const ROLES_GROUP = process.env.REACT_APP_ROLE_GROUP_URL +'/api/v1/roles'

export const POLICY_DETAILS=process.env.REACT_APP_POLICY_DETAIL + '/policy/api/v1'

export const DASHBOARD_SERVICE = process.env.REACT_APP_DASHBOARD_SERVICE + '/api/v1'

export const CLAIMS_CONSULTATION_BASE_URL=`${process.env.REACT_APP_UNIFIED_BASE_URL}/consultation-service/api/v1`

export const UPCOMING_APPOINTMENTS=`${process.env.REACT_APP_APPOINTMENT_URL}/api/v1/`

export const VIDEO_SERVICE = process.env.REACT_APP_VIDEO_SERVICE
