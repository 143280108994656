import axios from "axios";
import audio from '../assets/images/audio_icon.svg'
import video from '../assets/images/video_icon.svg'
import pdf from '../assets/images/pdf_icon.svg'
import ppt from '../assets/images/ppt_icon.svg'
import image from '../assets/images/image_icon.svg'
import excel from '../assets/images/excel_icon.svg'
import word from '../assets/images/word_icon.svg'

export const getIdByName = (json, name) => {
  const data = json.filter((v) => v.name === name);
  if (data && data.length) {
    return data[0].id;
  } else {
    return "";
  }
};

export const getNameById = (json, id) => {
  const data = json.filter((v) => v.id === id);
  if (data && data.length) {
    return data[0].name;
  } else {
    return "";
  }
};

export const getRoleName = (role) => {
  switch (role) {
    case "administrators":
      return "Admin";
    case "doctors":
      return "Doctor";
    case "dietitians":
      return "Dietician";
    case "diabetic-coaches":
    case "diabetic-coach":
      return "Diabetes Coach";
    default:
      return "Role";
  }
};
export const getRefRoleName = (role) => {
  switch (role) {
    case "administrator":
      return "Clinic Admin";
    case "doctor":
      return "Doctor";
    case "dietitian":
      return "Dietician";
    case "diabetic-coach":
      return "Diabetes Coach";
    case "Wellness Coach":
    case "wellness-coach":
      return "AHP";  
    default:
      return "Role";
  }
};
export const getRefRoleToRole = (role) => {
  switch (role) {
    case "administrator":
      return "administrators";
    case "doctor":
      return "doctors";
    case "dietitian":
      return "dietitians";
    case "diabetic-coach":
      return "diabetic-coaches";
    case 'ahp':
      return 'ahp';
    default:
      return "Role";
  }
};

export const getRefRolesToRole = (roles) => {
  switch (roles) {
    case "administrators":
      return "administrator";
    case "doctors":
      return "doctor";
    case "dietitians":
      return "dietitian";
    case "diabetic-coaches":
      return "diabetic-coach";
    case 'ahp':
      return 'ahp';
    default:
      return roles;
  }
}

export const flattenArray = (arr) => {
  if (arr && arr.length) {
    const result = arr.map((v) => {
      const { active } = v;
      return {
        active,
        ...v.doctor,
        mobileNum: `+ ${v.mobile.countryCode} ${v.mobile.number}`,
      };
    });
    console.log(result);
    return result;
  }
  return arr;
};

export const getIcon = (filename) => {
  const url = new URL(filename);
  const ext = url.pathname.split(".").pop();
  switch (ext) {
    case 'xls':
    case 'xlsx':
    case 'csv':
      return excel;
    case 'ppt':
    case 'pptx':
      return ppt;
    case 'mp3':
      return audio;
    case 'mp4':
      return video;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return image;
    case 'pdf':
      return pdf;
    default:
      return word;
  }
};

export const padNumber = (number, size) => {
  var s = String(number);
  while (s.length < (size || 2)) { s = "0" + s; }
  return s;
}

export const booleanToString = (value) => {
  switch (value) {
    case true:
      return 'yes'
    case false:
      return 'no'
    case null:
    case "null":
      return 'NA'
    default:
      return ''
  }
}
export const stringToBoolean = (value) => {
  switch (value) {
    case 'yes':
      return true
    case 'no':
      return false
    case 'NA':
      return "null"
    default:
      return ''
  }
}

export const downloadFile = async (url, fileName) => {
  const response = await axios({
    url,
    method: 'GET',
    responseType: 'blob',
  })
  const blobbedResponse = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = blobbedResponse;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
}

export const getBasenameFormUrl = (urlStr) => {
  if(urlStr){
    try{
        const url = new URL(urlStr);
        return url.href.split("?")[0].split("/").pop()
    }
    catch(e){
        console.log("Error", e)
        return "file"
    }
  }
};
export const getAppointmentType = (type) => {
  const types = {
    CARE_PROGRAM: "Wellness Program",
    VIRTUAL_CONNECT: "Virtual Connect",
    PHYSICAL_CONSULTATIONS: "Physical Consultation",
  }

  return types[type] || 'NA'
}

export const getRelativePath = (s3path) => {
  return s3path.split("?")[0].split("com/")[1];
}