import axios from "axios";
import { authStorage } from "../utils/login";

export default class Services {
  axiosInstance;

  constructor() {
    this.init();
  }

  init() {
    this.axiosInstance = axios.create();
    this.registerRequestInterceptor();
    this.registerResponseInterceptor();
  }

  getServiceInstance() {
    return this.axiosInstance;
  }

  registerRequestInterceptor() {
    this.axiosInstance.interceptors.request.use(
      config => {
        const tokenStr = "Bearer " + authStorage.authToken;
        if (tokenStr) {
          config.headers["Authorization"] = tokenStr;
        }
        return config;
      },
      error => {
        Promise.reject(error);
      }
    );
  }

  registerResponseInterceptor() {
    this.axiosInstance.interceptors.response.use(
      response => {
        return response;
      },
      async error => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
          try {
            originalRequest._retry = true;
            const newToken = await this.getNewAuthToken();
            if (newToken) {
              originalRequest.headers["Authorization"] = "Bearer " + newToken;
              return axios(originalRequest);
            }
          } catch (e) {}
        }
        return Promise.reject(error);
      }
    );
  }

  async getNewAuthToken() {
    const ins = axios.create({
      headers: { Authorization: "Bearer " + authStorage.refreshToken }
    });

    const { data } = await ins.post(
      process.env.REACT_APP_AUTH_BASEURL + "/api/v1/refresh-access-token",
      { accessToken: authStorage.authToken }
    );

    const newToken = data.accessToken;
    authStorage.setAuthDetails(newToken, authStorage.refreshToken);
    return newToken;
  }
}
