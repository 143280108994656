import { useState, useEffect } from "react";
import { UserManager, User, Log } from "oidc-client-ts";
import { useHistory } from "react-router-dom";
import { authStorage } from "../utils/login";

const useAuthService = () => {
  const history = useHistory()
  const client_id = process.env.REACT_APP_CLIENT_ID +"";
  const client_secret = process.env.REACT_APP_CLIENT_SECRET +"";
  const userManager = new UserManager({
    redirect_uri: window.location.origin + "/login",
    authority: "https://identitysts.azurewebsites.net/",
    post_logout_redirect_uri: window.location.origin + "/logout",
    client_id: client_id,
    client_secret: client_secret,
    scope: "openid email profile roles",
    response_type: "code",
    automaticSilentRenew: true,
    metadata: {
      issuer: "https://identitysts.azurewebsites.net",
      jwks_uri:
        "https://identitysts.azurewebsites.net/.well-known/openid-configuration/jwks",
      authorization_endpoint:
        "https://identitysts.azurewebsites.net/connect/authorize",
      token_endpoint: "https://identitysts.azurewebsites.net/connect/token",
      userinfo_endpoint:
        "https://identitysts.azurewebsites.net/connect/userinfo",
      end_session_endpoint:
        "https://identitysts.azurewebsites.net/connect/endsession",
      check_session_iframe:
        "https://identitysts.azurewebsites.net/connect/checksession",
      revocation_endpoint:
        "https://identitysts.azurewebsites.net/connect/revocation",
      introspection_endpoint:
        "https://identitysts.azurewebsites.net/connect/introspect",
      token_endpoint_auth_methods_supported: ["token_endpoint_auth_method"],

      // device_authorization_endpoint: "https://identitysts.azurewebsites.net/connect/deviceauthorization",
      // backchannel_authentication_endpoint: "https://identitysts.azurewebsites.net/connect/ciba",
    },
  });

  userManager.events.addAccessTokenExpiring(() => {
    renewToken();
  });

  Log.setLogger(console);
  Log.setLevel(Log.INFO);

  const fetchUserData = async () => {
    const user = await userManager.getUser();
    if (user) {
      authStorage.setAuthDetails(user.access_token)
    } else renewToken();

    return user
  };

  const login = () => userManager.signinRedirect();

  const renewToken = async () => {
    const user = await userManager.signinSilent();

    if (user) authStorage.setAuthDetails(user.access_token)
    else login();

    return user
  };

  const logout = (): Promise<void> => {
    return userManager.signoutRedirect();
  };

  const signInCallback = async (url?: string) => {
    const user = await userManager.signinRedirectCallback(url);
    console.log(user);
    
    if (user) {
      authStorage.setAuthDetails(user.access_token)
      window.history.replaceState(
        {},
        window.document.title,
        window.location.origin + window.location.pathname
      );
      history.replace(window.location.pathname)
    } else login();

    return user
  };

  return { login, fetchUserData, renewToken, logout, signInCallback };
};

export default useAuthService;
