import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: any) => ({
  root: {
    color: "#000000",
    backgroundColor: 'transparent',
    padding: "0px",
    "& .MuiSnackbarContent-message":
    {
      padding: "0px !important",
    },
    "& .MuiTypography-body1": {
      fontSize: "0.9rem !important",
    },
  },
  wrapper: {
    width: "fit-content",
  },
  mainContainer: {
    justifyContent: "center",
    borderRadius: "12px",
    background: '#FFFAFA',
    display: "flex",
    alignItems: "center",
  },
  messageContainer: {
    borderRadius: "12px",
    padding: '12px 12px 12px 14px',
    alignItems: "center",
    display: "flex",
  },
  notifyIcon: {
    width: "24px",
    height: "24px",
    background: "#FF4C5C",
    borderRadius: "50%",
    color: "#E5EDFF",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  facilityContent: {
    fontFamily: "Inter",
    fontStyle: "normal",
    padding: "0px 0px 0px 24px",
  },
  title: {
    fontWeight: 700,
  },
  reason: {
    fontWeight: 400,
  },
  notifySuccessIcon: {
    width: "24px",
    height: "24px",
    background: "#07C180",
    borderRadius: "50%",
    color: "#E5EDFF",
    fontWeight: 700,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  colorContainer: {
    width: "8px",
    height: "74px",
    background: "#FF4C5C !important",
    borderRadius: "4px 0px 0px 4px",
  },
  messageSuccessWrapper: {
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    width: "946px",
    marginBottom: "22px",
  },
  messageSuccessContainer: {
    padding: "12px 14px",
    display: "flex",
    gap: "12px",
    alignItems: "center",
    backgroundColor: "#07C180",
  },
  successContainer: {
    zIndex: 1,
    justifyContent: "flex-start",
    borderRadius: "12px",
    background: '#FAFFFD',
    display: "flex",
  },
  successMessageContainer: {
    width: "8px",
    background: "#07C180",
    borderRadius: "4px 0px 0px 4px",
  },
  infoContainer: {
    justifyContent: "center",
    borderRadius: "12px",
    background: '#E5EDFF',
    display: "flex",
  },
  infoMessageContainer: {
    width: "8px",
    height: "74px",
    background: "#4066BF",
    borderRadius: "4px 0px 0px 4px",
  },
  notifyInfoIcon: {
    width: "24px",
    height: "24px",
    background: "#4066BF",
    borderRadius: "50%",
    color: "#E5EDFF",
    fontWeight: 700,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
}))

export default useStyles;