import * as actionTypes from "./actionTypes";

export const setAuthStart = () => ({ type: actionTypes.AUTH_START });

export const setAuthSuccess = (data, role) => ({
  payload: data,
  role,
  type: actionTypes.AUTH_SUCCESS,
});

export const setAuthFail = (error) => ({ error, type: actionTypes.AUTH_FAIL });

export const setLogout = () => ({ type: actionTypes.AUTH_INITIATE_LOGOUT });

export const setLogoutSucceed = () => ({ type: actionTypes.AUTH_LOGOUT });

export const auth = (data, role) => ({
  type: actionTypes.AUTH_USER,
  payload: data,
  role,
});

export const setAuthToken = (data, role, inProgress = false) => ({
  type: actionTypes.AUTH_TOKEN,
  role,
  inProgress,
  payload: data,
});

export const setAuthCompleted = (inProgress) => ({
  type: actionTypes.AUTH_COMPLETED,
  payload: inProgress
});

export const switchUserRole = (role) => ({
  type: actionTypes.SWITCH_USER_ROLE,
  role,
});

export const setFacilitylist = (data) => ({ 
  type:actionTypes.FACILITY_LIST,
  payload : data,
})  

export const UpdateUser =(data)=>({ 
  type:actionTypes.UPDATE_USER_DETAILS,
  payload : data,
})  