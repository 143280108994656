export const AUTH_USER = "AUTH_USER";
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_CHECK_TIMEOUT = "AUTH_CHECK_TIMEOUT";
export const AUTH_INITIATE_LOGOUT = "AUTH_INITIATE_LOGOUT";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_TOKEN = "AUTH_TOKEN";
export const AUTH_COMPLETED = "AUTH_COMPLETED";
export const SWITCH_USER_ROLE = "SWITCH_USER_ROLE";
export const UPDATE_USER_MOBILE = "UPDATE_USER_MOBILE";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_MESSAGE = "REGISTER_MESSAGE";
export const REGISTER_OTP = "REGISTER_OTP";
export const REGISTER_OTP_SUCCESS = "REGISTER_OTP_SUCCESS";
export const REGISTER_OTP_ONE_TIME_TOKEN = "REGISTER_OTP_ONE_TIME_TOKEN";

export const CLINIC_DETAILS = "CLINIC_DETAILS";
export const UPDATE_CLINIC_INFO = "UPDATE_CLINIC_INFO";
export const USER_DETAILS = "USER_DETAILS";

export const UPDATE_CARE_PROGRAM_DATA = 'UPDATE_CARE_PROGRAM_DATA'
export const CLEAR_CARE_PROGRAM_DATA = 'CLEAR_CARE_PROGRAM_DATA'

export const OPEN_WEBSOCKET = 'OPEN_WEBSOCKET'
export const CHAT_ROOM_LIST = 'CHAT_ROOM_LIST'
export const CLOSE_WEBSOCKET = 'CLOSE_WEBSOCKET'
export const CHAT_ROOM_INFO = 'CHAT_ROOM_INFO'
export const CHAT_ROOM_PARTICIPANT_INFO = 'CHAT_ROOM_PARTICIPANT_INFO'
export const CHAT_MESSAGE_LIST = 'CHAT_MESSAGE_LIST'
export const CHAT_OTHER_INFO = 'CHAT_OTHER_INFO'
export const OPEN_CHAT_LIST = 'OPEN_CHAT_LIST'
export const ENABLE_CHAT_WIDGET = 'ENABLE_CHAT_WIDGET'
export const OPEN_CHAT_DETAILS = 'OPEN_CHAT_DETAILS'
export const OPEN_CHAT_ROOMS = 'OPEN_CHAT_ROOMS'
export const FACILITY_LIST  = 'FACILITY_LIST'
export const UPDATE_USER_DETAILS ='USER_DETAILS'
export const CALL_PICK_UP  = 'CALL_PICK_UP'
export const CHAT_NOW  = 'CHAT_NOW'
export const UPDATE_INST_INFO ='UPDATE_INST_INFO'
export const UPDATE_BOOK_ID ='UPDATE_BOOK_ID'
export const UPDATE_CALL_INFO='UPDATE_CALL_INFO'