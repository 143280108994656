import { getCookie, setCookie, eraseCookie } from "./cookies";

class AuthStorage {
  get authToken() {
    return getCookie(process.env.REACT_APP_ACCESS_TOKEN_KEY);
  }

  get refreshToken() {
    return getCookie(process.env.REACT_APP_REFRESH_TOKEN_KEY);
  }

  setAuthDetails = (accessToken, refreshToken) => {
    setCookie(process.env.REACT_APP_ACCESS_TOKEN_KEY, accessToken, 1);
    setCookie(process.env.REACT_APP_REFRESH_TOKEN_KEY, refreshToken, 10);
  };

  deleteAuthDetails = () => {
    eraseCookie(process.env.REACT_APP_ACCESS_TOKEN_KEY);
    eraseCookie(process.env.REACT_APP_REFRESH_TOKEN_KEY);
  };
}

export const authStorage = new AuthStorage();
