import { CLINIC_DIABETIC_COACH_SERVICE, CLINIC_DIETITIAN_SERVICE, CLINIC_SERVICE_URL, URL, CLINIC_AHP_BASEURL, CLINIC_ADMINISTRATOR_BASEURL, CLINIC_CARE_PROGRAM_SERVICE_BASEURL, GUEST_PROFILE_BASEURL } from '../config/url.config';
import Services from "./services";


const instance = new Services().getServiceInstance();

export const registerClinicApi = (role, userId, data) => {
  return instance
    .post(URL[role].CLINIC_SERVICE_URL + userId + "/register-clinic", data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const getClinicAPI = async (key, id) => {
  const { data } = await instance.get(`${CLINIC_SERVICE_URL}${id}`)
  return data
}

export const getAllDoctorByClinicApi = async (key, clinicId, order, search, page, limit) => {
  const params = {
    sortBy: order?.orderBy,
    orderBy: order.order,
    search: search || '',
    limit: limit || 10,
    skip: 0
  }
  if (page && limit) {
    params.skip = (page - 1) * limit
  }
  const data = await instance.get(
    CLINIC_SERVICE_URL + clinicId + "/doctors", { params }
  );
  return data;
};

export const getAllAdminByClinicApi = async (key, clinicId, order, search, page, limit) => {
  const params = {
    sortBy: order?.orderBy,
    orderBy: order.order,
    search: search || '',
    limit: limit || 10,
    skip: 0
  }
  if (page && limit) {
    params.skip = (page - 1) * limit
  }
  const data = await instance.get(
    CLINIC_SERVICE_URL + clinicId + "/administrators", { params }
  );
  return data;
};

export const getAllAHPByClinicApi = async (key, clinicId, order, search, page, limit, status) => {
  const params = {
    sortBy: order?.orderBy,
    orderBy: order.order,
    search: search || '',
    limit: limit || 10,
    skip: 0,
    active: status
  } 
  if (page && limit) {
    params.skip = (page - 1) * limit
  }
  const data = await instance.get(
    CLINIC_AHP_BASEURL + clinicId + "/ahps", { params }
  );
  return data;
};

export const addDoctorToClinicApi = (clinicId, doctorId, params) => {
  return instance
    .post(CLINIC_SERVICE_URL + clinicId + "/doctors/" + doctorId, params)
    .then((res) => res)
    .catch((err) => err.response);
};

export const addAdminToClinicApi = (clinicId, adminId, params) => {
  return instance
    .post(CLINIC_SERVICE_URL + clinicId + "/administrators/" + adminId)
    .then((res) => res)
    .catch((err) => err.response);
};

export const updateAdminStatus = async (id, params) => {
  return instance.patch(`${CLINIC_ADMINISTRATOR_BASEURL}${id}`, params).then((res) => res)
    .catch((err) => err.response);
}

export const getAllPatientByClinicApi = async (key, clinicId,careProgramId, order, search, page, limit, dndStatus, ahpId = "",status,) => {
  const params = {
    sortBy: order?.orderBy,
    orderBy: order.order,
    search: search || '',
    limit: limit || 10,
    skip: 0,
    careProgramId:  careProgramId || "",
    dnd: dndStatus || false,
    ahpId,
    status,
  }
  if (page && limit) {
    params.skip = (page - 1) * limit
  }
  const data = await instance.get(`${CLINIC_SERVICE_URL}${clinicId}/users`, { params });
  return data;
}

export const addPatientToClinicApi = (clinicId, patientId) => {
  return instance.post(CLINIC_SERVICE_URL + clinicId + "/users/" + patientId);
};

export const addAHPToClinicApi = (clinicId, ahpId, params) => {
  return instance.post(
    `${CLINIC_AHP_BASEURL}${clinicId}/ahps/${ahpId}`, params
  );
};

export const updateClinicDetails = async (id, params) => {
  return instance.patch(`${CLINIC_SERVICE_URL}${id}`, params).then((res) => res)
    .catch((err) => err.response);
}

export const updateClinicAddress = async (id, params) => {
  return instance.patch(`${CLINIC_SERVICE_URL}${id}/address`, params).then((res) => res)
    .catch((err) => err.response);
}

export const getDoctorsByDepartment = async (id,departmentId) => {
  return instance.get(`${CLINIC_SERVICE_URL}${id}/departments/${departmentId}/doctors`).then((res) => res)
    .catch((err) => err.response);
}

export const addClinicFundAccount = async (clinicId, payload) => {
  return instance.post(`${CLINIC_SERVICE_URL}${clinicId}/fund-accounts-bank`, payload).then((res) => res)
    .catch((err) => err.response);
}

export const getClinicFundAccount = async (clinicId) => {
  return instance.get(`${CLINIC_SERVICE_URL}${clinicId}/fund-accounts`).then((res) => res)
    .catch((err) => err.response);
}

export const searchDoctors = (queryCache, queryHash) => async (key, clinicId, doctorRole, ahpRoleId = null) => {
  let apiUrl;
  if (!doctorRole) {
    return [];
  }
  if (queryCache && queryHash) {
    const cachedData = queryCache.getQueryByHash(queryHash)
    if (cachedData?.state?.data) {
      return cachedData?.state?.data
    }
  }
  const params = {
    skip: 0,
    limit: 1000
  }
  if (doctorRole === "ahp") {
    if (ahpRoleId) {
      params['roleId'] = ahpRoleId
    }
    apiUrl = CLINIC_AHP_BASEURL + clinicId + "/ahps/";
  } else {
    apiUrl = CLINIC_SERVICE_URL + clinicId + "/doctors/";
  }

  const res = await instance.get(apiUrl, { params });
  let data = res.data || [];
  const arr = [];
  if (doctorRole === "doctor") {
    data = data.filter(doctor => doctor.clinicPayload?.active);
  } else {
    data = data.filter(ahp => ahp.active);
  }

  data.forEach(element => {
    let id, first, last, mobile, consultationFee, followUpFee;
    if (doctorRole === "ahp") {
      if (element.ahp) {
        id = element.ahp._id;
        first = element.ahp.AhpProfile.name.first;
        last = element.ahp.AhpProfile.name.last;
        mobile = element.ahp.AhpProfile.mobile;
        consultationFee = element.consultationFee;
        followUpFee = element.followUpFee;
      }
    } else if (doctorRole === "doctor") {
      if (element.payload) {
        id = element.payload._id;
        first = element.payload.name.first;
        last = element.payload.name.last;
        mobile = element.payload.mobile;
        consultationFee = element.clinicPayload.consultationFee;
        followUpFee = element.clinicPayload.followUpFee;
      }
    }

    if (id) {
      arr.push({ id, mobile, name: { first: first, last: last } ,consultationFee ,followUpFee});
    }
  });
  return arr;
};


export const getAllCareProgramByClinicApi = async (
  key,
  clinicId,
  search,
  page,
  limit
) => {
  const params = {
    sortBy: "creaedAt",
    orderBy: "asc",
    search: search || "",
    limit: limit || 10,
    skip: 0
  };
  if (page && limit) {
    params.skip = (page - 1) * limit;
  }
  const data = await instance.get(
    `${CLINIC_CARE_PROGRAM_SERVICE_BASEURL}${clinicId}/care-programmes`,
    { params }
  );
  return data;
};

export const getAllGuestUserByClinicApi = async (key, order, page, limit) => {
  const params = {
    sortBy: order?.orderBy,
    orderBy: order.order,
    limit: limit || 10,
    skip: 0
  }
  if (page && limit) {
    params.skip = (page - 1) * limit
  }
  const data = await instance.get(`${GUEST_PROFILE_BASEURL}/guestusers` , { params });
  return data;
};

