import axios from "axios";
var mime = require('mime-types');

const fileInstance = axios.create({
  baseURL: process.env.REACT_APP_FILE_BASEURL
});

const bytesToMegaBytes = bytes => bytes / (1024*1024);

export const uploadToS3 = async ( file, prefix, payload={}) => {

  try {
    const { size } = file;
    const fileMBSize = bytesToMegaBytes(size);
    if (fileMBSize > 8) { // Check for 8 MB or more filesize
      let error = { response: { data: { message: "File size more than 8 MB is not supported" } } };
      throw error;
    }
    const s3prefix = prefix || "logo";
    const formdata = new FormData();
    formdata.append('files', file);
    formdata.append("tag", s3prefix);

    const response = await fileInstance.post("/api/v1/uploadFile", formdata);

    const { s3Url, keyPath } = response?.data?.map((x)=>x)[0]?.response||{};
    return { url: s3Url.replace(/%2F/gi, "/"), err: null, keyPath , fileSize: response?.headers["content-length"]};
  } catch (err) {
    console.log({ err }, "uploadToS3");
    return { url: undefined, err };
  }
};
