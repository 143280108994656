import React, { useEffect, useState } from "react";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ChatWindow from "./ChatWindow";
import CustomTextFieldSearch from '../../components/common/CustomTextFieldSearch';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import groupAvtar from '../../assets/images/group-avtar.svg'
import participantAvtar from '../../assets/images/participant-avtar.svg'
import moment from 'moment';
import ChatIcon from '../../assets/images/chats.png'
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles, Collapse, List, ListItem, IconButton } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { send } from '../../utils/wss';
import { styles } from "./styles";
import { Controller, useForm } from 'react-hook-form';
import { createOnetoOneChatRoom, searchChatRooms } from '../../api/chat';
import { setOpenChatDetails, setOpenChatList, setOpenChatRooms } from "../../store/actions";
const useStyles = makeStyles(styles);

const index = () => {
    const {
        chatRoomList = [],
        openChatList = [],
        openChatDetails = {},
        openChatRooms = []
    } = useSelector((state) => state?.chat);
    const clinic = useSelector((state) => state?.clinic?.details);
    const dispatch = useDispatch();
    const classes = useStyles();
    const { control, setValue, trigger } = useForm();
    const { user: chatUser } = useSelector((state) => state.auth);
    const [search, setSearch] = useState("");
    const [isChatListOpen, setIsChatListOpen] = useState(false);
    const [otherContactList, setOtherContactList] = useState(chatRoomList);
    const [request, setRequest] = useState({
        loading: false,
        message: "",
        severity: ""
    });
    const [searchVal, setSearchVal] = useState("");
    useEffect(() => {
        if (openChatList.length > 0) {
            dispatch(setOpenChatList([]));
            dispatch(setOpenChatDetails({}));
            dispatch(setOpenChatRooms([]));
        }
        setOtherContactList(chatRoomList)
    }, []);

    useEffect(() => {
        setOtherContactList(chatRoomList)
    }, [chatRoomList])

    useEffect(() => {
        (async () => {
            if (search) {
                const patientData = await searchChatRooms(clinic._id, chatUser.userId, search)
                let searchList = []
                if (patientData && patientData.status < 350) {
                    searchList = [...searchList, ...patientData?.data]
                }
                setOtherContactList([...searchList])
            }
        })()
        if (search == "") {
            setOtherContactList(chatRoomList)
        }
    }, [search])

    const openCloseChatList = () => {
        setIsChatListOpen(!isChatListOpen);
        if(isChatListOpen){
            setSearchVal("")
            setControlValue("search", searchVal);
            setSearch("")
        }
    }

    const getLastMessageTime = (chatroom) => {
        const myDate = chatroom?.lastMessage?.updatedAt;
        if (moment(myDate).isSame(new Date(), "day")) {
            return moment(chatroom?.lastMessage?.updatedAt).format("hh:mm a")
        }
        return moment(myDate).format("D MMM YY")        
    }

    const initChat = async (newChat) => {
        if (Object.keys(newChat).includes('payload') || openChatRooms.indexOf(newChat.room._id) === -1) {
            let receiver = '';
            let chatParticipant = {}
            if (Object.keys(newChat).includes('room') && !newChat.room.isGroup) {
                receiver = newChat?.formattedRoom?.chatUserId
                chatParticipant = newChat?.formattedRoom
            }
            //start chat with patient
            if (Object.keys(newChat).includes('payload')) {
                receiver = newChat?.payload?.chatUserId
                chatParticipant['chatUserId'] = newChat?.payload?.chatUserId
                chatParticipant['name'] = `${newChat?.payload?.name?.first} ${newChat?.payload?.name?.last}`
                chatParticipant['isOnline'] = true
            }
            //start chat with doctor,ahp,admin
            if (Object.keys(newChat).includes('AhpProfile') ||
                (!Object.keys(newChat).includes('formattedRoom')
                    &&
                    (newChat.chatUser?.role == "doctor" || newChat.chatUser?.role == "administrator"))
            ) {
                receiver = newChat?.chatUser?._id
                chatParticipant['chatUserId'] = newChat?.chatUser?._id
                chatParticipant['name'] = newChat?.chatUser?.displayName
                chatParticipant['isOnline'] = true
                chatParticipant['displayPicture'] = newChat?.chatUser?.displayPicture
            }
            chatParticipant['action'] = 'send-message-one-to-one'
            chatParticipant['isGroup'] = false
            try {
                const payload = {
                    sender: chatUser?.chatUserId,
                    recipient: receiver
                }
                const res = await createOnetoOneChatRoom(payload)
                if (res && res.status < 350) {
                    send(
                        JSON.stringify({
                            action: "get-one-to-one-room-by-reference",
                            body: { to: receiver }
                        })
                    );
                    if (openChatRooms.length == 3) {
                        openChatList.shift();
                        openChatRooms.shift();
                        delete openChatDetails[Object.keys(openChatDetails)[0]];
                    }
                    const chatRoomId = newChat?.room?._id ? newChat.room._id : res.data._id;
                    const chatRoom = newChat?.room?._id ? { ...newChat } : { ...newChat, room: res.data };
                    dispatch(setOpenChatList([...openChatList, { ...chatParticipant, room: chatRoomId, chatInfo: chatRoom }]));
                    dispatch(setOpenChatDetails({ ...openChatDetails, [chatRoomId]: [] }));
                    dispatch(setOpenChatRooms([...openChatRooms, chatRoomId]));
                }
            } catch (e) {
                setRequest({
                    message: (e.response.data && e.response.data.message) ? e.response.data.message : "Something went wrong!",
                    loading: false,
                    severity: "error"
                });
            }
        }
    };

    const initGroupChat = (newChat) => {
        let chatParticipant = {}
        if (openChatRooms.indexOf(newChat.formattedRoom._id) === -1) {
            send(
                JSON.stringify({
                    action: "get-message",
                    body: { limit: 10, skip: 0, room: newChat?.formattedRoom?._id }
                })
            );
            chatParticipant['chatUserId'] = newChat?._id
            chatParticipant['name'] = newChat?.room?.name
            chatParticipant['isOnline'] = true
            chatParticipant['displayPicture'] = ''
            chatParticipant['action'] = 'send-message'
            chatParticipant['isGroup'] = true
            if (openChatRooms.length == 3) {
                openChatList.shift();
                openChatRooms.shift();
                delete openChatDetails[Object.keys(openChatDetails)[0]];
            }
            dispatch(setOpenChatList([...openChatList, { ...chatParticipant, room: newChat?.formattedRoom?._id, chatInfo: newChat }]));
            dispatch(setOpenChatRooms([...openChatRooms, newChat?.formattedRoom?._id]));
            dispatch(setOpenChatDetails({ ...openChatDetails, [newChat?.formattedRoom?._id]: [] }));
        }
    }

    const getChatListLastMsg = (message, index) =>{
        let element = document.getElementById(`msg-preview-${index}`)
        if(element){
            return element.innerHTML = message;
        }
    }
    const setControlValue = (controlName,value) =>{
        setValue(controlName,value);
        trigger(controlName)
    }

    return (
        <div className={classes.chatRoot}>
            <IconButton 
                variant="contained" 
                size="small" 
                className={classes.FloatChatIcon} 
                onClick={openCloseChatList}
            >
                {!isChatListOpen ? <img src={ChatIcon} alt='chat'/> : <ClearIcon className={classes.CloseChatIcon}/>}
            </IconButton>
            <div className={classes.chatMultiWindowWrapper}>
                {
                    openChatList.length > 0 && openChatList.map((chatItem, index) => (
                        <ChatWindow
                            key={chatItem.room}
                            patient={chatItem}
                            chatData={openChatDetails[chatItem.room] ? openChatDetails[chatItem.room] : []}
                            openedChatWindowIndex={index}
                        />
                    ))
                }
            </div>
            <div className={"chatPatientListWrap "+(`${isChatListOpen ? 'toggle' : ''}`)}>
                <div className="chatHead">
                    <div className="chatTitle">
                        <span>Chats </span>
                    </div>
                    {
                        isChatListOpen ? 
                        <div className={classes.topicons}><ClearIcon onClick={()=>setIsChatListOpen(false)} className={classes.HeadCloseIcon}/></div> 
                        : 
                        <KeyboardArrowUpIcon className="chatHeadArrow" />
                    }
                </div>
                {/* <Collapse orientation="vertical" in={isChatListOpen}> */}
                    <div className={`chatPatientList ${isChatListOpen ? 'chatListOpen' : ''}`}>
                        <Controller
                            name="search"
                            control={control}
                            render={controlProps => {
                                return (
                                    <CustomTextFieldSearch
                                        label="Search people, groups"
                                        value={searchVal}
                                        onChange={(e) => {
                                            setControlValue("search", e);
                                            controlProps.onChange(e);
                                            setSearchVal(e);
                                          }}
                                        onSearch={(value) => {
                                            setSearch(value)
                                        }}
                                        resetDefaultOnEmptyChange={true}
                                    />
                                )
                            }}
                        />
                        <div className={classes.chatListWrap}>
                            <List>
                                {otherContactList && otherContactList.map((chatRoom, index) => {
                                    return (
                                        <ListItem button key={index} className={classes.chatItem} onClick={chatRoom?.formattedRoom?.isGroup ? () => { initGroupChat(chatRoom) } : () => { initChat(chatRoom) }}>
                                            <ListItemIcon>
                                                {chatRoom?.formattedRoom?.isGroup ?
                                                    <Avatar className={classes.AvatarIcons} src={groupAvtar} />
                                                    :
                                                    <Avatar className={classes.AvatarIcons} src={chatRoom?.formattedRoom?.displayPicture ? chatRoom?.formattedRoom?.displayPicture :participantAvtar} />
                                                }
                                            </ListItemIcon>
                                            <ListItemText
                                                className={classes.UserNameMessage}
                                                secondary={chatRoom?.lastMessage?.type == "text"
                                                    ? <span id={`msg-preview-${index}`}>{getChatListLastMsg(chatRoom?.lastMessage?.payload.message, index)}</span>
                                                    : chatRoom?.lastMessage?.payload.message} // if last message  is type media 
                                            >
                                                {chatRoom.formattedRoom.name}
                                            </ListItemText>

                                            <ListItemText
                                                className={classes.UserTimeCount}
                                                primary={getLastMessageTime(chatRoom)}
                                                secondary={chatRoom.unreadMessageCount > 0 ? chatRoom.unreadMessageCount : null}
                                                align="right"
                                            >
                                            </ListItemText>
                                        </ListItem>
                                    )
                                })}
                            </List>
                            {
                                otherContactList.length === 0 &&
                                <div className={classes.noResult}>No result found</div>
                            }
                        </div>
                    </div>
                {/* </Collapse> */}
            </div>
        </div>
    )
}

export default index;
