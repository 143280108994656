const drawerWidth =  250;

export const styles = (theme) => ({
  root: {
    display: "flex",  
    flex:1,
    height:'100vh',
    overflow:'hidden',
  },
  mainRoot:{
    '&:after':{
      content:"''",
      width:' 10px',
      height:' 69px',
      backgroundColor: '#fff',
      top:0,
      right:0,
      position:' absolute',
      zIndex: 0
    }
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: "white",
    // borderBottom: "solid 1px #caecf4",
    boxShadow: '0px 2px 10px rgba(133, 157, 177, 0.3)',
    width: 'calc(100% - 70px)'
  },
  appBarShift: {
    marginLeft: drawerWidth + 10,
    width: `calc(100% - ${drawerWidth + 10}px)`,
    right:9,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarFull: {
    marginLeft: drawerWidth + 10,
    width: '100%',
    right:9,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow: '0px 2px 10px rgba(133, 157, 177, 0.3)'
  },
  menuButton: {
    marginRight: 36,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    height: '100vh',
    // overflowY: 'auto',
  },
  ScrollDrawer:{
    padding: "30px 0px 60px 0px",
    position:'relative',
    zIndex:1200
  },
  drawerOpen: {
    width: drawerWidth,
    position:'relative',
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.background.main,
    border: "none",
  },
  drawerClose: {
    padding:0,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.background.main,
    border: "none",
    overflow: "unset",
    width: 70,
    height:'100%',
    [theme.breakpoints.up("sm")]: {
      width: 70
    },
    // [theme.breakpoints.down("sm")]: {
    //   display: "none",
    // },
    "& .MuiListItemText-root": {
      display: "none",
    },
    '& .MuiListItemIcon-root img':{
      width:'30px !important'
    },
    '& ul a .MuiListItem-root':{
      paddingTop:15,
      paddingBottom:15
    },
  },
  menuLink: {
    color: theme.palette.text.sideBarLink,
    display:'block',
    position: "relative",
    "& .MuiListItem-root": {
      position: "relative",
      
    },
    "&:hover , &.active":{
      textDecoration: "none",
      color: theme.palette.text.sideBarLink,
    },    
    "&.disabled:hover": {
      backgroundColor: "transparent"
    },
    "&.active": {
      "& > .MuiListItem-root::before": {
        content: "''",
        height: "100%",
        width: "3px",
        position: "absolute",
        left: "0",
        backgroundColor: "#59bad1",
      }
    },
    "& .MuiListItemIcon-root": {
      minWidth: "40px",
    },
    "& .MuiIcon-root": {
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
    "&:hover > .MuiListItemIcon-root, &.active > .MuiListItemIcon-root": {
      color: theme.palette.text.mainLableBold,
    },
    "& img": {
      width: "16px",
    },
  },
  menuItem: {
    "& .MuiListItemIcon-root": {
      color: "#59bad1",
    },
    "& .MuiListItemText-root > span": {
      fontSize: "14px",
      lineHeight: "1.14",
      letterSpacing: "-0.28px",
    },
    "&:not(.disabled):hover , &.Mui-selected": {
      backgroundColor: `${theme.palette.background.menuHover} !important`,
      textDecoration: "none",
    },
    "&.disabled:hover": {
      backgroundColor: "transparent"

    },
    "&.disabled": {
      cursor: "pointer"

    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 2),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: "#F2F3F5",
    minHeight: "100%",
    height: '100vh',
    overflowY: 'overlay',
    position: 'relative',
    '&::-webkit-scrollbar': {
      backgroundColor:'#fff'
    },
    ['@supports not (overflow: overlay)']:{
      overflowY: 'auto',
      scrollbarColor: '#888 #fff' 
    }
  },
  contentWhite: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: "#FFFFFF",
    minHeight: "100%",
    height: '100vh',
    overflowY: 'overlay',
    position: 'relative',
    '&::-webkit-scrollbar': {
      backgroundColor:'#fff'
    },
    ['@supports not (overflow: overlay)']:{
      overflowY: 'auto',
      scrollbarColor: '#888 #fff' 
    }
  },
  listedContent: {
    padding: 0,
  },
  sectionDesktop: {
    textAlign: "right",
    display: "none",
    marginLeft: "auto",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      alignItems: "center",
    },
    "& > button.menuIconsLinks": {
      color: theme.palette.icons.main,
    },
    /* "& > button.menuIconsLinks:hover": {
      color: theme.palette.icons.hover,
      backgroundColor: "unset",
    }, */
    "& > button.menuIconsLinks .material-icons": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& > button.menuIconsLinks svg.calenderico": {
      fontSize: "17px",
    },
  },
  sectionMobile: {
    display: "flex",
    marginLeft: "auto",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  profileIcon: {
    height: "40px",
    width: "40px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "50%",
    backgroundColor: "lightgrey",
    // backgroundImage: (props) => props.imageSrc
  },
  profileTextContainer: {
    textAlign: "left",
    minWidth: "80px",
    padding: "0 8px",
    display: "block",
  },
  profileName: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: 1.14,
    letterSpacing: "-0.28px",
    // color: theme.palette.text.mainLableBold,
    display: "block",
  },
  profileRole: {
    fontSize: "10px",
    fontWeight: "300",
    lineHeight: "1.1",
    letterSpacing: "-0.2px",
    display: "block",
  },
  profileButton: {
    borderRadius: 0,
    "& .profileMenuDropdown": {
      display: "none",
    },
    /* "&:hover": {
      backgroundColor: theme.palette.background.main,
      color: `${theme.palette.text.sideBarLink} !important`
    }, */
    "& .openProfileMenuDropdown": {
      display: "block",
    },
  },
  menuSeparator: {
    width: "0",
    height: "30px",
    margin: "0 15px",
    border: "solid 1px #c3cdd9",
  },
  profileMenu: {
    width: "100%",
    position: "absolute",
    backgroundColor: "#ffffff",
    top: "100%",
    textAlign: "left",
    padding: "5px 0",
    borderBottomRightRadius: "5px",
    borderBottomLeftRadius: "5px",
    boxShadow: '0px 0px 12px -2px rgba(0, 0, 0, 0.18)',
    "&>div": {
      display: "block",
      color: theme.palette.text.sideBarLink,
      fontSize: "14px",
      lineHeight: "1.14",
      letterSpacing: "-0.28px",
      padding: "0px 10px 5px",
      fontWeight: "500",
      "&:hover ,&.active": {
        backgroundColor: '#EDEFF3',
      },
    },
    "&>a": {
      display: "block",
      color: theme.palette.text.sideBarLink,
      fontSize: "14px",
      lineHeight: "1.14",
      letterSpacing: "-0.28px",
      padding: "8px 20px",
      fontWeight: "500",
      "&:hover": {
        backgroundColor: theme.palette.background.menuHover,
        textDecoration: "none",
      },
    },
    "& > div > a": {
      display: "block",
      color: theme.palette.text.sideBarLink,
      fontSize: "14px",
      lineHeight: "1.14",
      letterSpacing: "-0.28px",
      padding: "8px 20px",
      fontWeight: "400",
      "&:hover": {
        backgroundColor: theme.palette.background.menuHover,
        // fontWeight: "400",

        textDecoration: "none",
      },
    },
  },

  mobileMenu: {
    "& ul": {
      backgroundColor: theme.palette.background.main,
    },
    "& li": {
      padding: "0 22px 0 10px",
      display: "block",
      color: theme.palette.text.mainLableBold,
      fontSize: "14px",
      lineHeight: "1.14",
      letterSpacing: "-0.28px",
      fontWeight: "500",
      "&:hover": {
        backgroundColor: theme.palette.background.menuHover,
        textDecoration: "none",
      },
    },
  },
  nested: {
    "& .MuiListItem-root": {
      padding: "8px 48px",
      display: "flex"
    },
  },
  logo: { 
    width: "100%", 
    height: "100%" ,  
    marginBottom:"27px",
  },
  menuBottom:{
    backgroundColor: "#32529D !important",
    padding:"5px",
    position: "fixed",
    left: 0,
    bottom: 0,
    width:"250px",
    height: "60px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center"
  },
  version:{
    fontSize: 10,
    marginLeft: 5,
    color: '#FFF',
    alignSelf: 'flex-end',
    marginBottom: '6px'
  },
  MobileMenuDropdown:{
    position: 'absolute',
    visibility: 'visible',
    opacity: 1,
    width: 250,
    height: 'auto !important',
    zIndex: 99999999999999,
    left: '100%',
    backgroundColor: '#32529D',
    top:0,
    "& .MuiListItemText-root": {
      display: "block",
      // padding: '19px 30px',
      margin: 0,
      height:58
    },
    '& .MuiListItem-root':{
      padding: '15px 20px',      
    },
    '& a:hover':{
      textDecoration:'none'
    }
  },
  customhoverlink:{
    '& .onhover-open':{
      display:'none',
      padding:0
    },
    '&:hover .onhover-open':{
      display:'block'
    }
  },
  MenuTopPrimary:{
    fontSize:'18px !important',
    color: '#fff'
  },
  MobileMenuLink:{
    padding:0,
    '& .menu-item-mobile':{
      padding: '17px 15px'
    }
  },
  nestedOnMobile:{
    '& .MuiListItemText-root':{
      padding: '20px 40px'
    },
    '& a span':{
      fontSize:'16px !important'
    },
    '& a:hover':{
      backgroundColor:theme.palette.background.menuHover
    }
  },
  MenuTopPrimaryWrap:{
    backgroundColor: '#142E6C',
    padding: '19px 20px',
  },
  toolbarClose:{
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px 7px 0px 7px",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  baseContainer: {
    background: "#32529D",
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "sticky",
    top: "0px",
    padding:"10%"
},
subMenuRoles: {
  width: "100%",
  position: "absolute",
  backgroundColor: "#ffffff",
  top: "35%",
  right:"100%",
  textAlign: "left",
  padding: "5px 0",
  borderBottomRightRadius: "5px",
  borderBottomLeftRadius: "5px",
  boxShadow: '0px 0px 12px -2px rgba(0, 0, 0, 0.18)',
  "&>div": {
    display: "block",
    color: theme.palette.text.sideBarLink,
    fontSize: "14px",
    lineHeight: "1.14",
    letterSpacing: "-0.28px",
    padding: "0px 10px 5px",
    fontWeight: "500",
    "&:hover ,&.active": {
      backgroundColor: '#EDEFF3',
    },
  },
  "&>a": {
    display: "block",
    color: theme.palette.text.sideBarLink,
    fontSize: "14px",
    lineHeight: "1.14",
    letterSpacing: "-0.28px",
    padding: "8px 20px",
    fontWeight: "500",
    "&:hover": {
      backgroundColor: theme.palette.background.menuHover,
      textDecoration: "none",
    },
  },
  "& > div > a": {
    display: "block",
    color: theme.palette.text.sideBarLink,
    fontSize: "14px",
    lineHeight: "1.14",
    letterSpacing: "-0.28px",
    padding: "8px 20px",
    fontWeight: "400",
    "&:hover": {
      backgroundColor: theme.palette.background.menuHover,
      // fontWeight: "400",

      textDecoration: "none",
    },
  },
},
subMenufacility: {
  width: "100%",
  position: "absolute",
  backgroundColor: "#ffffff",
  maxHeight:"250px",
  overflow:'auto',
  top: "40%",
  right:"100%",
  textAlign: "left",
  padding: "5px 0",
  borderBottomRightRadius: "5px",
  borderBottomLeftRadius: "5px",
  boxShadow: '0px 0px 12px -2px rgba(0, 0, 0, 0.18)',
  "&>div": {
    display: "block",
    color: theme.palette.text.sideBarLink,
    fontSize: "14px",
    lineHeight: "1.14",
    letterSpacing: "-0.28px",
    padding: "0px 10px 5px",
    fontWeight: "500",
    "&:hover ,&.active": {
      backgroundColor: '#EDEFF3',
    },
  },
  "&>a": {
    display: "block",
    color: theme.palette.text.sideBarLink,
    fontSize: "14px",
    lineHeight: "1.14",
    letterSpacing: "-0.28px",
    padding: "8px 20px",
    fontWeight: "500",
    "&:hover": {
      backgroundColor: theme.palette.background.menuHover,
      textDecoration: "none",
    },
  },
  "& > div > a": {
    display: "block",
    color: theme.palette.text.sideBarLink,
    fontSize: "14px",
    lineHeight: "1.14",
    letterSpacing: "-0.28px",
    padding: "8px 20px",
    fontWeight: "400",
    "&:hover": {
      backgroundColor: theme.palette.background.menuHover,
      // fontWeight: "400",

      textDecoration: "none",
    },
  },
},
borderbottom:
{
  borderBottom: '1px solid #E6E6E6'
},
grey:{
  // "&:hover ,&.active": {
  //   backgroundColor: '#EDEFF3',
  // },
},
activeback:
{
  backgroundColor: '#EDEFF3',
  borderBottom: '1px solid #E6E6E6' 
}
});