import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { USER_ROLES } from "../../utils/constants";
import {setPickUp,UpdateInstInfo} from "../../store/actions"
import { json } from "stream/consumers";
export let sendInstant, ws, closeInstant;

export var onMessageCallback ,calling = false,SocketConnection;

var  intervalData ;

export const RepeatCall=(id:string,role:any,string:string)=>{
        calling = true
        intervalData = setInterval(()=>{
            ws.send(JSON.stringify({'userId':id,'role':role,"method":string}))
        },3000)
}
export const stopRepeatCall=()=>{
    intervalData && clearInterval(intervalData);
}
export function InstantVideo(fn:()=>void,Socket:()=>void,payload:any) {
    onMessageCallback = fn.bind(fn);
    var aliveCall;
    SocketConnection = Socket.bind(Socket);

   // const dispatch =useDispatch();
    ws = new WebSocket('wss://devapis.Securrahealth.com/ws/');
  

    console.log('websocket is connected ...')

    ws.onopen = function () {
        console.log('websocket is connected ...')
        SocketConnection(true);
        ws.readyState === 1;
        ws.send(JSON.stringify(payload));
        aliveCall=setInterval(()=>{ws.send(JSON.stringify({"method":"keepAlive"}))},1000);
    }

    ws.onmessage = function (event) {
        console.log(`[message] Data received from server: ${event.data}`);
        //setData(event.data)
        onMessageCallback(event.data);
        calling = false;
         clearInterval(intervalData);
        // dispatch(setPickUp({ pickup: true}));
        // dispatch(UpdateInstInfo(event.data));
    };

    ws.onclose = function (event) {
        if (event?.wasClean) {
            console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
        } else {
            console.log('[close] Connection died');
        }
        calling = false;
        clearInterval(intervalData);
        clearInterval(aliveCall);
        SocketConnection(false);
    };

    ws.onerror = function (error) {
        console.log(`error`, error);
        calling = false;
        clearInterval(intervalData);
        clearInterval(aliveCall);
        SocketConnection(false);
    };

    sendInstant = ws.send.bind(ws);
    closeInstant = ws.close.bind(ws);
}
// export const registerForMessageCallback = fn => {
//     // The callback function is supplied as an argument and assigned to the
//     // `onMessageCallback` variable we declared earlier
//     onMessageCallback = fn;
// };
/* const InstantChat = () => {
    const [data, setData] = useState<any>()

    useEffect(()=>{
        setDoctorActive('63a5846176ed667268be3114',setData)
    },[])

    return(<></>)
}

export default InstantChat; */