export const ViewContent = ({document}:any) => {
const fileExtenstion = document && document?.uri?.split("?")[0].split(".")[document?.uri?.split("?")[0].split(".").length - 1]

  return (
    <div style={{
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        height: '100%',
        border: '1px solid #000',
        backgroundSize: '20px 20px',
        backgroundPosition: '0 0,0 10px,10px -10px,-10px 0px'
    }}>
      {fileExtenstion === "pdf" ? (
        <iframe height="100%" width="100%" src={document.uri}></iframe>
      ) : (
        <img src={document.uri}></img>
      )}
    </div>
  );
};
