import {
  FormatListNumbered,
  Label,
  PaymentRounded,
  QuestionAnswer,
  VideoCallRounded
} from "@material-ui/icons";
import AHPInactiveIcon from "../assets/images/icons/ahp_inactive.svg";
import AppointmentInactiveIcon from "../assets/images/icons/appointment_inactive.svg";
import AdminInactiveIcon from "../assets/images/icons/admin_inactive.svg";
import ClinicInactiveIcon from "../assets/images/icons/clinic_inactive.svg";
import CareProgramInactiveIcon from "../assets/images/icons/cp_inactive.svg";
import EnrolmentRequestIcon from "../assets/images/icons/wp_enrollment_req.svg";
import DevicesInactiveIcon from "../assets/images/icons/devices_inactive.svg";
import DoctorInactiveIcon from "../assets/images/icons/doctor_inactive.svg";
import HomeInactiveIcon from "../assets/images/icons/home_inactive.svg";
import PatientInactiveIcon from "../assets/images/icons/patient_inactive.svg";
import PaymentInactiveIcon from "../assets/images/icons/payment_inactive.svg";
import SettingsInactiveIcon from "../assets/images/icons/settings_inactive.svg";
import ChatMessage from "../assets/images/icons/chat-message.svg";
import LabPartnerIcon from "../assets/images/icons/lab_partner.svg";
import questionLibIcon from "../assets/images/healthProfiler/question_library_inactive.svg";
import questionLibSetIcon from "../assets/images/healthProfiler/question_set_library_inactive.svg";
import tempLibIcon from "../assets/images/healthProfiler/template_library_inactive.svg";
import dataParamLibIcon from "../assets/images/healthProfiler/data_parameter_library_inactive.svg";
import associationsIcon from "../assets/images/healthProfiler/associations_inactive.svg";
import VectorInactiveIcon from "../assets/images/icons/tag_icon_inactive.svg";
import AppointmentIcon from "..//assets/images/icons/appointment_inactive.svg";
import HealthProfiler from "../assets/images/icons/ic_health_profiler_logo.svg"
import FacilityIcon from "../assets/images/FacilityIcon.svg";
import DoctorIcon from "../assets/images/DoctorIcon.svg";
import ic_doctor_emp from "../assets/images/icons/ic_doctor_emp.svg";
import ic_facility_emp from "../assets/images/icons/ic_facility_emp.svg";
import ic_doctor from "../assets/images/menu-drawer/ic_menu_doctor.svg"
import ic_admin from "../assets/images/menu-drawer/ic_menu_admin.svg"
import ic_home from "../assets/images/menu-drawer/ic_menu_home.svg"
import ic_medicine from "../assets/images/menu-drawer/ic_menu_medicine.svg"
import ic_others from "../assets/images/menu-drawer/ic_menu_others.svg"
import ic_payment from "../assets/images/menu-drawer/ic_menu_payment.svg"
import ic_profile from "../assets/images/menu-drawer/ic_menu_profile.svg"
import ic_settings from "../assets/images/menu-drawer/ic_menu_settings.svg"
import ic_users from "../assets/images/menu-drawer/ic_menu_users.svg"
import ic_ahp from "../assets/images/menu-drawer/ic_menu_ahp.svg"
import ic_appointments from "../assets/images/menu-drawer/ic_menu_appoinments.svg"
import ic_ePharma from "../assets/images/menu-drawer/ic_menu_e-pharma.svg"
import ic_diagonosis from "../assets/images/menu-drawer/ic_menu_diagonosis.svg"
import ic_consultation from "../assets/images/menu-drawer/ic_menu_consultation.svg"
import ic_patient from "../assets/images/menu-drawer/ic_menu_patients.svg"
import ic_scheduler from "../assets/images/menu-drawer/ic_menu_scheduler.svg"


export const MENU_EMPANELMENT_ADMIN = [

  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img
          src={ic_home}
          className="inactive"
          style={{ width: "19px" }}
        />
      </>
    ),
    submenu: []
  },
  // {
  //   label: "Prescription",
  //   url: "/Prescription",
  //   icon: (
  //     <>
  //       <img
  //         src={HomeInactiveIcon}
  //         className="inactive"
  //         style={{ width: "19px" }}
  //       />
  //     </>
  //   ),
  //   submenu: []
  // },
  // {
  //   label: "Lab Transcriber",
  //   url: "/labTranscriber",
  //   icon: (
  //     <>
  //       <img
  //         src={HomeInactiveIcon}
  //         className="inactive"
  //         style={{ width: "19px" }}
  //       />
  //     </>
  //   ),
  //   submenu: []
  // },
  {
    label: "Facility Empanelment",
    url: "/Empanelment/facilityEmpanelment",
    icon: (
      <>
        <img
          src={ic_facility_emp}
          className="inactive"
          style={{ width: "19px" }}
        />
      </>
    ),
    submenu: []
  },
  {
    label: "Doctor Empanelment",
    url: "/Empanelment/DoctorEmpanelment",
    icon: (
      <>
        <img
          src={ic_doctor_emp}
          className="inactive"
          style={{ width: "15px" }}
        />
      </>
    ),
    submenu: []
  },
  //Except dashboard all side menu items are temporarily removed for Star release
 /* {
    label: "Facility Empanelment",
    url: "/empanelment/facility",
    icon: (
      <>
        <img
          src={ClinicInactiveIcon}
          className="inactive"
          style={{ width: "19px" }}
        />
      </>
    ),
    submenu: []
  }, {
    label: "Doctor Empanelment",
    url: "/empanelment/doctor",
    icon: (
      <>
        <img
          src={HomeInactiveIcon}
          className="inactive"
          style={{ width: "19px" }}
        />
      </>
    ),
    submenu: []
  },
  {
    label: "Dashboard - Admin",
    url: "/admin/facility-detail",
    icon: (
      <>
        <img
          src={HomeInactiveIcon}
          className="inactive"
          style={{ width: "19px" }}
        />
      </>
    ),
    submenu: []
  },
  {
    label: "Manage users",
    url: "/admin",
    disabled: true,
    icon: (
      <>
        <img
          src={HomeInactiveIcon}
          className="inactive"
          style={{ width: "19px" }}
        />
      </>
    ),
    submenu: [
      {
        label: "Admins",
        url: "/admin/admins-list",
        icon: (
          <>
            <img src={AdminInactiveIcon} className="inactive" />
          </>
        )
      },
      {
        label: "Doctors",
        url: "/admin/doctors-list",
        icon: (
          <>
            <img src={AdminInactiveIcon} className="inactive" />
          </>
        )
      },
      {
        label: "AHPs",
        url: "/admin/ahps-list",
        icon: (
          <>
            <img src={AdminInactiveIcon} className="inactive" />
          </>
        )
      },
    ]
  },
  {
    label: "Dashboard",
    url: "/home",
    icon: (
      <>
        <img
          src={HomeInactiveIcon}
          className="inactive"
          style={{ width: "19px" }}
        />
      </>
    ),
    submenu: []
  }, 
  {
    label: "Tags",
    url: "/tags",
    icon: (
      <>
        <img
          src={VectorInactiveIcon}
          className="inactive"
          style={{ width: "19px" }}
        />
      </>
    ),
    submenu: []
  },
   {
    label: "Profile",
    url: "/clinic",
    disabled: true,
    icon: (
      <>

        <img
          src={ClinicInactiveIcon}
          className="inactive"
          style={{ width: "19px" }}
        />
      </>
    ),
    submenu: [
      {
        label: "Admin",
        url: "/clinic/admin",
        icon: (
          <>
            <img src={AdminInactiveIcon} className="inactive" />
          </>
        )
      },
      {
        label: "Doctors",
        url: "/clinic/doctors",
        icon: (
          <>
            <img src={DoctorInactiveIcon} className="inactive" />
          </>
        )
      },
      {
        label: "Participants",
        url: "/clinic/patients",
        icon: (
          <>
            <img src={PatientInactiveIcon} className="inactive" />
          </>
        )
      },
      {
        label: "Guest Users",
        url: "/clinic/guest-users",
        icon: (
          <>
            <img src={PatientInactiveIcon} className="inactive" />
          </>
        )
      },
       {
        label: "AHP(s)",
        url: "/clinic/ahp",
        icon: (
          <>
            <img src={AHPInactiveIcon} className="inactive" />
          </>
        )
      }
    ]
  },
  {
    label: "Appointments",
    url: "/appointments/scheduler",
    icon: (
      <>
        <img src={AppointmentInactiveIcon} className="inactive" />
      </>
    ),
    submenu: [
      {
        label: "Appointment Scheduler",
        url: "/",
        icon: (
          <>
            <img src={AppointmentInactiveIcon} className="inactive" />
          </>
        )
      },
      {
        label: "Availability management",
        url: "/appointments/scheduler",
        icon: (
          <>
            <img src={AppointmentInactiveIcon} className="inactive" />
          </>
        )
      }
    ]
  },
  {
    label: "Program Management",
    url: "/care-program",
    disabled: true,
    icon: (
      <>

        <img
          src={CareProgramInactiveIcon}
          className="inactive"
          style={{ width: "19px" }}
        />
      </>
    ),
    submenu: [
      {
        label: "Wellness Programs",
        url: "/care-program/wellness-program",
        icon: (
          <>
            <img src={CareProgramInactiveIcon} className="inactive" />
          </>
        )
      },

      {
        label: "Groups",
        url: "/care-program/groups",
        icon: (
          <>
            <img src={CareProgramInactiveIcon} className="inactive" />
          </>
        )
      },
      {
        label: "Enrollment Requests",
        url: "/care-program/enrollment-request",
        icon: (
          <>
            <img src={EnrolmentRequestIcon} className="inactive" />
          </>
        )
      },
    ]
  },
  {
    label: "Device Management",
    url: "/device-management",
    disabled: true,
    icon: (
      <>
        <img src={DevicesInactiveIcon} className="inactive" />
      </>
    ),
    submenu: []
  },
  {
    label: "Payments",
    url: "/payment",
    disabled: true,
    icon: (
      <>
        <img src={PaymentInactiveIcon} className="inactive" />
      </>
    ),
    submenu: [
      {
        label: "Care Program",
        url: "/payment/care-plan-payment",
        icon: (
          <>
            <img src={CareProgramInactiveIcon} className="inactive" />
          </>
        )
      },
      {
        label: "Tele Consultation",
        url: "/payment/tele-consultation-payment",
        icon: (
          <>
            <VideoCallRounded className="inactive" />
          </>
        )
      },
      {
        label: "Adhoc Payments",
        url: "/payment/adhoc-payments",
        icon: (
          <>
           <PaymentRounded className="inactive" />
          </>
        )
      }
    ]
  },
  {
    label: "Question Management",
    url: "/question",
    disabled: true,
    icon: (
      <>
        <QuestionAnswer className="inactive" />
      </>
    ),
    submenu: [
      {
        label: "Questions",
        url: "/question/list",
        icon: (
          <>
            <FormatListNumbered className="inactive" />
          </>
        )
      },
      {
        label: "Tagging",
        url: "/question/tagging",
        icon: (
          <>
            <Label className="inactive" />
          </>
        )
      }
    ]
  },
  {
    label: "Health Profiler",
    url: "/health-profiler",
    icon: (
      <>
        <img src={LabPartnerIcon} className="inactive" />
      </>
    ),
    submenu: [
      {
        label: "Question Library",
        url: "/health-profiler/hp-questions",
        icon: (
          <>
            <img src={questionLibIcon} className="inactive"/>
          </>
        )
      },
      {
        label: "Question Set Library",
        url: "/health-profiler/hp-question-set",
        icon: (
          <>
            <img src={questionLibSetIcon} className="inactive" />
          </>
        )
      },
      {
        label: "Templates Library",
        url: "/health-profiler/template",
        icon: (
          <>
            <img src={tempLibIcon} className="inactive" />
          </>
        )
      },
      {
        label: "Data Parameter Library",
        url: "/health-profiler/data-master",
        icon: (
          <>
            <img src={dataParamLibIcon} className="inactive" />
          </>
        )
      },
      {
        label: "Associations",
        url: "/health-profiler/Associations",
        icon: (
          <>
            <img src={associationsIcon} className="inactive" />
          </>
        )
      }
    ]
  },


  {
    label: "Lab Partner",
    url: "/LabPartner",
    icon: (
      <>
        <img src={LabPartnerIcon} className="inactive" />
      </>
    ),
    submenu: []
  },
  {
    label: "Settings",
    url: "/my-account",
    icon: (
      <>
        <img src={SettingsInactiveIcon} className="inactive" />
      </>
    ),
    submenu: []
  },
  {
    label: "Chat",
    url: "/chats",
    icon: (
      <>
        <img src={ChatMessage} className="inactive" />
      </>
    ),
    submenu: []
  },
  {
    label: "Dashboard",
    url: "/Empanelment/index",
    icon: (
      <>
        <img
          src={HomeInactiveIcon}
          className="inactive"
          style={{ width: "19px" }}
        />
        </>
        ),
        submenu:[]
  },
  {
    label: "Facility Empanelment",
    url: "/Empanelment/facilityEmpanelment",
    icon: (
      <>
        <img src={FacilityIcon} className="inactive" />
      </>
    ),
    submenu: []
    },
  {
    label: "Doctor Empanelment",
    url: "/Empanelment/DoctorEmpanelment",
    icon: (
      <>
        <img src={DoctorIcon} className="inactive" />
      </>
    ),
    submenu: []
  },
  {
    label: "Profile Doctor",
    url: "/newprofile/doctor",
    icon: (
      <>
        <img src={ChatMessage} className="inactive" />
      </>
    ),
    submenu: []
  },
  {
    label: "Profile Admin",
    url: "/newprofile/admin",
    icon: (
      <>
        <img src={ChatMessage} className="inactive" />
      </>
    ),
    submenu: []
  },
  {
    label: "Profile Wellness",
    url: "/newprofile/wellness",
    icon: (
      <>
        <img src={ChatMessage} className="inactive" />
      </>
    ),
    submenu: []
  },
  {
    label: "Facility Settings",
    url: "/newprofile/facility",
    icon: (
      <>
        <img src={ChatMessage} className="inactive" />
      </>
    ),
    submenu: []
  },*/
];

export const Operational_Receptionist_Login = [
  {
    label: "Dashboard",
    url: "/OperationalReceptionist/dashboard",
    icon: (
      <>
        <img src={ic_home} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Slot Management",
    url: "/appointment-scheduler",
    icon: (
      <>
        <img src={ic_scheduler} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Appointments",
    url: "/Appointments",
    icon: (
      <>
        <img src={ic_appointments} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "My Profile",
    url: "/newprofile/operational-recepptionist",
    icon: (
      <>
        <img src={ic_profile} className="inactive" />
      </>
    ),
    submenu: [],
  },
]

export const Receptionist_Login = [
  {
    label: "Dashboard",
    url: "/Receptionist/dashboard",
    icon: (
      <>
        <img src={ic_home} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Slot Management",
    url: "/appointment-scheduler",
    icon: (
      <>
        <img src={ic_scheduler} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Appointments",
    url: "/Appointments",
    icon: (
      <>
        <img src={ic_appointments} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "My Profile",
    url: "/newprofile/receptionist",
    icon: (
      <>
        <img src={ic_profile} className="inactive" />
      </>
    ),
    submenu: [],
  },
]
export const MENU_SUPER_ADMIN = [
  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img src={ic_home} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Manage users",
    url: "/ManageUsers/",
    disabled:true,
    icon: (
      <>
        <img src={ic_users} className="inactive" />
      </>
    ),
    submenu: [
      {
        label: "Admins",
        url: "/ManageUsers/Admin",
        icon: (
          <>
            <img src={ic_admin} className="inactive" />
          </>
        ),  
      },
      {
        label: "Doctors",
        url: "/ManageUsers/Doctor",
        icon: (
          <>
            <img src={ic_doctor} className="inactive" />
          </>
        ),  
      },
      // commenting as per bug 19743
      // {
      //   label: "AHPs",
      //   url: "/ManageUsers/AHP",
      //   icon: (
      //     <>
      //       <img src={ic_ahp} className="inactive" />
      //     </>
      //   ),  
      // },
      {
        label: "Patients",
        url: "/ManageUsers/Patients",
        icon: (
          <>
            <img src={ic_patient} className="inactive" />
          </>
        ),  
      },
      {
        label: "Others",
        url: "/ManageUsers/Others",
        icon: (
          <>
            <img src={ic_others} className="inactive" />
          </>
        ),  
      },
      
    ],
  }, 
  {
    label: "Slot Management",
    url: "/appointment-scheduler",
    icon: (
      <>
        <img src={ic_scheduler} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Facility Settings",
    url: "/newprofile/facility",
    icon: (
      <>
        <img src={ic_settings} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "My Profile",
    url: "/newprofile/admin",
    icon: (
      <>
        <img src={ic_profile} className="inactive" />
      </>
    ),
    submenu: [],
  },
];
export const MENU_FACILITY_ADMIN = [
  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img src={ic_home} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Manage users",
    url: "/ManageUsers/",
    disabled:true,
    icon: (
      <>
        <img src={ic_users} className="inactive" />
      </>
    ),
    submenu: [
      {
        label: "Admins",
        url: "/ManageUsers/Admin",
        icon: (
          <>
            <img src={ic_admin} className="inactive" />
          </>
        ),  
      },
      {
        label: "Doctors",
        url: "/ManageUsers/Doctor",
        icon: (
          <>
            <img src={ic_doctor} className="inactive" />
          </>
        ),  
      },
      // commenting as per bug 19743
      // {
      //   label: "AHPs",
      //   url: "/ManageUsers/AHP",
      //   icon: (
      //     <>
      //       <img src={ic_ahp} className="inactive" />
      //     </>
      //   ),  
      // },
      
      {
        label: "Patients",
        url: "/ManageUsers/Patients",
        icon: (
          <>
            <img src={ic_patient} className="inactive" />
          </>
        ),  
      },
      {
        label: "Others",
        url: "/ManageUsers/Others",
        icon: (
          <>
            <img src={ic_others} className="inactive" />
          </>
        ),  
      },
    ],
  }, 
  {
    label: "Slot Management",
    url: "/appointment-scheduler",
    icon: (
      <>
        <img src={ic_scheduler} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Appointments",
    url: "/Appointments",
    icon: (
      <>
        <img src={AppointmentIcon} className="inactive" />
      </>
    ),
  submenu: []
  },
  {
    label: "Facility Settings",
    url: "/newprofile/facility",
    icon: (
      <>
        <img src={ic_settings} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "My Profile",
    url: "/newprofile/admin",
    icon: (
      <>
        <img src={ic_profile} className="inactive" />
      </>
    ),
    submenu: [],
  },
];

export const MENU_FIELD_AGENT = [
  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img src={ic_home} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "My Profile",
    url: "/newprofile/admin",
    icon: (
      <>
        <img src={ic_profile} className="inactive" />
      </>
    ),
    submenu: [],
  },
];

export const MENU_DOCTOR = [
  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img src={ic_home} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Slot Management",
    url: "/appointment-scheduler",
    icon: (
      <>
        <img src={ic_scheduler} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Appointments",
    url: "/Appointments",
    icon: (
      <>
        <img src={AppointmentIcon} className="inactive" />
      </>
    ),
    submenu: []
  },
  {
    label: "My Profile",
    url: "/newprofile/doctor",
    icon: (
      <>
        <img src={ic_profile} className="inactive" />
      </>
    ),
    submenu: [],
  },
];

export const MENU_OPERATOR = [

  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img
          src={HomeInactiveIcon}
          className="inactive"
          style={{ width: "19px" }}
        />
      </>
    ),
    submenu: []
  },
  {
    label: "Appointments",
    url: "/Appointments",
    icon: (
      <>
        <img src={AppointmentIcon} className="inactive" />
      </>
    ),
    submenu: []
  },
  {
    label: "Followup Requests",
    url: "/Followup_requests",
    icon: (
      <>
        <img src={AppointmentIcon} className="inactive" />
      </>
    ),
    submenu: []
  },
  {
    label: "My Profile",
    url: "/newprofile/doctor",
    icon: (
      <>
        <img src={ic_profile} className="inactive" />
      </>
    ),
    submenu: [],
  },
]

export const MOBILE_MENU = [
  // {
  //   label: "Calender",
  //   url: "calender",
  //   icon: <CalendarTodayOutlined fontSize="small" className="calenderico" />
  // },
  // {
  //   label: "Notifications",
  //   url: "notification",
  //   icon: <NotificationsNoneOutlined fontSize="small" />
  // }
];

export const TRANSCRIPTION_AGENT = [
  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img src={ic_home} className="active" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Lab Tests",
    url: "/labTranscriber",
    icon: (
      <>
        <img src={AppointmentIcon} className="inactive" />
      </>
    ),
    submenu: []
  }
];

export const CLAIMS_PROCESS = [
  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img src={ic_home} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Consultation",
    url: "/claims/consultation",
    icon: (
      <>
        <img src={ic_consultation} className="inactive" />
      </>
    ),
    submenu: []
  },
  {
    label: "E - Pharmacy",
    url: "/claims/epharmacylist",
    icon: (
      <>
        <img src={ic_ePharma} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Diagnostics",
    url: "/claims/diagnostics",
    icon: (
      <>
        <img src={ic_diagonosis} className="inactive" />
      </>
    ),
    submenu: []
  }
];
