import { CircularProgress, IconButton, makeStyles } from "@material-ui/core";
import { AttachFileOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import Notify from "../../../common/CommonToast";
import { uploadToS3 } from "../../../../utils/uploadToS3";
import { styles } from "./styles";

const useStyles = makeStyles(styles);
const FileUploadButton = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState({
    loading: false,
    message: "",
    severity: "",
  });
  const triggerFileSelect = () => {
    document.getElementById(`file-input-${props.chatParticipantInfo.room}`).click()
  }
  const handleFileUpload = async (e) => {    
    setRequest({ loading: true, message: "", severity: "" });
    setLoading(true)
    if (e.target.files && e.target.files[0]) {
      const name=e.target.files[0].name;
      const payload={
        user_id: props.chatParticipantInfo?.isGroup 
                ? props.chatParticipantInfo.room 
                : props.chatParticipantInfo.chatUserId
      }      
      const s3Prefix="chat";
      const {url,err} = await uploadToS3(e.target.files[0], s3Prefix, name);
      if (props.onFileUpload) {
        props.onFileUpload(url)
      }
      // e.target.value = null      
      if (url && name) {        
        setRequest({
          loading: false,
          message: "Uploaded successfuly",
          severity:"success"
        });
      } else {
         setRequest({
          loading: false,
          message: err?.response?.data?.message || "Error Occured while uploading!",
          severity: "error",
        });
      }
    }
    setLoading(false)
  }

  const handleFileClick = (event)=>{
    event.target.value = ''
  }

  return (
    <>
    {request.message && (
      <Notify message={request.message} severity={request.severity} />
    )}
    <IconButton className={classes.root} onClick={triggerFileSelect} size="small">     
      {loading &&
        <CircularProgress style={{ height: "24px", width: "24px" }} />
      }
      {!loading &&
        <AttachFileOutlined fontSize="small"/>
      }
      <input
        type="file" id={`file-input-${props.chatParticipantInfo.room}`} onChange={handleFileUpload} onClick={handleFileClick}/>
    </IconButton>
    </>
  );
};

export default FileUploadButton;
