import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: any) => ({
   SecondaryContainer: {
      display: "flex",
      gap: "0.5rem",
      marginTop: "0.65rem",
   },
   //chat
   outerBox: {
      position: "fixed",
      bottom: 0,
      right: 0,
      zIndex: 1,
   },
   mainNormalAccordion: {
      width: "330px",
      borderRadius: "15px 15px 0px 0px",
      "& .MuiAccordionSummary-root.Mui-expanded": {
         borderBottom: "2px solid #F1F1F1"
      }
   },
   userPhoto: {
      height: "35px",
      width: "35px",
      backgroundSize: "cover",
      // backgroundPosition: "center",
      borderRadius: "50%",
      backgroundColor: "lightgrey",
      marginRight: "20px"
   },
   chatUserName: {
      lineHeight: "40px"
   },
   chatDetails: {
      height: "450px",
      overflow: "scroll",
      "& .MuiAccordionDetails-root": {
         borderTop: "2px solid #f1f1f1"
      }
   },
   chatText: {
      width: "100%"
   },
   sendermessage: {
      backgroundColor: "#34519C",
      padding: " 10px",
      color: "white",
      borderRadius: "12px 0px 12px 12px",
   },
   sendername: {
      fontWeight: 600,
      textAlign: "end",
      fontSize: "13px",

   },
   receivermessage: {
      backgroundColor: "#EBEFF4",
      padding: "10px",
      color: "black",
      borderRadius: "0px 12px 12px 12px",
   },
   receivername: {
      fontWeight: 600,
      textAlign: "start",
      fontSize: "13px"
   },
   senderStyle: {
      marginTop: "10px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end"
   },
   receiverStyle: {
      marginTop: "10px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start"
   }
}));

export default useStyles