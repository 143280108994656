import * as actionTypes from "./actionTypes";

export const registerStart = () => ({ type: actionTypes.REGISTER_START });

export const registerSuccess = (data) => ({
  payload: data,
  type: actionTypes.REGISTER_SUCCESS,
});

export const registerFail = (error) => ({
  error,
  type: actionTypes.REGISTER_FAIL,
});

export const registerMessage = (message) => ({
  message,
  type: actionTypes.REGISTER_MESSAGE,
});

export const register = (data, onSuccess) => ({
  type: actionTypes.REGISTER_USER,
  payload: data,
  onSuccess,
});

export const registerOtpSuccess = (token, role, mobileData) => ({
  payload: token,
  role,
  mobileData,
  type: actionTypes.REGISTER_OTP_SUCCESS,
});

export const registerOneTimeToken = (payload) => ({
  payload,
  type: actionTypes.REGISTER_OTP_ONE_TIME_TOKEN,
});