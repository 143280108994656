import {
  CHAT_ROOM_SERVICE_URL,
  CHAT_USERS_SERVICE_URL,
  CLINIC_SERVICE_URL,
  NEW_CHAT_PARTICIPANTS,
} from "../config/url.config";
import Services from "./services";

const instance = new Services().getServiceInstance();

//Change the name userId param to chatUserId
export const getChatUserApi = (userId) => {
  return instance
    .get(CHAT_USERS_SERVICE_URL + userId)
    .then((res) => res)
    .catch((err) => err.response);
};

export const getUserChatDetail = (userId) => {
  return instance
    .get(CHAT_USERS_SERVICE_URL + "details/"+ userId)
    .then((res) => res)
    .catch((err) => err.response);
};

export const createChatUserApi = (data) => {
  return instance
    .post(CHAT_USERS_SERVICE_URL, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const getChatRoomApi = (refId) => {
  return instance
    .get(CHAT_ROOM_SERVICE_URL + "reference/" + refId)
    .then((res) => res)
    .catch((err) => err.response);
};

export const createChatRoomApi = (data) => {
  return instance
    .post(CHAT_ROOM_SERVICE_URL, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const addParticipantToRoomApi = (roomId, participantId) => {
  return instance
    .post(
      CHAT_ROOM_SERVICE_URL +
        `${roomId}/chat-room-participants/${participantId}`
    )
    .then((res) => res)
    .catch((err) => err.response);
};

export const getChatUserByIdApi = (id, role) => {
  const params = { id, role };
  return instance
    .get(CHAT_USERS_SERVICE_URL + "user", { params })
    .then(res => res)
    .catch(err => err.response);
};

export const getAllAssociates = (clinicId,search) => {
  const params = { search };
  return instance
    .get(`${CLINIC_SERVICE_URL}${clinicId}/associates`, { params })
    .then(res => res)
    .catch(err => err.response);
};

export const createOnetoOneChatRoom = (data) => {
  return instance
    .post(CHAT_ROOM_SERVICE_URL+'one-on-one', data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const getGroupParticipants = (roomId, page, limit,role,chatUser) => {
  const params = {
    limit: limit || 10,
    skip: 0,
  }
  if (page && limit) {
    params.skip = (page - 1) * limit
  }
  if(role){
    params["role"] = role
  }
  if(chatUser){
    params["chatUser"] = chatUser
  }
  return instance
    .get(`${CHAT_ROOM_SERVICE_URL}${roomId}/chat-room-participants`,{params})
    .then(res => res)
    .catch(err => err.response);
};

export const updateChatRoom = (roomId,data) => {
  return instance
    .patch(`${CHAT_ROOM_SERVICE_URL}${roomId}`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const getOnlineParticipantsCount = (roomReferenceId) => {
  return instance
    .get(`${CHAT_ROOM_SERVICE_URL}reference/${roomReferenceId}`)
    .then(res => res)
    .catch(err => err.response);
};

export const getChatRoomList = (chatUserId,clinicId) => {
  const params = {
    chatUser : chatUserId,
    clinicId
  }
  return instance
    .get(CHAT_ROOM_SERVICE_URL,{params})
    .then(res => res)
    .catch(err => err.response);
};

export const getNewChatParticipants=(clinicId,search,page,limit)=>{
  const params = {
    limit: limit || 10,
    skip: 0,
  }
  if (page && limit) {
    params.skip = (page - 1) * limit
  }
  if(search){
    params['search'] = search
  }
  return instance
    .get(`${NEW_CHAT_PARTICIPANTS}/${clinicId}/get-users`,{params})
    .then(res => res)
    .catch(err => err.response);
}

export const searchChatRooms = (clinicId,userId,search,page,limit) => {
  const params = {
    limit: limit || 10,
    skip: 0,
  }
  if (page && limit) {
    params.skip = (page - 1) * limit
  }
  if(search){
    params['search'] = search
  }
  return instance
    .get(`${CHAT_ROOM_SERVICE_URL}portal/${clinicId}/participant/${userId}`,{params})
    .then(res => res)
    .catch(err => err.response);
};

export const getEnrolledCareprograms = (clinicId,userId) => {
  const params = {
    userId
  }
  return instance
    .get(`${NEW_CHAT_PARTICIPANTS}/${clinicId}/get-users`,{params})
    .then(res => res)
    .catch(err => err.response);
};

export const getMentionUsers = (roomId,search,page,limit) => {
  const params = {
    limit: limit || 10,
    skip: 0,
  }
  if (page && limit) {
    params.skip = (page - 1) * limit
  }
  if(search){
    params['search'] = search
  }
  return instance
    .get(`${CHAT_ROOM_SERVICE_URL}${roomId}/chat-room-participants`,{params})
    .then(res => res)
    .catch(err => err.response);
};
