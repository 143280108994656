export const styles = (theme) => ({
    root: {
        backgroundColor: '#ffffff',
        width: 310,
        filter: "drop-shadow(4px 8px 14px rgba(0, 0, 0, 0.1))",
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        overflow: 'hidden',        
        marginRight: 20,
        transition: 'width 0.3s ease-in-out',
        "&.chatSizing": {
            width: 210
        }
    },
    chatHeader: {
        color: '#fff',
        backgroundColor: "#F3F7F9",                
        display: 'flex',
        alignItems: 'center',
        justifyContent: "space-between",
        cursor: 'pointer',
        lineHeight: '60px',         
    },
    chatHeadName: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: "calc(100% - 40px)",
        padding: '0 10px',
        cursor: 'pointer',
        display: 'flex',
        alignItems:'center'
    },
    AvatarIcons: {
        width: 32,
        height: 32,
        marginRight: 10
    },
    chatHeadClose: {
        color: "#000",
        marginRight: 10
    },
    ChatTitle: {
        fontSize: 16,
        color: '#223345',
        fontWeight: 600,
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    chatFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderTop: '1px solid #EEEEEE',
        padding: '8px',
        position:'relative'
    },
    SendMessageIcon: {
        fill: '#fff',
        width: 30,
        height: 30,
        backgroundColor: '#40c5ea',
        padding: '6px',
        borderRadius: '25px',
        transform: 'rotateZ(-33deg)'
    },
    chatActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',        
    },
    chatInput: {
        resize: "none",
        border:"none",
        outline:'none',
        boxShadow:'none',
        '& .MuiInputBase-root::after': {
            content: 'none',
        },
        '& .MuiInputBase-root::before': {
            content: 'none',
        },
        '& .MuiInputBase-root input': {
            backgroundColor: '#fff'
        }
    },
    chatContent: {
        "& .inner": {
            display: 'flex',
            padding: '5px',
            display: 'inline-flex',
            flexDirection: 'column',
            maxWidth: 'calc(100% - 20px)',     
            zIndex: '0',       
            "& .message": {
                "& .profile-name": {
                    fontSize: '11px',
                    margin: '0',
                    marginBottom: '3px'
                },
                '& .message-content': {
                    backgroundColor: "#EBEFF4",
                    borderBottomLeftRadius: '10px',
                    borderBottomRightRadius: '10px',
                    borderTopLeftRadius: '0',
                    borderTopRightRadius: '10px',
                    color: '#000',
                    fontSize: '13px',
                    padding: '10px',
                    display: 'inline-block',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',    
                    textAlign:'left',            
                },
                "& .message-content-download": {
                    display: 'inline-flex',
                    flexWrap: 'wrap',
                    alignItems:'center',
                    borderBottomLeftRadius: '10px',
                    borderBottomRightRadius: '10px',
                    borderTopLeftRadius: '0',
                    borderTopRightRadius: '10px', 
                    backgroundColor: "#EBEFF4",
                    padding: '10px',
                    "& > img":{
                        cursor: 'pointer',
                        // filter: 'invert(1)',
                        display: 'inline-flex',
                        alignItems: 'center'
                    },
                    "& > a": {
                        margin: '0 10px',
                        display: 'inline-flex',
                        alignItems: 'center'
                    },
                    "& > small": {
                        textAlign: 'left',
                        color: '#000'
                    }
                }                            
            },
            "& .audioPlayer":{
                background:"#EBEFF4",
                borderRadius:"0px 10px 10px 10px",
                "& div+img":{
                    width : "160px !important"
                },
                "& input":{
                    maxWidth: '270px',
                    minWidth: 'unset',
                    margin: '15px 16px 15px 15px'
                },
                "& .name_date_container":{
                    textAlign: 'left !important',
                    padding: '10px 10px 0px 10px !important',
                    margin:"0px !important"
                },
                "& div:nth-child(2)":{
                    maxHeight: '50px !important',
                    paddingBottom: '5px',
                }
            }   
        },
        '& .right': {
            justifyContent: 'flex-end',
            display: 'flex',
            "& .inner": {
                '& .message': {
                    textAlign: 'end',
                    '& .message-content': {
                        border:'1px solid #DADADA',
                        backgroundColor: '#34519C',
                        color: "#fff",
                        borderBottomRightRadius: '10px',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '0',
                        borderBottomLeftRadius: '10px',
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-word',    
                        textAlign:'left', 
                    },
                    "& .message-content-download": {
                        display: 'inline-flex',
                        flexWrap: 'wrap',
                        alignItems:'center',
                        borderBottomRightRadius: '10px',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '0',
                        borderBottomLeftRadius: '10px',   
                        backgroundColor: "#32529d",
                        padding: '10px',
                        "& > img":{
                            cursor: 'pointer',
                            filter: 'invert(1)',
                            display: 'inline-flex',
                            alignItems: 'center'
                        },
                        "& > a": {
                            margin: '0 10px',
                            display: 'inline-flex',
                            alignItems: 'center'
                        },
                        "& > small": {
                            textAlign: 'left',
                            color: '#fff'
                        }
                    }
                },
                "& .audioPlayer":{
                    background:"#32529d",
                    borderRadius:"10px 0px 10px 10px",
                    "& div+img":{
                        width : "160px !important"
                    },
                    "& span":{
                        textAlign:'center',
                        color:"#fff"
                    },
                    "& input":{
                        maxWidth: '270px',
                        minWidth: 'unset',
                        margin: '15px 16px 15px 15px'
                    },
                    "& .name_date_container":{
                        textAlign: 'left !important',
                        padding: '10px 10px 0px 10px !important'
                    },
                    "& div:nth-child(2)":{
                        maxHeight: '50px !important',
                        paddingBottom: '5px'
                    }
                }
            },
        },
        "& .name_date_container":{
            textAlign: 'left !important',
            marginBottom:"3px",
            width:"100%",
        }
    },
    dateContainer: {
        position: "relative",
        textAlign: "center",
        zIndex: "1",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        margin: '6px 0',
        "&::before": {
            content: "''",
            height: "1px",
            width: "calc( 100% - 32px)",
            background: "#e4e4e4",
            position: "absolute",
            top: "50%",
            left: "16px",
            zIndex: "-1",
            display: "block",
        },
        "& .date": {
            padding: "3px 10px",
            borderRadius: "13px",
            backgroundColor: "white",
            display: "block",
            color: "#3c4f69",
            width: "fit-content",
            fontSize: '11px'
        }
    },

    ReciverPrimary:{
        color:'#5E7D95',
        fontSize:13,
        position:"relative",
        marginRight:15,
        '&::before':{
            content: '"."',
            position: 'absolute',
            right:-10,
            top:3,
            fontSize: 22,
            lineHeight:0,
        }
    },
    ReciverSecondary:{
        color:'#5E7D95',
        fontSize:13,
    },

    SenderPrimary:{
        color:'#fff',
        fontSize:13,
        position:"relative",
        marginRight:15,
        '&::before':{
            content: '"."',
            position: 'absolute',
            right:-10,
            top:3,
            fontSize: 22,
            lineHeight:0,
        }
    },

    SenderSecondary:{
        color:'#fff',
        fontSize:13,
    },
    mentionList:{
        position: 'absolute',
        bottom: '65px',
        left: '15px',
        zIndex: 999,
        right: 'auto',
        background: 'white',
        minWidth: '240px',
        boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.10)',
        '& ul':{
            maxHeight:'150px',
            overflowY:'auto',
            cursor:'pointer',
            '&::-webkit-scrollbar': {
                width: 5
            }
        }
    }
});