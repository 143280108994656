import * as actionTypes from "./actionTypes";

export const UpdateInstInfo =(data)=>({
    payload:data,
    type:actionTypes.UPDATE_INST_INFO,
});

export const UpdateBookId=(data)=>({
    payload:data,
    type:actionTypes.UPDATE_BOOK_ID,
});

export const UpdateCallInfo=(data)=>({
    payload:data,
    type:actionTypes.UPDATE_CALL_INFO
});