import * as actionTypes from "./actionTypes";

export const setUserDetails = (data) => ({
  payload: data,
  type: actionTypes.USER_DETAILS,
});

export const updateUserMobile = (mobile) => ({
  type: actionTypes.UPDATE_USER_MOBILE,
  mobile,
});
