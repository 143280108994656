import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import FullScreenLoader from "./components/common/FullScreenLoader";
import { setAuthCompleted } from "./store/actions";
import { USER_ROLES } from "../src/utils/constants";


const Login = React.lazy(() => import("./pages/Login"));
const Register = React.lazy(() => import("./pages/Register"));
const UserOnboard = React.lazy(() => import("./pages/Register/User"));
const ClinicOnboard = React.lazy(() => import("./pages/Register/Clinic"));
const ClinicList = React.lazy(() => import("./pages/ClinicList"));
const ResetPassword = React.lazy(() => import("./pages/ResetPassword"));

const AdminLogin = React.lazy(() => import("./pages/NewAdmin/Login"));
const AdminOnboarding = React.lazy(() => import("./pages/NewAdmin/Onboarding"));
const AdminOtp = React.lazy(() => import("./pages/NewAdmin/Otp"));
const AdminSignUp = React.lazy(() => import("./pages/NewAdmin/SignUp"));
const AdminSlider = React.lazy(() => import("./pages/NewAdmin/Slider"));
const CreatePassword = React.lazy(() => import("./pages/NewDoctor/CreatePassword"));
const GetStarted = React.lazy(() => import("./pages/NewDoctor/GetStarted"));
const CreateFacility=React.lazy(() => import("./pages/NewAdmin/CreateFacility"));
const Receptionist=React.lazy(() => import("./pages/Receptionist/Dashboard"));
const OperationalReceptionist = React.lazy(() => import("./pages/OperationalReceptionist/Dashboard"));

const Home = React.lazy(() => import("./pages/Home"));
const Doctor = React.lazy(() => import("./pages/Doctor"));
const PatientProfile = React.lazy(() =>
  import("./pages/Patients/PatientProfile")
);
const BookNewTest = React.lazy(() =>
  import("./pages/Patients/PatientProfile/LabTest/BookNewTest/index")
);
const CreateDoctor = React.lazy(() => import("./pages/Doctor/CreateDoctor"));

const Admin = React.lazy(() => import("./pages/Admin"));
const CreateAdmin = React.lazy(() => import("./pages/Admin/CreateAdmin"));
const Logout = React.lazy(() => import("./pages/Logout"));
const Appointments = React.lazy(() => import("./pages/Appointments"));
const CreateAppointment = React.lazy(() =>
  import("./pages/Appointments/CreateAppointment")
);
const CreateTest = React.lazy(() => import("./pages/LabTests/CreateTest"));
const ScheduleAppointment = React.lazy(() =>
  import("./pages/Appointments/scheduleAppointment")
);
const Payment = React.lazy(() => import("./pages/Payment"));
const AHPList = React.lazy(() => import("./pages/AHP/AHPList"));
const CreateAHP = React.lazy(() => import("./pages/AHP/CreateAHP"));
const PatientList = React.lazy(() => import("./pages/Patients/PatientList"));
const Account = React.lazy(() => import("./pages/Account"));
const CreatePatient = React.lazy(() =>
  import("./pages/Patients/CreatePatient")
);
const CreateCareProgram = React.lazy(() =>
  import("./pages/CareProgram/CreateProgram")
);
const CareProgramList = React.lazy(() =>
  import("./pages/CareProgram/CareProgramList")
);

const EnrollmentRequest = React.lazy(() =>
  import("./pages/CareProgram/EnrollmentRequset")
);

const Demo = React.lazy(() => import("./pages/Demo"));

const QuestionCatelogue = React.lazy(() => import("./pages/QuestionCatelogue"));
const QuestionCatelogueAdd = React.lazy(() =>
  import("./pages/QuestionCatelogue/Add")
);
const ScreeningQuestionAdd = React.lazy(() =>
  import("./pages/QuestionCatelogue/AddScreeningQuestion")
);
const ScreeningQuestionList = React.lazy(() =>
  import("./pages/QuestionCatelogue/ListScreeningQuestion")
);
const SortQuestions = React.lazy(() =>
  import("./pages/QuestionCatelogue/SortQuestions")
);
const AddTag = React.lazy(() => import("./pages/QuestionCatelogue/AddTag"));
const EditQuestion = React.lazy(() =>
  import("./pages/QuestionCatelogue/indexEdit")
);
// const ScreeningQuestionSearch = React.lazy(() => import("./pages/QuestionCatelogue/SearchScreeningQuestion"));

const CareProgramPayment = React.lazy(() =>
  import("./pages/Payment/CareProgramPayment")
);
const CareProgram = React.lazy(() => import("./pages/Payment/CareProgram"));
const AdHocPayment = React.lazy(() => import("./pages/Payment/AdHocPayment"));
const AdHocDetails = React.lazy(() => import("./pages/Payment/AdHocDetails"));
const CreateAdHocPayment = React.lazy(() =>
  import("./pages/Payment/CreateAdHoc")
);
const TeleConsultation = React.lazy(() =>
  import("./pages/Payment/TeleConsultation")
);
const TeleConsultationPayment = React.lazy(() =>
  import("./pages/Payment/TeleConsultationPayment")
);

const CareProgramGroups = React.lazy(() =>
  import("./pages/CareProgram/Groups/GroupList")
);
const CareProgramGroupsEdit = React.lazy(() =>
  import("./pages/CareProgram/Groups/GroupListEdit")
);
const CareProgramGroupsAddmembers = React.lazy(() =>
  import("./pages/CareProgram/Groups/AddMembers")
);

const Chats = React.lazy(() => import("./pages/Chats"));

const LabPartner = React.lazy(() => import("./pages/LabPartner"));
const LabPartnerAdd = React.lazy(() =>
  import("./pages/LabPartner/LabPartnerAdd")
);
const LabPartnerDetail = React.lazy(() =>
  import("./pages/LabPartner/LabPartnerDetail")
);
const LabTestAdd = React.lazy(() =>
  import("./pages/Patients/PatientProfile/LabTest/BookNewTest/index")
);

// health profiler
// const AppointmentsManagement = React.lazy(() => import("./pages/AppointmentManagement/Appointment"));
// const FollowupRequests = React.lazy(() => import("./pages/AppointmentManagement/FollowupRequest"));
// const Consultation = React.lazy(() => import("./pages/AppointmentManagement/Consultation"));
const TagsList = React.lazy(() => import("./pages/Tags/Tagslist/index.tsx"))
const DataParmList = React.lazy(() => import("./pages/HealthProfiler/DataParameter"));
const HealthProfilerConsole = React.lazy(() => import("./pages/HealthProfiler/index"));
const HealthProfilerQuestions = React.lazy(() => import("./pages/HealthProfiler/Questions/QuestionLibrary"));
const HealthProfilerQuestionSet = React.lazy(() => import("./pages/HealthProfiler/Questions/QuestionSetLibrary"));
const HealthProfilerTemplate = React.lazy(() => import("./pages/HealthProfiler/Template"));
const QuestionForm = React.lazy(() => import("./pages/HealthProfiler/Questions/QuestionForm"));
const QuestionSetForm = React.lazy(() => import("./pages/HealthProfiler/Questions/QuestionSetForm"));
const TemplateForm = React.lazy(() => import("./pages/HealthProfiler/Template/TemplateForm"));
const HealthProfilerAssociations = React.lazy(() => import("./pages/HealthProfiler/Associations"));
const Prescription = React.lazy(() => import("./pages/prescription"));
const LabTranscriber = React.lazy(() => import("./pages/LabTest"));
const FacilityAdmin = React.lazy(() => import("./pages/ManageUser/Admin"));
const FacilityDoctor = React.lazy(() => import("./pages/ManageUser/Doctor"));
const FacilityAhp = React.lazy(() => import("./pages/ManageUser/AHP"));
const FacilityOthers = React.lazy(() => import("./pages/ManageUser/Others"));
const AdminDetail=React.lazy(() => import("./pages/ManageUser/Admin/AdminDetail"));
const AhpDetail=React.lazy(()=> import("./pages/ManageUser/AHP/AhpDetails"))
const OthersDetail=React.lazy(()=> import("./pages/ManageUser/Others/OthersDetail"))
const Empanelmentfacility=React.lazy(()=> import ("./pages/Empanelment/facilityPending"));
const EmpanelmentDoctor =React.lazy(()=> import ("./pages/Empanelment/DoctorPending"));
// Empanelment
const FacilityDetail=React.lazy(() => import("./pages/Empanelment/FacilityDetail"))
const DoctorDetail=React.lazy(() => import("./pages/Empanelment/DoctorDetail"))
const AppointmentsManagement = React.lazy(() => import("./pages/AppointmentManagement/Appointment"));
const FollowupRequests = React.lazy(() => import("./pages/AppointmentManagement/FollowupRequest"));
const Consultation = React.lazy(() => import("./pages/AppointmentManagement/Consultation"));
const FacilityPatients = React.lazy(() => import("./pages/ManageUser/Patients"));
const FacilityPatientDetail = React.lazy(() => import("./pages/ManageUser/Patients/PatientDetails"));


// Availability management
const Scheduler = React.lazy(() => import("./pages/Scheduler"));

// Empanelment
const EmpanelDashboard = React.lazy(() => import("./pages/Empanelment/Dashboard"))
const GuestUserList = React.lazy(() => import("./pages/GuestProfile/index"));
const AdminFacilityList = React.lazy(() => import("./pages/NewAdmin/FacilityList"));
const AdminFacilityDetail = React.lazy(() => import("./pages/NewAdmin/FacilityDetail"));
const AdminAddDoctor = React.lazy(() => import("./pages/NewAdmin/CreateDoctor"));
const AdminDoctors = React.lazy(() => import("./pages/NewAdmin/Doctors"));
const AdminResetPassword = React.lazy(() => import("./pages/NewAdmin/ResetPassword"));

//Profiles - Doctor, Admin, Wellness Coach and Facility.
const MyDoctorProfile = React.lazy(()=> import("./pages/NewProfiles/DoctorProfile/myProfile"));
const MyAdminProfile = React.lazy(()=> import("./pages/NewProfiles/AdminProfile/myProfile"));
const ManageUserDoctor = React.lazy(()=> import("./pages/NewProfiles/DoctorProfile/manageUserDoctor"));
const ManageUserAdmin = React.lazy(()=> import("./pages/NewProfiles/AdminProfile/manageUserAdmin"));
const ManageUserReceptionist = React.lazy(()=> import("./pages/NewProfiles/ReceptionistProfile/manageUserReceptionist"));
const ReceptionistProfile = React.lazy(()=> import("./pages/NewProfiles/ReceptionistProfile/myProfile"));
const ManageUserWellness = React.lazy(()=> import("./pages/NewProfiles/WellnessProfile/manageUserAhp"));
const myWellnessProfile = React.lazy(()=> import("./pages/NewProfiles/WellnessProfile/myProfile"));
const AdminProfile = React.lazy(()=> import("./pages/NewProfiles/AdminProfile"));
const WellnessProfile = React.lazy(()=> import("./pages/NewProfiles/WellnessProfile"));
const FacilitySettings = React.lazy(()=> import("./pages/NewProfiles/FacilitySettings"));
const Dashboard = React.lazy(()=> import("./pages/Dashboard"));

const MyOperationalReceptionistProfile = React.lazy(()=> import("./pages/NewProfiles/OperationalReceptionistProfile"));
//claims
const ClaimsDashBoard = React.lazy(()=> import("./pages/Claims/ClaimsDashboard"));

const ClaimsConsultation=React.lazy(()=> import("./pages/Claims/Consultation"));
const EpharmacyList=React.lazy(()=> import("./pages/Claims/Epharmacy"));
const DiagnosticsList=React.lazy(()=> import("./pages/Claims/Diagnostics"));

//consultation
const VideoConsultation=React.lazy(()=> import("./pages/consultation"));

//Claims- EPharmacy, Consultation and Diagnostics 
// const ClaimEPharmacy = React.lazy(() => import("./pages/Claims/EpharmacyPending"));
// const ClaimConsultation = React.lazy(() => import("./pages/Claims/ConsultationPending"));






// const GuestUserList = React.lazy(() => import("./pages/GuestProfile/index"))

export default function Routes({ isAuthenticated }) {
  const { userRole, isLoggedUser } = useSelector((state) => state.auth);
  const { details } = useSelector((state) => state?.clinic);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated && location?.pathname == "/clinic/list") {
      dispatch(setAuthCompleted(true));
    }
  }, [isAuthenticated, location.pathname]);

  const restrictedRoutes = [
    {
      path: "/home",
      component: Home,
      roles: ["All"],
    },
    {
      path: "/prescription",
      component: Prescription,
      roles: ["All"],
    },
    {
      path: "/labTranscriber",
      component: LabTranscriber,
      roles: [USER_ROLES.transcription_agent],
    },
    {
      path: "/clinic/doctors",
      component: Doctor,
      exact: true,
      roles: ["All"],
    },
    {
      path: "/clinic/doctors/create/:doctorId?",
      component: CreateDoctor,
      exact: true,
      roles: ["administrators"],
    },
    {
      path: "/clinic/admin",
      component: Admin,
      exact: true,
      roles: ["All"],
    },
    {
      path: "/clinic/admin/create/:adminId?",
      component: CreateAdmin,
      exact: true,
      roles: ["administrators"],
    },
    {
      path: "/clinic/ahp",
      component: AHPList,
      exact: true,
      roles: ["All"],
    },
    {
      path: "/clinic/ahp/create/:ahpId?",
      component: CreateAHP,
      exact: true,
      roles: ["administrators", "doctors"],
    },
    {
      path: "/clinic/patients",
      component: PatientList,
      exact: true,
      roles: ["All"],
    },
    // {
    //   path: "/clinic/guest-users",
    //   component: GuestUserList,
    //   exact: true,
    //   roles: ["All"],
    // },
    {
      path: "/clinic/patients/create/:user_id?",
      component: CreatePatient,
      exact: true,
      roles: ["administrators", "doctors"],
    },
    {
      path: "/clinic/patients/:id",
      component: PatientProfile,
      exact: true,
      roles: ["All"],
    },
    {
      path: "/clinic/patients/:id/book-new-test/:cid/:groupId/:schedularId/:packageCode",
      component: BookNewTest,
      exact: true,
      roles: ["All"],
    },
    {
      path: "/tags",
      component: TagsList,
      exact: true,
      roles: ["All"],
    },
    {
      path: "/health-profiler/data-master",
      component: DataParmList,
      exact: true,
      roles: ["All"],
    },
    // {
    //   path: "/tag/:type/:id?",
    //   component: CreateViewEditTags,
    //   exact: true,
    //   roles: ["All"],
    // },
    {
      path: "/care-program/wellness-program",
      component: CareProgramList,
      exact: true,
      roles: ["administrators"],
    },
    {
      path: "/care-program/groups/:id?",
      component: CareProgramGroups,
      exact: true,
      roles: ["All"],
    },
    {
      path: "/care-program/groups/edit-group/:groupId/:careProgramId",
      component: CareProgramGroupsEdit,
      exact: true,
      roles: ["All"],
    },
    {
      path: "/care-program/groups/add-members/:groupId/:careProgramId",
      component: CareProgramGroupsAddmembers,
      exact: true,
      roles: ["All"],
    },
    {
      path: "/care-program/wellness-program/create/:id?",
      component: CreateCareProgram,
      exact: true,
      roles: ["administrators"],
    },
    {
      path: "/care-program/enrollment-request",
      component: EnrollmentRequest,
      exact: true,
      roles: ["All"],
    },
    // {
    //   path: "/appointments",
    //   component: Appointments,
    //   exact: true,
    //   roles: ["All"],
    // },
    {
      path: "/lab-tests/create-test/:patientId?",
      component: CreateTest,
      exact: true,
      roles: ["All"],
    },
    // {
    //   path: "/appointments/create/:patientId?",
    //   component: CreateAppointment,
    //   exact: true,
    //   roles: ["All"],
    // },
    {
      path: "/appointments/schedule/:id",
      component: ScheduleAppointment,
      exact: true,
      roles: [USER_ROLES.facility_admin,USER_ROLES.super_admin,USER_ROLES.doctor,USER_ROLES.receptionist],
    },
    {
      path: "/payment/adhoc-payments",
      component: AdHocPayment,
      exact: true,
      roles: ["All"],
    },
    {
      path: "/payment/create/:patientId?",
      component: CreateAdHocPayment,
      exact: true,
      roles: ["All"],
    },
    {
      path: "/payment/adhoc-payments/:id?",
      component: AdHocDetails,
      exact: true,
      roles: ["All"],
    },
    {
      path: "/payment/care-plan-payment",
      component: CareProgramPayment,
      exact: true,
      roles: ["All"],
    },
    {
      path: "/payment/care-plan-payment/:id?", //care-plan-payment
      component: CareProgram,
      exact: true,
      roles: ["All"],
    },
    {
      path: "/payment/tele-consultation-payment",
      component: TeleConsultationPayment,
      exact: true,
      roles: ["All"],
    },
    {
      path: "/payment/tele-consultation-payment/:id?",
      component: TeleConsultation,
      exact: true,
      roles: ["All"],
    },
    {
      path: "/my-account",
      component: Account,
      roles: ["All"],
    },
    {
      path: "/my-account",
      component: Account,
      roles: ["All"],
    },
    {
      path: "/chats",
      component: Chats,
      roles: ["All"],
    },
    {
      path: "/LabPartner",
      component: LabPartner,
      roles: ["All"],
    },
    {
      path: "/LabPartner/Add",
      component: LabPartnerAdd,
      roles: ["All"],
    },
    {
      path: "/LabPartner/detail/:labId?",
      component: LabPartnerDetail,
      roles: ["All"],
    },
    {
      path: "/health-profiler",
      component: HealthProfilerConsole,
      roles: ["All"],
    },
    {
      path: "/health-profiler/questions",
      component: HealthProfilerQuestions,
      roles: ["All"],
    },
    {
      path: "/health-profiler/question-set",
      component: HealthProfilerQuestionSet,
      roles: ["All"],
    },
    {
      path: "/health-profiler/template",
      component: HealthProfilerTemplate,
      roles: ["All"],
    },
    {
      path: "/health-profiler/create-question/:questionId?",
      component: QuestionForm,
      roles: ["All"],
    },
    {
      path: "/health-profiler/hp-question-set/view-template/:questionSetId",
      component: TemplateForm,
      roles: ["All"],
    },
    {
      path: "/health-profiler/template/view-template/:questionSetId",
      component: TemplateForm,
      roles: ["All"],
    },
    {
      path: "/health-profiler/create-question-set/:questionSetId?",
      component: QuestionSetForm,
      roles: ["All"],
    },
    {
      path: "/health-profiler/associations",
      component: HealthProfilerAssociations,
      roles: ["All"],
    },
    {
      path: "/ManageUsers/Admin",
      component: FacilityAdmin,
      exact: true,
      roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin],
    },
    {
      path: "/ManageUsers/Doctor",
      component: FacilityDoctor,
      exact: true,
      roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin],
    }, 
    {
      path: "/ManageUsers/AHP",
      component: FacilityAhp,
      exact: true,
      roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin],
    }, 
    {
      path: "/ManageUsers/Others",
      component: FacilityOthers,
      exact: true,
      roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin],
    },
    {
      path: "/ManageUsers/Patients",
      component: FacilityPatients,
      exact: true,
      roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin],
    },
    {
      path: "/ManageUsers/Patients/Detail",
      component: FacilityPatientDetail,
      exact: true,
      roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin],
    },
    {
      path: "/ManageUsers/AdminDetail/:id?",
      component: AdminDetail,
      exact: true,
      roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin],
    },  
    {
      path: "/ManageUsers/AhpDetail/:id?",
      component: AhpDetail,
      exact: true,
      roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin],
    }, 
    {
      path: "/ManageUsers/OthersDetail/:id?",
      component: OthersDetail,
      exact: true,
      roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin],
    }, 
    {
      path: "/Empanelment/facilityEmpanelment",
      component: Empanelmentfacility,
      roles: [USER_ROLES.empanelment_admin],
    },
    {
      path: "/Empanelment/DoctorEmpanelment",
      component: EmpanelmentDoctor,
      roles: [USER_ROLES.empanelment_admin],
    },
    {
      path:"/Empanelment/facilityEmpanelment/FacilityDetail",
      component:FacilityDetail,
      roles:[USER_ROLES.empanelment_admin],
    },
    {
      path:"/Empanelment/DoctorEmpanelment/DoctorDetail",
      component:DoctorDetail,
      roles:[USER_ROLES.empanelment_admin],
    },
    {
      path: "/logout",
      component: Logout,
      roles: ["All"],
    },
    { path: "/demo", component: Demo, roles: ["All"] },
    {
      path: "/question/list",
      component: QuestionCatelogue,
      roles: ["All"],
      exact: true,
    },
    {
      path: "/question/list/create",
      component: QuestionCatelogueAdd,
      roles: ["All"],
      exact: true,
    },
    { path: "/question/update/:id", component: EditQuestion, roles: ["All"] },
    {
      path: "/question/tagging",
      component: ScreeningQuestionList,
      roles: ["All"],
    },
    {
      path: "/question/tagging/sort/:type/:id?",
      component: SortQuestions,
      roles: ["All"],
    },
    // { path: "/question/tagging/select-question", component: ScreeningQuestionSearch, roles: ["All"] },
    {
      path: "/question/tagging/create",
      component: ScreeningQuestionAdd,
      roles: ["All"],
    },
    { path: "/question/tagging/create-tag", component: AddTag, roles: ["All"] },
    {path: "/clinic/list", component: ClinicList, roles: ["All"] },
    {
      path: "/Appointments",
      component: AppointmentsManagement,
      roles: [USER_ROLES.facility_admin,USER_ROLES.doctor,USER_ROLES.receptionist,USER_ROLES.opd_ops,USER_ROLES.facility_receptionist],
    },
    {
      path: "/Appointments/consultation",
      component: Consultation,
      roles: ["All"],
    },
    {
      path: "/Followup_requests",
      component: FollowupRequests,
      roles: ["All"],
    },
   // { path: "/Empanelment/dashboard", component: EmpanelDashboard, roles: ["All"] },

    { path: "/OperationalReceptionist/dashboard", component: OperationalReceptionist, roles: [USER_ROLES.opd_ops] },
    { path: "/Receptionist/dashboard", component: Receptionist, roles: [USER_ROLES.receptionist,USER_ROLES.facility_receptionist] },
    { path: "/Empanelment/dashboard", component: EmpanelDashboard, roles: [USER_ROLES.empanelment_admin] },
    { path: "/createfacility", component: CreateFacility, roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin,USER_ROLES.doctor,USER_ROLES.field_agent] },
    { path: "/admin/login", component: AdminLogin, roles: ["All"] },

    { path: "/admin/facility-list", component: AdminFacilityList, exact : true,roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin,USER_ROLES.doctor,USER_ROLES.field_agent,USER_ROLES.telemedicine_admin,USER_ROLES.operations_admin,USER_ROLES.receptionist, USER_ROLES.facility_receptionist] },

    { path: "/admin/facility-detail/:facilityid?", component: AdminFacilityDetail, roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin] },

    { path: "/admin/add-doctor/:facilityid", component: AdminAddDoctor, roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin,USER_ROLES.doctor,USER_ROLES.field_agent]},

    { path: "/admin/doctors-list", component: AdminDoctors, roles: ["All"] },

    { path: "/admin/reset-password", component: AdminResetPassword, roles: ["All"] },
    { path: "/claims/dashboard", component: ClaimsDashBoard, roles: [USER_ROLES.claims_admin,USER_ROLES.claims_processor] },

    { path: "/clinic/list", component: ClinicList, roles: ["All"] },
    // { path: "/clinic/onboard", component: ClinicOnboard, roles: ["All"] },

    {

      path: "/appointment-scheduler",

      component: Scheduler,

      roles: [USER_ROLES.facility_admin,USER_ROLES.doctor,USER_ROLES.doctor,USER_ROLES.receptionist,USER_ROLES.super_admin,USER_ROLES.opd_ops, USER_ROLES.facility_receptionist],

    },

    // {path: "/clinic/list", component: ClinicList, roles: ["All"] },
    // { path: "/clinic/onboard", component: ClinicOnboard, roles: ["All"] }
    { path: "/Dashboard", component: Dashboard,exact : true, roles: ["All"] },
    { path: "/Empanelment/index", component: EmpanelDashboard, roles: ["All"] },
    { path: "/newprofile/doctor", component: MyDoctorProfile, roles: [USER_ROLES.doctor] },
    { path: "/newprofile/operational-recepptionist", component: MyOperationalReceptionistProfile, roles: [USER_ROLES.opd_ops] },
    { path: "/newprofile/admin", component: MyAdminProfile, roles: [USER_ROLES.facility_admin] },
    { path: "/newprofile/receptionist", component: ReceptionistProfile, roles: [USER_ROLES.receptionist, USER_ROLES.facility_receptionist] },
    { path: "/ManageUsers/Doctor/profile:doctorId?", component: ManageUserDoctor, roles: [USER_ROLES.facility_admin] },
    { path: "/ManageUsers/Admin/profile:adminId?", component: ManageUserAdmin, roles: [USER_ROLES.facility_admin] },
    { path: "/ManageUsers/Others/profile:receptionistId?", component: ManageUserReceptionist, roles: [USER_ROLES.facility_admin] },
    { path: "/ManageUsers/AHP/profile:wellnessId?", component: ManageUserWellness, roles: [USER_ROLES.facility_admin] },
    { path: "/newprofile/wellness", component:myWellnessProfile, roles: ["All"] },
    { path: "/newprofile/admin", component: AdminProfile, roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin,USER_ROLES.field_agent]},
    { path: "/newprofile/wellness", component: WellnessProfile, roles: ["All"] },
    { path: `/newprofile/facility`, component: FacilitySettings, roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin] },
    { path: "/Admin/Slider", component: AdminSlider, roles: [USER_ROLES.super_admin,USER_ROLES.facility_admin] },
    {path: "/Empanelment/facilityEmpanelment/createfacility",component:CreateFacility,roles:[USER_ROLES.empanelment_admin]},
    {path: "/Empanelment/DoctorEmpanelment/:facilityid",component:AdminAddDoctor,roles:[USER_ROLES.empanelment_admin]},
    { path: "/claims/consultation", component: ClaimsConsultation, roles: [USER_ROLES.claims_admin,USER_ROLES.claims_processor] },
    { path: "/claims/epharmacylist", component: EpharmacyList, roles: [USER_ROLES.claims_admin,USER_ROLES.claims_processor] },
    { path: "/claims/diagnostics", component: DiagnosticsList, roles: [USER_ROLES.claims_admin,USER_ROLES.claims_processor] },
    { path: "/Appointments/room:urlname?", component: VideoConsultation, roles: ["All"] },
    { path: "/InstantCall/room:urlname?", component: VideoConsultation, roles: ["All"] }


  ];
  
  
  const clinicListRoute = [
     {path: "/clinic/list", component: ClinicList, roles: ["All"] },
  ]

  let routes = (
    <Switch>
      <Route path="/login" component={Login} />
      {/* <Route path='/Dashboard' exact = {true} component={Dashboard} /> */}
    </Switch>
  );
  if (isLoggedUser) {
    routes = (
      <Switch>
        {restrictedRoutes
          .filter((route) => {
            if(userRole === USER_ROLES.super_admin || userRole === USER_ROLES.facility_admin || userRole === USER_ROLES.doctor || userRole === USER_ROLES.field_agent || userRole === USER_ROLES.telemedicine_admin || userRole === USER_ROLES.operations_admin)
            {
              if(JSON.stringify(details) != '{}' || location.pathname.includes('/createfacility') || location.pathname.includes('/admin/facility-list') || location.pathname.includes('/logout'))
              {
                if (route.roles?.includes("All")) return true;
                if (route.roles.includes(userRole)) return true;
              }
            }
            else
            {
              if (route.roles?.includes("All")) return true;
              if (route.roles.includes(userRole)) return true;
            }
          })
          .map((route) => {
            return (
              <Route path={route.path} component={route.component} exact />
            );
          })}
           {(userRole === USER_ROLES.super_admin || userRole === USER_ROLES.facility_admin  || userRole === USER_ROLES.doctor || userRole === USER_ROLES.field_agent || userRole === USER_ROLES.telemedicine_admin || userRole === USER_ROLES.operations_admin|| userRole === USER_ROLES.receptionist) ?   JSON.stringify(details) == '{}' ? <Redirect from="/" to={isAuthenticated ? "/admin/facility-list" : ""} /> : <Redirect from="/" to={isAuthenticated ? "/Dashboard" : ""} />  : <Redirect from="/" to={isAuthenticated ? "/Dashboard" : ""} />} 
      </Switch>
    );
  }

  return <Suspense fallback={<FullScreenLoader />}>{routes}</Suspense>;
}