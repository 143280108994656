import {useState, useEffect } from "react";
import useAuthService from "./hooks/useAuthService";
import { useDispatch, useSelector } from "react-redux";
import { QueryCache, ReactQueryCacheProvider } from "react-query";
import Routes from "./routes";
import Layout from "./hoc/Layout";
import { authStorage } from "./utils/login";
import Notify from "./components/common/CommonToast";
import { setLogoutSucceed } from "./store/actions";

export default function App() {
  const {login} = useAuthService()
  const queryCache = new QueryCache({
    defaultConfig: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  const dispatch = useDispatch()
  const [request, setRequest] = useState({
    message: "",
    loading: false,
    severity: "",
  });
  const isAuthenticated = useSelector(state =>
    authStorage.authToken && state.auth.user && !state.auth.inProgress ? true : false
  );

  const searchParams = new URLSearchParams(window.location.search);
  const isSignedInSSO = searchParams.has("code")

  useEffect(()=>{
    if(!authStorage.authToken && !isSignedInSSO)
    {
      dispatch(setLogoutSucceed());
      login()
    }
  },[])

  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      {request.message &&  (
        <Notify message={request.message} severity={request.severity} />
      )}
      <Layout isAuthenticated={isAuthenticated}>
        <Routes isAuthenticated={isAuthenticated} />
      </Layout>
    </ReactQueryCacheProvider>
  );
}
