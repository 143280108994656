
const signalR = require("@microsoft/signalr");

let connection: any = "";

export const initChatSocket = (id: string, name: string) => {
  connection = new signalR.HubConnectionBuilder().withUrl(`https://devapis.securrahealth.com/chat/chathub?userid=${id}&role=DOCTOR&name=${name}`).build();
  connection.start();
  socketListeners();
}

const socketListeners = () => {
  if (connection.state === 'Connected') {
    connection.send('Check');
    connection.on("check", function (res) {
      if (res) {
        connection.send('CreateConsultation', { practionerId: "63aad62c8170f840e82f1352", patientId: "639b657ff0307456da3b6ac5" });
      } else {
        setTimeout(() => {
          connection.send('Check')
        }, 2000)
      }
    })
    connection.on("createConsultation", function (createConsultationResponse) {
      console.log("createConsultationResponse");
      console.log(createConsultationResponse);
    })
    connection.on("accept", function (AcceptRsponse) {
      console.log("AcceptRsponse");
      console.log(AcceptRsponse);
      // joinRoom()
    });
    connection.on("reject", function (rejectRsponse) {
      console.log("rejectRsponse");
      console.log(rejectRsponse);
    });
  } else if (connection.state === "Connecting") {
    setTimeout(() => {
      socketListeners()
    }, 500)
  }
}

export const acceptChat = (room) => {
  connection.send("Accept", room)
}

export const rejectChat = (room) => {
  connection.send("Reject", { practionerId: "63aad62c8170f840e82f1352", channelId: "63d897d0467718a4203503f5", patientId: "639b0f2270c7ef286645ecb6" });
}

// const sendMsg = (message) => connection.send('SendMessage', {content: message,fromUserId:"63aad62c8170f840e82f1352", toRoomId: "", contentType: ""})


// const joinRoom = (room) => connection.send('Join', room)
//   .then((history) => {
//     console.log(room);
//     console.log('message history', history)
//     connection.on('send_message', m => { console.log(m) })
//   });