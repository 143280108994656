import {
  CircularProgress, Grid,
  IconButton,
  makeStyles,
  TextField,
  Collapse,
  TextareaAutosize,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from "@material-ui/core";
import SendIcon from '@material-ui/icons/Send';
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CloseIcon from '@material-ui/icons/Close';
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector, useDispatch } from "react-redux";
import DocumentViewer from "../../../components/common/DocumentViewer";
import { getIcon, downloadFile } from "../../../utils";
import FileUploadButton from "./FileUploadButton";
import { styles } from "./styles";
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import groupAvtar from '../../../assets/images/group-avtar.svg'
import participantAvtar from '../../../assets/images/participant-avtar.svg'
import DownloadIcon from '../../../assets/images/icons/download-circular.png';
import { recieve, registerOnMessageCallback, close, send, startWebsocketConnection } from '../../../utils/wss';
import { setOtherInfo, setOpenChatDetails, setOpenChatList, setOpenChatRooms } from "../../../store/actions";
import Audio from "../../../components/common/CustomAudio/Audio"
import CustomVideoRenderer from "../../common/DocumentViewer/CustomVideoRenderer";
import { getMentionUsers } from "../../../api/chat";
import clsx from "clsx";

const useStyles = makeStyles(styles);
const PageSize = 10;

const ChatWindow = ({
  chatData,
  patient,
  openedChatWindowIndex
}) => {
  const {
    chatRoomList = [],
    openChatList = [],
    openChatDetails = {},
    openChatRooms = [],
    loadingHistory,
    hasMore,
    fetchingNewMessage
  } = useSelector((state) => state?.chat);

  const classes = useStyles();
  const { name } = useSelector(state => state.user);
  const { user: chatUserObj } = useSelector(state => state.auth);
  const [displayRole, setDisplayRole] = useState("");
  const [tagText, setTagText] = useState(null)
  const [mentionList,setMentionList] = useState([])
  const [isSearching, setIsSearching] = useState(false)
  const [tablePage, setTablePage] = useState(1)
  const [tagList, setTagList] = useState([])
  const rowsPerPage = 5
  const [mentionedUsers, setMentionedUsers] = useState([])
  const [showDoc, setShowDoc] = useState(null);
  const [textmessage, setTextMessage] = useState("");
  const [request, setRequest] = useState({
    loading: false,
    message: "",
    severity: ""
  });
  const [imageUrl, setImageUrl] = useState("");
  const [isChatOpen, setIsChatOpen] = useState(true);
  const [isNewMsg, setIsNewMsg] = useState(false);
  const dispatch = useDispatch();
  const [downloadLoading, setDownloadLoading] = useState(null)
  const [isPlaying, setIsPlaying] = useState(null)


  const textToMentions = (textmessage) =>{
    let msg = textmessage
    mentionedUsers.map((ele)=>{
        if(textmessage.includes(`@${ele.participant.displayName}`)){
            msg = msg.replaceAll(`@${ele.participant.displayName}`, `<a style='color:#59bad1'>@${ele.participant.displayName}</a>`);
        }
    })
    return msg
}

  const handleSubmit = (e) => {
    e.preventDefault();
    let submitTextMessage = textmessage.trim();
    let formData;
    if (imageUrl) {
      formData = {
        action: patient.action,
        body: {
          type: "media",
          payload: { media: [imageUrl], message: imageUrl },
        }
      };
    } else if (submitTextMessage.length>0) {
      formData = {
        action: patient.action,
        body: {
          type: "text",
          payload: { message: submitTextMessage.includes("@") ? textToMentions(submitTextMessage) : submitTextMessage },
        }
      };
    }
    if(formData){
      if (patient?.isGroup) {
        formData['body']['room'] = patient.room
      } else {
        formData['body']['to'] = patient.chatUserId
      }
      send(JSON.stringify(formData));
      setTextMessage("");
      setImageUrl("");
    }
  };

  const handleKeyDown = e => {
    if (e.key === "Enter" && !e.shiftKey) {
      handleSubmit(e);
    }
  };

  const removeAttachement = () => {
    if (imageUrl) setImageUrl("");
  };

  const fetchMoreMesssages = () => {
    dispatch(setOtherInfo({
      loadingHistory: true,
      hasMore,
      fetchingNewMessage: false
    }))
    if (Object.keys(patient).length > 0 && openChatDetails[patient.room]) {
      setTimeout(() => {
        recieve(patient.room, PageSize, openChatDetails[patient.room].length);
      }, 200);
    }
  };

  const removeChatWindow = (chatroom) => {
    openChatList.splice(openChatList.indexOf(chatroom), 1)
    dispatch(setOpenChatList([...openChatList]))
    delete openChatDetails[chatroom.room];
    dispatch(setOpenChatDetails({ ...openChatDetails }));
    openChatRooms.splice(openChatRooms.indexOf(chatroom.room), 1)
    dispatch(setOpenChatRooms([...openChatRooms]));
  }

  const openCloseChat = () => {
    !isChatOpen && setIsNewMsg(false);
    setIsChatOpen(!isChatOpen);
  }

  const getFileName = (fileURL) => {
    if(fileURL){
      let fileArray = fileURL.split("?")[0].split("/")
      let filename = fileArray[fileArray.length - 1]
      if(filename.length>10){
        return filename.slice(0,10)+'...'+filename.split(".")[1]
      }else{
        return fileArray[fileArray.length - 1]
      }
    }
}

const getTextMessageWithURL=(message, index)=>{
  if(!message) return;

  var checkMention = textToMentions(message)      
  var mentionMsg = checkMention.includes(`@`)

  var msgWithChar60or62 = message.replace(/<|>/g, (char) => {
    var char60or62 = char
    if (char60or62 === '<') {
        return '<span>&lt;</span>'
    } else if (char60or62 === '>') {
        return '<span>&gt;</span>'
    }
  })
  var urlRegex = /[(http(s)?):\/\/((www\.)|(WWW\.))?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/=]*)/ig;
  var msg =  msgWithChar60or62.replace(urlRegex, function (url) {
    var hyperlink = url;
    if (!hyperlink.match('^https?:\/\/')) {
      hyperlink = 'http://' + hyperlink;
    }
    
    return '<a href="' + hyperlink + '" style="color: #59bad1" target="_blank" rel="noopener noreferrer">' + url + '</a>'
  });
  if(document.getElementById(`message-${openedChatWindowIndex}-${index}`)){
      document.getElementById(`message-${openedChatWindowIndex}-${index}`).innerHTML = mentionMsg === true ? checkMention : msg
  }
}

  const fetchMoreMentionList = ()=>{
    setTablePage(tablePage+1)
  }

  useEffect(()=>{
    (async()=>{
      if(tagText && isSearching){
          const res = await getMentionUsers(patient.room,tagText.replace("@",''),tablePage,rowsPerPage)
          if(res && res.status<350){
              if(tablePage==1){
                  setMentionList([...res.data])
              }else{
                  setMentionList([...mentionList,...res.data])
              }
          }
      } 
    })()
  },[tablePage,textmessage,tagText])

  const isMentionListOpen = mentionList.length !== 0

  const handleMentionList = (message)=>{
      if(message.includes("@")){
        if(message[message.lastIndexOf('@')-1] === undefined || message[message.lastIndexOf('@')-1] === " "){
            setTagText(message.slice(message.lastIndexOf('@')))
          setIsSearching(true)
          return true
        }else{
          setTagText(null)
          setIsSearching(false)
          return false
        }
      }else{
        setTagText(null)
        setIsSearching(false)
        return false
      }
  }
  
  const getMessage=(value)=>{
    let str = value
    for(let i=0;i<tagList.length;i++){
      if(str.includes(`@${tagList[i].participant?.displayName}`))
      str = str.replace(`@${tagList[i].participant?.displayName}`,'')
    }
    return str
  }

  const getTextMessage = (message) =>{
      return message.slice(0,message.lastIndexOf('@'))
  }

  return (
    <>
      <div className={`${classes.root} ${!isChatOpen && 'chatSizing'}`}>
        <div className={classes.chatHeader}>
          <div className={classes.chatHeadName} onClick={openCloseChat}>
          {patient?.isGroup ? 
            <Avatar className={classes.AvatarIcons}  src={groupAvtar} />
            :
            <Avatar className={classes.AvatarIcons}  src={patient?.displayPicture ? patient?.displayPicture : participantAvtar} />
          }
            <Typography variant='h6' className={classes.ChatTitle} title={patient.name}>{patient.name}</Typography>
          </div>
          <div>
            <IconButton className={classes.chatHeadClose} size="small" onClick={() => { setIsChatOpen(false), removeChatWindow(patient) }}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <Collapse orientation="vertical" in={isChatOpen}>
          <div className={classes.chatContent}>
            <div>
              <div
                id={`message_container_${patient.room}`}
                style={{
                  height: 448,
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column-reverse"
                }}
              >
                {/*Put the scroll bar always on the bottom*/}
                <InfiniteScroll
                  dataLength={chatData.length}
                  next={fetchMoreMesssages}
                  style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
                  inverse={true}
                  hasMore={true}
                  scrollableTarget={`message_container_${patient.room}`}
                >
                  {chatData.map((v, index) => (
                    <React.Fragment key={index}>
                      <Grid
                        item
                        xs={12}
                        className={`${v?.sender?.participant?._id === chatUserObj.chatUserId
                          ? "right"
                          : ""
                          }`}
                      >
                        <div className="inner">
                          <div className="message">
                            {v?.payload?.media && v?.payload?.media?.length > 0 ? (
                              <>
                              {v?.type == "media" && v?.payload?.mediaMeta?.ContentType?.split("/")[0] == "audio" ?
                                <div className="audioPlayer">
                                 
                                  <div className="name_date_container">
                                    {v && v.sender && <small className={v?.sender?.participant?._id == chatUserObj?.chatUserId ? v?.sender?.reference == '1-1' ? classes.SenderSecondary : classes.SenderPrimary : classes.ReciverPrimary}>
                                      {v?.sender?.participant?._id === chatUserObj.chatUserId ? `${v.sender.reference == '1-1' ? '' : 'You'}` : v?.sender?.participant?.displayName}
                                    </small>}
                                    <small className={v?.sender?.participant?._id == chatUserObj?.chatUserId ? v?.sender?.reference == '1-1' ? classes.SenderSecondary : classes.SenderSecondary : classes.ReciverSecondary}>
                                      {moment(v?.sentAt).local().format("hh:mm A")}
                                    </small>
                                  </div>

                                  <Audio 
                                      url={v?.payload?.media[0]} 
                                      index={index}
                                      setIsPlaying={setIsPlaying}
                                      isPlaying={isPlaying}
                                      incomingAudio={v?.sender?.participant?._id !== chatUserObj.chatUserId}
                                  />
                                </div>
                              :
                              <div className="message-content-download">
                                
                                <div className="name_date_container">
                                  {v && v.sender && <small className={v?.sender?.participant?._id == chatUserObj?.chatUserId ? v?.sender?.reference == '1-1' ? classes.SenderSecondary : classes.SenderPrimary : classes.ReciverPrimary}>
                                    {v?.sender?.participant?._id === chatUserObj.chatUserId ? `${v.sender.reference == '1-1' ? '' : 'You'}` : v?.sender?.participant?.displayName}
                                  </small>}
                                  <small className={v?.sender?.participant?._id == chatUserObj?.chatUserId ? v?.sender?.reference == '1-1' ? classes.SenderSecondary : classes.SenderSecondary : classes.ReciverSecondary}>
                                    {moment(v?.sentAt).local().format("hh:mm A")}
                                  </small>
                                </div>

                                {downloadLoading?._id === v._id ?
                                  <CircularProgress size={20} />
                                  :
                                  <img src={DownloadIcon} width="30" height="30" onClick={async () => {
                                    setDownloadLoading(v)
                                    await downloadFile(v?.payload?.message, getFileName(v?.payload?.message))
                                    setDownloadLoading(null)
                                  }} />
                                }
                                <a
                                  onClick={() => {
                                    setShowDoc(v?.payload?.message);
                                  }}
                                  target="_blank"
                                  rel="noopener noreferrer"                                  
                                >
                                  <img
                                    src={getIcon(v?.payload?.message)}
                                    alt="document"
                                    width="40"
                                    height="40"
                                  />                                  
                                </a>
                                <small>{getFileName(v?.payload?.message)}</small>
                              </div> 
                              }
                              </> 
                            ) : (
                              <div className="message-content"> 
                                
                                <div className="name_date_container">
                                  {v && v.sender && <small className={v?.sender?.participant?._id == chatUserObj?.chatUserId ? v?.sender?.reference == '1-1' ? classes.SenderSecondary : classes.SenderPrimary : classes.ReciverPrimary}>
                                    {v?.sender?.participant?._id === chatUserObj.chatUserId ? `${v.sender.reference == '1-1' ? '' : 'You'}` : v?.sender?.participant?.displayName}
                                  </small>}
                                  <small className={v?.sender?.participant?._id == chatUserObj?.chatUserId ? v?.sender?.reference == '1-1' ? classes.SenderSecondary : classes.SenderSecondary : classes.ReciverSecondary}>
                                    {moment(v?.sentAt).local().format("hh:mm A")}
                                  </small>
                                </div>
                                
                                <span id={`message-${openedChatWindowIndex}-${index}`} >{getTextMessageWithURL(v?.payload?.message, index)}</span>
                              </div>
                            )}
                          </div>      
                        </div>
                      </Grid>
                      {index === chatData.length - 1 && (
                        <Grid item xs={12} className={classes.dateContainer}>
                          <span className="date">
                            {moment(v.sentAt)
                              .local()
                              .format("DD-MMM-YYYY")}
                          </span>
                        </Grid>
                      )}
                      {index < chatData.length - 1 &&
                        moment(chatData[index + 1].sentAt)
                          .local()
                          .format("DD-MMM-YYYY") !=
                        moment(v.sentAt)
                          .local()
                          .format("DD-MMM-YYYY") && (
                          <Grid item xs={12} className={classes.dateContainer}>
                            <span className="date">
                              {moment(v.sentAt)
                                .local()
                                .format("DD-MMM-YYYY")}
                            </span>
                          </Grid>
                        )}
                    </React.Fragment>
                  ))}
                </InfiniteScroll>
              </div>
            </div>
            <div className={classes.chatFooter}>
                {tagText && isMentionListOpen && <Grid xs={4} className={classes.mentionList}>
                    <List id='mention_container'>
                        <InfiniteScroll
                            dataLength={mentionList?.length}
                            next={fetchMoreMentionList}
                            hasMore={true}
                            scrollableTarget="mention_container"
                        >
                            {mentionList && mentionList.length>0 && mentionList.map((user,index)=>{
                                return (
                                    <ListItem>
                                        <ListItemIcon className={classes.mentionAvtarIcon}>
                                                <Avatar className={classes.mentionAvtar}  src="https://material-ui.com/static/images/avatar/1.jpg" />
                                        </ListItemIcon>
                                        <ListItemText 
                                        onClick={()=>{
                                            setTagList([...tagList,user])
                                            setMentionedUsers([...mentionedUsers, user])
                                            setTextMessage(getTextMessage(textmessage) + `@${user?.participant?.displayName} `)
                                            setTagText(null)
                                            setIsSearching(false)
                                            document.getElementById("textField").focus()
                                        }}>
                                            {user?.participant?.displayName}
                                        </ListItemText>
                                    </ListItem>
                                )
                            })}
                        </InfiniteScroll>
                    </List>
                </Grid>}
              <FileUploadButton chatParticipantInfo={patient} onFileUpload={url => setImageUrl(url)} />
              {imageUrl ? (
                <a href={imageUrl} target="_blank" rel="noopener noreferrer">
                  <img
                    src={getIcon(imageUrl)}
                    alt="document"
                    width="60"
                    height="60"
                  />
                </a>
              ) : (
                <TextareaAutosize
                  id="textField"
                  placeholder="Your text here…"
                  name="textmessage"
                  inputProps={{ style: { fontSize: 14, width: 220 } }}
                  className={classes.chatInput}
                  value={textmessage}
                  onChange={e => {
                    setTextMessage(e.target.value)
                    if(patient?.isGroup){
                        handleMentionList(tagList.length>0 ? getMessage(e.target.value): e.target.value)
                        setTablePage(1) 
                    }
                  }}
                  onKeyDown={handleKeyDown}
                  maxRows={4}
                />
              )}

              <div className={classes.chatActions}>
                <div>
                  {imageUrl && (
                    <IconButton onClick={removeAttachement} size="small">
                      <RemoveCircleIcon fontSize="small" />
                    </IconButton>
                  )}
                </div>
                <div>
                  {request.loading ? (
                    <CircularProgress />
                  ) : (
                    <IconButton size="small" onClick={handleSubmit}>
                      <SendIcon className={classes.SendMessageIcon} fontSize="small" />
                    </IconButton>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
      {showDoc && showDoc.split("?")[0].split(".").pop() =="mp4" ? 
        <CustomVideoRenderer 
            show={showDoc}
            onClose={() => {
              setShowDoc(null);
            }}
        />
        :  
        <DocumentViewer
            open={showDoc}
            onClose={() => {
              setShowDoc(null);
            }}
            title={"View Document"}
            documents={[{ uri: showDoc }]}
        />
      }
    </>
  )
}

export default ChatWindow;
