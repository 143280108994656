import { Grid, Typography } from "@material-ui/core";
import styles from "./styles";
import DummyDoctor from "../../assets/images/dummy_doctor.jpg";
import male from "../../assets/images/GenderMale.svg";
import female from "../../assets/images/GenderFemale.svg";
import ChatCircleDots from "../../assets/images/ChatCircleDots.png";
import Accept_Call from "../../assets/images/Accept_Call.png";
import Decline_Call from "../../assets/images/Decline_Call.png";
import cross from "../../assets/images/cross.png";
import Count from "../../assets/images/count.png";
import React, { useEffect, useLayoutEffect, useState } from "react";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setChatNow, setPickUp } from "../../store/actions/pickup";
import { UpdateBookId, UpdateCallInfo, UpdateInstInfo } from "../../store/actions/instantVideo";
import { sendInstant, stopRepeatCall } from "../Websocket/InstantVideo";
import { useQuery } from "react-query";

import moment from "moment";
import { bookConsultationInstant, InstantUpdateDoctor } from "../../api/Instant/InstantApi";
import Notify from "../Notify";
import { useHistory } from "react-router-dom";
import { USER_ROLES } from "../../utils/constants";
var TimeCount;

const CallPopUp = ({ data, setinstantResponse }: any) => {
  const classes = styles();
  const { pickup ,chatnow} = useSelector((state :any) => state?.call);
  const starttime = sessionStorage.getItem('CountTimer') ?  Number(sessionStorage.getItem('CountTimer')) : 15 ;
  const [request, setRequest] = useState({
    message: "",
    loading: false,
    severity: "",
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const { details } = useSelector((state: any) => state?.clinic);
  const { userRole, user, facility } = useSelector((state: any) => state?.auth);
  const { instantdata } = useSelector((state: any) => state?.instantVideo)
  const getInMilliseconds = (time: Record<string, any> | undefined): number => {
    let milliseconds = 0
    if (!!time) {
      const hours = time.get("hour")
      const minutes = time.get("minute");
      milliseconds = (hours * 60 * 60 * 1000) + (minutes * 60 * 1000)
    }
    return milliseconds
  }
  const receptionist = userRole === USER_ROLES.receptionist || userRole === USER_ROLES.opd_ops || userRole === USER_ROLES.facility_receptionist

  const Timer = () => {
    var countdownNumberEl = document.getElementById("countdown-number");
    var countdown = starttime;
    if (countdownNumberEl !== null) {
      countdownNumberEl.textContent = countdown.toString();

      TimeCount = setInterval(function () {
        if(--countdown <= 0)
        {
          TimeCount && clearInterval(TimeCount);
          callReject(); 
        }
        else
        sessionStorage.setItem("CountTimer", `${countdown}`);

        if (countdownNumberEl !== null) {
          countdownNumberEl.textContent = countdown.toString();
        }
      }, 950);
    }
  };

  const callReject = () => {
    sessionStorage.removeItem('CountTimer');
    dispatch(setPickUp({ pickup: false }));
    receptionist ? sendInstant(JSON.stringify({ 'callId': instantdata?.callId, 'role': 'receptionist', "method": "receptionistReject" })) : sendInstant(JSON.stringify({ 'callId': instantdata?.callId, "method": "doctorReject" }));
  }

  useEffect(() => {
    TimeCount && clearInterval(TimeCount)
    Timer();
  }, []);
  
  const BookConsultation = async () => {
    setRequest({
      message: "",
      loading: true,
      severity: "",
    });
    let Receptionistpayload = {
      "facility": details?.id,
      "roleId": userRole,
      "practitioner": user?._id,
      "primaryUser": instantdata?.user,
      "appointmentCreatedBy": user?._id,
      "appointmentCreatedPersonRole": userRole,
      "date": moment().format('YYYY/MM/DD'),
      "slotStartTime": getInMilliseconds(moment()),
    }
    let DoctorPayload = {
      "bookedId": instantdata?.bookedId,
      "practitioner": user?._id,
      "roleId": userRole,
    }
    try {
      const res = receptionist ? await bookConsultationInstant('AppoinmentCreate', Receptionistpayload) : await InstantUpdateDoctor('DoctorUpdation', DoctorPayload);
      if (res && res?.data) {
        dispatch(UpdateBookId(res?.data?.bookedId));
        dispatch(UpdateCallInfo(true));
        dispatch(setPickUp({ pickup: false }));
        stopRepeatCall();
        const data = res?.data;
        const consultationId = res?.data?.consultationId;
        history.push({
          pathname: `/InstantCall/room`,
          search: `${instantdata?.roomId}`,
          state: {
            row: { data }
          }
        })
      }
      else {
        setRequest({
          message: res?.data?.message || "Something went wrong",
          loading: false,
          severity: "error",
        });
        dispatch(setPickUp({ pickup: false }));
        dispatch(UpdateInstInfo({}))
        setinstantResponse({});
      }
    }
    catch (e) {
      setRequest({
        message: e?.response?.message || "Something went wrong",
        loading: true,
        severity: "error",
      });
      dispatch(setPickUp({ pickup: false }));
      dispatch(UpdateInstInfo({}))
      setinstantResponse({});
    }
  }
  return (
    <>
      {request.message && (
        <Notify message={request.message} severity={request.severity} />
      )}
      <Grid className={classes.popupbox}>
        <Grid container xs={12}>
          <Grid>
            <img
              className={classes.callUserPhoto}
              src={data?.photo || DummyDoctor}
              loading="lazy"
              decoding="async"
            />
          </Grid>
          <Grid>
            <Typography style={{ color: "white" }}>{data?.name}</Typography>
            <div style={{ color: "grey" }}>
              <img src={male} style={{ width: "15px", color: "#717171" }} />{" " + data?.gender}
              , Age: 28 ID: 3231
            </div>
            <p style={{ color: "grey" }}>1 known family connection</p>
            <div>
              <Button
                variant="contained"
                color="success"
                style={{ borderRadius: "20px" }}
                onClick={() => {
                  sessionStorage.removeItem('CountTimer');
                  TimeCount && clearInterval(TimeCount)
                  BookConsultation();
                }}
              >
                <img src={pickup ? Accept_Call : ChatCircleDots} />
                &nbsp; {pickup ? 'Accept' : 'chatNow'}
              </Button>
              <Button
                variant="contained"
                color="error"
                style={{ borderRadius: "20px", marginLeft: "10px" }}
                onClick={() => {
                  sessionStorage.removeItem('CountTimer');
                  TimeCount && clearInterval(TimeCount)
                  receptionist ? sendInstant(JSON.stringify({ 'callId': instantdata?.callId, 'role': 'receptionist', "method": "receptionistReject" })) : sendInstant(JSON.stringify({ 'callId': instantdata?.callId, "method": "doctorReject" }));
                  dispatch(UpdateInstInfo({}))
                  setinstantResponse({});
                  dispatch(setPickUp({ pickup: false }));
                }}
              >
                <img src={pickup ? Decline_Call : cross} />
                &nbsp; {'Reject'}
              </Button>
            </div>
          </Grid>
          <Grid>
            <div id="countdown" className={classes.countdown}>
              <div id="countdown-number" className={classes.countdownumber}></div>
              <svg className={classes.svg}>
                <circle
                  r="18"
                  cx="20"
                  cy="20"
                  className={classes.circle}
                  style={{animation: `countdown ${starttime}s linear infinite forwards`}}
                ></circle>
              </svg>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CallPopUp;
