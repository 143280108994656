import {
  AppBar,
  Button,
  CssBaseline,
  Drawer,
  Icon,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Toolbar,
  useTheme,
  Grid,
  Typography,
  Popover
} from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import logo from "../../assets/images/onboarding/images/login_logo.svg";
import {
  ChevronRight as ChevronRightIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Menu as MenuIcon,
  MoreVert as MoreIcon,
  ChevronLeft as ChevronLeftIcon,
  MenuOpen
} from "@material-ui/icons";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  NavLink as RouterLink,
  useHistory, useLocation
} from "react-router-dom";
import { getClinicAPI } from "../../api/clinic";
import DummyDoctor from "../../assets/images/dummy_doctor.jpg";
import LogoWhite from "../../assets/images/logo.svg";
import LogoWhiteStar from "../../assets/images/Star_logo_white.svg"
import MenuUIIcon from "../../assets/images/menu-icon.svg";
import { MENU_EMPANELMENT_ADMIN, MENU_SUPER_ADMIN, MENU_DOCTOR, MENU_FIELD_AGENT, Receptionist_Login, MENU_FACILITY_ADMIN, TRANSCRIPTION_AGENT, Operational_Receptionist_Login, CLAIMS_PROCESS } from "../../config/menu.config";
import { USER_ROLES } from "../../utils/constants";
import { openWebSocket, setChatRoomList, setChatRoomInfo, setMessageList, setChatParticipantInfo, setOtherInfo, setOpenChatDetails, setEnableWidget, setOpenChatList, setClinicDetails } from "../../store/actions";
import { getRoleName } from "../../utils";
import EnhancedMenu from "./EnhancedMenu";
import { styles } from "./styles";
import { getRefRoleName } from '../../utils/index';
// import { recieve, registerOnMessageCallback, close, send, startWebsocketConnection } from '../../utils/wss';
import ChatWidget from "../ChatWidget";
import { getChatRoomList } from "../../api/chat";
import Notify from "../common/CommonToast";
import Push from "push.js";
import menulogo from "../../assets/images/poweredBySecurraWhite.svg";
import tick from "../../assets/images/charm_tick.png";
import SecurraLogoBlue from "../../assets/images/securraLogoBlue.svg";
import { boolean } from "yup";
import { switchUserRole, updateClinic } from "../../store/actions";
import { padding } from "@mui/system";
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import CallPopUp from "../../components/callPopup";
import {InstantVideo,sendInstant,closeInstant,RepeatCall,stopRepeatCall,ws,calling} from '../Websocket/InstantVideo';
import { User } from "@twilio/conversations";
import {setPickUp,UpdateInstInfo,UpdateCallInfo} from "../../store/actions"
import InstantChat from "../../pages/AppointmentManagement/Consultation/InstantChat";
import { initChatSocket } from "../../components/Websocket/InstantChatSocket";

const useStyles = makeStyles(styles);
const PageSize = 10;

function scrollToBottom(roomId = '') {
  const container = roomId != '' ? document.getElementById(`message_container_${roomId}`) : document.getElementById(`message_container`);
  if (container) {
    let firstTime = true;
    if (firstTime) {
      container.scrollTop = container.scrollHeight;
      firstTime = false;
    } else if (
      container.scrollTop + container.clientHeight ===
      container.scrollHeight
    ) {
      container.scrollTop = container.scrollHeight;
    }
  }
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default function Layout({ children }) {
  const classes = useStyles();
  const theme = useTheme();
  const [isExpand, setIsExpand] = useState(null)
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  //const clinic = useSelector((state) => state?.clinic?.details);
  const clinic = undefined;
  const [showSwitch, setShowSwitch] = useState(false);
  const [showfacility, setshowfacility] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const [facilityList, setfacilityList] = useState([]);
  const facilityId = new URLSearchParams(location.search).get("facilityid")
  const { userRole, user, facility } = useSelector((state) => state?.auth);
  const FirstName = user?.name?.first && user?.name?.first.length > 0 ? user?.name?.first[0].toUpperCase() + user?.name?.first.slice(1) : '';
  const LastName = user?.name?.last && user?.name?.last.length > 0 ? user?.name?.last[0].toUpperCase() + user?.name?.last.slice(1) : '';
  const fullname = FirstName + ' ' + LastName
  //  const { data: clinicData } = useQuery(['getClinic', clinic.id], getClinicAPI);
  const clinicData = undefined; /* Temporary for dev-star-release*/
  const { REACT_APP_LIST_ROWS_PER_PAGE: rowsPerPage } = process.env;
  const [rolesVisible, setrolesVisible] = useState(false);
  const [facilityVisible, setfacilityVisible] = useState(false);
  const [clinicDetails, setclinicDetails] = useState(null);
  const [MENU, setMenu] = useState([])
  const { details } = useSelector((state) => state?.clinic);
  const { pickup ,chatnow} = useSelector((state) => state?.call);
  const { instantdata ,onCall} = useSelector((state)=> state?.instantVideo)
  const [instantResponse,setinstantResponse] =useState({});
  const [socketConnected,setsocketConnected] =useState(false);
  const [request, setRequest] = useState({
    message: "",
    loading: false,
    severity: "",
  });
  const {
    chatRoomList,
    chatUserInfo,
    chatParticipantInfo,
    openChatDetails,
    openChatRooms,
    chatRoomInfo,
    msgList,
    loadingHistory,
    hasMore,
    fetchingNewMessage,
    isChatWidgetEnabled
  } = useSelector((state) => state?.chat);

  useEffect(() => {
    if (user) {
      getChatRooms()
    }
  }, [user])

  // useEffect(() => {
  //   if (user && userRole === "DOCTOR") {
  //     initChatSocket(user.userId, user.name.first+" "+ user.name.last)
  //   }
  // }, [])

  useEffect(() => {
    SetUserRole(userRole);
  }, [userRole])

  useEffect(() => {
    if (!location.pathname.includes('/facility-list') && !location.pathname.includes('/Admin/Slider') && !userRole.includes("UNIFIED_APP_EMPANELMENT_ADMIN") && !location.pathname.includes('/createfacility')) {
      JSON.stringify(details) != '{}' && setRolesList(details?.roles)
      facility && setfacilityList(facility);
      // JSON.stringify(details) != '{}' && setclinicDetails(details);
      user?.practitionerProfile?.service && setShowSwitch(true);
      JSON.stringify(details) != '{}' && setshowfacility(true);
    } else {
      setShowSwitch(false)
      setshowfacility(false);
      !isChatWidgetEnabled && dispatch(setEnableWidget(true));
    }
  }, [location])

  const submenuView = (type, check) => {
    if (type === 'role')
      setrolesVisible(check);
    else
      setfacilityVisible(check);
  }

  useEffect(() => {
    !Push.Permission.has() && !localStorage.getItem('notifyRequest') && Push.Permission.request(onGranted, onDenied);
  }, [])

  const onGranted = () => {
  }

  const onDenied = () => {
    localStorage.setItem('notifyRequest', 'true');
  }

  const getChatRooms = async () => {
    setRequest({ message: "", loading: true, severity: "" });
    if (user.chatUserId && clinic._id) {
      try {
        const res = await getChatRoomList(user.chatUserId, clinic._id);
        if (res && res.status < 350 && res.data) {
          dispatch(setChatRoomList([...res.data]));
        } else {
          setRequest({
            message: res.data.message,
            loading: false,
            severity: "",
          });
        }
      } catch (error) {
        setRequest({
          message: error?.response?.data?.message || "Something went wrong",
          loading: false,
          severity: "error",
        });
      }
    }
  };

  const updateActiveStatus = (event) => {
    const updateChatInfo = chatRoomList.map((ele) => {
      if (event.chatUser.id === ele.formattedRoom.id) {

        ele.formattedRoom.isOnline = true
      }
      return ele
    })
    dispatch(setChatRoomList([...updateChatInfo]))
    dispatch(setChatParticipantInfo({ ...chatParticipantInfo, 'isOnline': true }))


  }

  const updateInActiveStatus = (event) => {
    const updateChatInfo = chatRoomList.map((ele) => {
      if (event.chatUser.id === ele.formattedRoom.id) {

        ele.formattedRoom.isOnline = false
      }
      return ele
    })
    dispatch(setChatRoomList([...updateChatInfo]))
    dispatch(setChatParticipantInfo({ ...chatParticipantInfo, 'isOnline': false }))

  }

  const registerCallback = data => {
    const msg = JSON.parse(data);

    switch (msg.action) {
      case "message-sent":
        if (isChatWidgetEnabled) {
          const roomId = (msg.chat.room ? msg.chat.room._id : msg.chat._id);
          if (Object.keys(openChatRooms).length > 0) {
            dispatch(setOtherInfo({
              fetchingNewMessage: true,
              hasMore: true,
              loadingHistory: false
            }))
            if (openChatDetails[roomId] && openChatDetails[roomId].length === 0) {
              recieve(roomId, rowsPerPage, openChatDetails[roomId].length);
            } else {
              recieve(roomId, 1, 0);
            }
            scrollToBottom(roomId);
          } else if (msg.chat) {
            recieve(roomId, rowsPerPage, 0);
          }
          getChatRooms()
        } else {
          if (Object.keys(chatRoomInfo).length > 0) {
            dispatch(setOtherInfo({
              fetchingNewMessage: true,
              hasMore: true,
              loadingHistory: false
            }))
            if (msgList.length === 0) {
              recieve(chatRoomInfo?._id || msg.chat.room, PageSize, msgList.length);
            } else {
              recieve(chatRoomInfo?._id || msg.chat.room, 1, 0);
              getChatRooms()
            }

            scrollToBottom();
          } else if (msg.chat && !msg.chat.payload) {
            if (!msg.chat.room) {
              dispatch(setChatRoomInfo(msg.chat))
              recieve(msg.chat.id || msg.chat.room, PageSize, 0);
            } else {
              dispatch(setChatRoomInfo(msg.chat.room))
              recieve(msg.chat.room._id || msg.chat.room, PageSize, 0);
            }
          }
        }
        break;
      case "get-messages":
        if (msg.chats?.length) {
          if (isChatWidgetEnabled) {
            if (loadingHistory) {
              dispatch(setOpenChatDetails({ ...openChatDetails, [msg.chats[0].room]: [...openChatDetails[msg.chats[0].room], ...msg.chats], }));
            } else {
              if (fetchingNewMessage) {
                dispatch(setOpenChatDetails({ ...openChatDetails, [msg.chats[0].room]: [...msg.chats, ...(openChatDetails[msg.chats[0].room] ? openChatDetails[msg.chats[0].room] : [])], }));
              } else {
                dispatch(setOpenChatDetails({ ...openChatDetails, [msg.chats[0].room]: [...msg.chats], }));
              }
            }
          } else {
            if (loadingHistory) {
              const tst = [...msgList, ...msg.chats];
              dispatch(setMessageList([...msgList, ...msg.chats]));
            } else {
              if (fetchingNewMessage) {
                dispatch(setMessageList([...msg.chats, ...msgList]));
              } else {
                dispatch(setMessageList([...msg.chats]));
              }
            }
          }
        }
        else {
          dispatch(setOtherInfo({ hasMore: false, loadingHistory, fetchingNewMessage }))
        }
        dispatch(setOtherInfo({
          fetchingNewMessage: false,
          loadingHistory: false,
          hasMore
        }))
        if (loadingHistory == false && msg.chats.length > 0) {
          // send(
          //   JSON.stringify({
          //     action: "message-read",
          //     body: { room: msg.chats[0]?.room }
          //   })
          // );
          // getChatRooms()
        }
        // getChatRooms()
        break;
      case "message-received":
        dispatch(setOtherInfo({
          fetchingNewMessage: true,
          hasMore: true,
          loadingHistory: false
        }))
        if (isChatWidgetEnabled) {
          if (openChatRooms.indexOf(msg?.payload?.chat?.room?._id) !== -1) {
            recieve(msg?.payload?.chat?.room?._id, 1, 0);
          }
        } else {
          if (msg?.payload?.chat?.room?.id == chatRoomInfo?.id) {
            recieve(chatRoomInfo?._id || msg?.payload?.chat?.room, 1, 0);
          }
        }
        scrollToBottom();
        Push.create("Message Received", {
          body: msg.payload.chat.payload.media.length === 0 ? msg.payload.chat.payload.message : "Media Content",
          // timeout: 10000,
          // link:'https://google.com',
          onClick: function () {
            window.focus();
            this.close();
            history.push({
              pathname: `/chats/${msg.payload.chat.room._id}`,
              state: msg.payload
            })
          }
        });
        getChatRooms()
        break;
      case "kept-alive":
        break;
      case "get-rooms":
        dispatch(setOtherInfo({
          loadingHistory: false,
          hasMore,
          fetchingNewMessage
        }))
        break
      case "message-read":
        getChatRooms()
        break;
      case "user-checked-in":
        updateActiveStatus(msg.payload)
        break;
      /**
       * Get the payload from the websocket and Check if the chat room id is availalbe in the chat room array. 
       * if matched change the status true
       * chatRoomList
       *  */
      case "user-checked-out":
        updateInActiveStatus(msg.payload)
        break;
      /**
         * Get the payload from the websocket and Check if the chat room id is availalbe in the chat room array. 
         * if matched change the status false
         * 
         *  */
      default:
        break;
    }
  };

  // React.useEffect(() => {
  //   if (user) {
  //     registerOnMessageCallback(registerCallback);
  //   }
  // }, [user, msgList, loadingHistory, fetchingNewMessage, hasMore, openChatDetails])

  // React.useEffect(() => {
  //   if (user) {
  //     dispatch(openWebSocket({ _id: user.chatUserId }));
  //     startWebsocketConnection(user.chatUserId, undefined, undefined)
  //   }
  // }, [user]);

  React.useEffect(() => {
    if (clinicData) {
      dispatch(setClinicDetails(clinicData));
    }
  }, [clinicData])


  const MENU_ITEMS = [
    {
      key: "1",
      caption: "Calender",
      onClick: () => { },
    },
    {
      key: "2",
      caption: "Notifications",
      onClick: () => { },
    },
    {
      key: "3",
      caption: "My Account",
      onClick: () => {
        history.push("/my-account");
      },
    },
    {
      key: "switch_role",
      caption: "Switch Role",
      subMenuItems: [
        {
          key: "4",
          caption: "Administrator",
          onClick: () => switchRole("administrators"),
        },
        {
          key: "5",
          caption: "Doctor",
          onClick: () => switchRole("doctors"),
        },
      ],
    },
    {
      key: "6",
      caption: "Signout",
      onClick: () => { },
    },
  ];
  const FACILITY_VIEW_ROLE = (userRole === USER_ROLES.facility_admin || userRole === USER_ROLES.super_admin || userRole === USER_ROLES.doctor || userRole === USER_ROLES.field_agent || userRole === USER_ROLES.receptionist || userRole === USER_ROLES.facility_receptionist);
  const DRAWER_EXCLUDED_PATHS = ["/Admin/Slider", "/admin/facility-list", '/createfacility']
  const FACILITY_LIST_DRAWER = ['/admin/facility-list'];
  //const CREATE_FACILITY_SCREEN_DRAWER = !(FACILITY_VIEW_ROLE && (window.location.pathname === '/createfacility' || window.location.pathname === '/createfacility/'))


  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuanchorEl, setmenuAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl) || Boolean(menuanchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const time = new Date(localStorage.getItem('cookie-expires')).getTime() - new Date().getTime()
  const [socketResponse, setSocketResponse] = React.useState('');

  React.useEffect(() => {
    setInterval(() => {
      window.alert('Your session has been expired please login again')
      window.location.href = "/"
    }, time);
  }, [])

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.target);
  };

  const handleProfileMenuClose = (event) => {
    setAnchorEl(null);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <EnhancedMenu
      id={mobileMenuId}
      open={Boolean(isMobileMenuOpen)}
      menuItems={MENU_ITEMS}
      anchorElement={mobileMoreAnchorEl}
      onClose={handleMenuClose}
    />
  );

  const securraLogo = (
    <Link component={RouterLink} to="/">
      {open ?
        <img src={LogoWhite} alt="securra logo" className={classes.logo} /> :
        <img src={LogoWhiteStar} alt="securra logo" className={classes.logo} />
      }
    </Link>
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const findSelected = (currentUrl) => {
    return location.pathname.includes(currentUrl);
  };

  const switchRole = (role) => {
    history.push("/logout")
  };
  const handleMenuClick = () => {
    dispatch(setChatParticipantInfo({}))
    dispatch(setChatRoomInfo({}))
    if (!getCookie("SAID")) {
      window.location.href = "/"
    }
  }

  useEffect(() => {
    if (location) {
      setIsExpand(location.pathname)
    }
  }, [location])

  const hideToolbar = location.pathname.includes("/Admin/Slider")

  const handleExpand = (text) => {
    setIsExpand((preval) => {
      if (preval !== text.url) {
        return text.url
      } else {
        return null
      }
    })
  }

  function SetUserRole(userRole) {
    switch (userRole) {
      case USER_ROLES.super_admin:
        setMenu(MENU_SUPER_ADMIN)
        break;
      case USER_ROLES.facility_admin:
        setMenu(MENU_FACILITY_ADMIN)
        break;
      case USER_ROLES.empanelment_admin:
        setMenu(MENU_EMPANELMENT_ADMIN)
        break;
      case USER_ROLES.doctor:
        setMenu(MENU_DOCTOR)
        break;
      case USER_ROLES.receptionist:
        setMenu(Receptionist_Login);
        break;
      case USER_ROLES.facility_receptionist:
        setMenu(Receptionist_Login);
        break;
      case USER_ROLES.field_agent:
        setMenu(MENU_FIELD_AGENT);
        break;
      case USER_ROLES.transcription_agent:
        setMenu(TRANSCRIPTION_AGENT);
        break;
      case USER_ROLES.opd_ops:
        setMenu(Operational_Receptionist_Login);
        break;
      case USER_ROLES.claims_admin:
        setMenu(CLAIMS_PROCESS);
        break;
      case USER_ROLES.claims_processor:
        setMenu(CLAIMS_PROCESS);
        break;
      default:
        setMenu([]);
        break;
    }
  }
useEffect(()=>{
  if(JSON.stringify(details) !== '{}')
  {
    let receptionist = userRole === USER_ROLES.receptionist || userRole === USER_ROLES.opd_ops || userRole === USER_ROLES.facility_receptionist;
    if((receptionist || userRole === USER_ROLES.doctor) && (ws ? ws?.readyState === 3 : true))
    {
      let doctor = userRole === USER_ROLES.doctor
      let payload = doctor ? {'userId':user?._id,'role':'doctor',"method":"connect"} : {'userId': user?._id,'role':'receptionist',"method":"connect"} ;
      InstantVideo(setinstantResponse,setsocketConnected,payload);
    }

    if(receptionist && !pickup && ws?.readyState === 1 && !calling && !onCall)
    { 
     // sendInstant(JSON.stringify({'userId': user?._id,'role':'receptionist',"method":"connect"}));
      RepeatCall(user?._id,'receptionist','receptionistCheck');
    }

    // if(userRole === USER_ROLES.doctor && !pickup && ws?.readyState === 1 && !onCall)
    // {
    //   sendInstant(JSON.stringify({'userId':user?._id,'role':'doctor',"method":"connect"}));   
    // }

  }
},[userRole,socketConnected,details,pickup,onCall])

useEffect(()=>{
  let role = userRole === USER_ROLES.receptionist || userRole === USER_ROLES.opd_ops || userRole === USER_ROLES.facility_receptionist || userRole === USER_ROLES.doctor;
  if(role)
  {
    if(location.pathname.includes('/InstantCall/room') && !onCall )
    {
     dispatch(UpdateCallInfo(true));
     stopRepeatCall();
    }
    else if(onCall && !location.pathname.includes('/InstantCall/room'))
    {
      dispatch(UpdateCallInfo(false));
    }
  }
},[location])


useEffect(()=>{
  if(JSON.stringify(instantResponse) !== '{}')
   {
    dispatch(UpdateInstInfo(JSON.parse(instantResponse)));
    dispatch(setPickUp({ pickup: true }));
    sessionStorage.setItem('pickup','true');
   }
},[instantResponse])

// /* Remove Notification on tab close*/
  useEffect(()=>{
    if(!sessionStorage.getItem('pickup'))
    dispatch(setPickUp({ pickup: false }));
  },[])

  return (
    <div className={clsx(classes.root)}>
      {/* <InstantChat /> */}
      {pickup && !onCall && sessionStorage.getItem('pickup') && JSON.stringify(instantdata) !== '{}' && <CallPopUp data ={instantdata} setinstantResponse={setinstantResponse}/>}
      {request.message && (
        <Notify message={request.message} severity={request.severity} />
      )}
      <CssBaseline />
      {hideToolbar ? null : <AppBar
        color="default"
        position="fixed"
        className={clsx(classes.appBar, window.location.pathname !== '/createfacility' ? {
          [classes.appBarShift]: open,
        }: classes.appBarFull)}
      >
        <Toolbar>
          {window.location.pathname === '/createfacility' && <div>
            <img src={SecurraLogoBlue} alt="securra logo" />
          </div>}
          <div style={{ textAlign: 'left' }}>
            {!open && <IconButton onClick={handleDrawerOpen}>
              <MenuIcon />
            </IconButton>}
          </div>
          <div className={classes.sectionDesktop}>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              color="inherit"
              disableRipple={true}
              disableFocusRipple={true}
              className={classes.profileButton}
              onMouseEnter={handleProfileMenuOpen}
              onMouseLeave={handleProfileMenuClose}
            >
              <div>
                <img className={classes.profileIcon} src={user?.profilePic || DummyDoctor} loading="lazy" decoding='async' />
              </div>
              <div className={classes.profileTextContainer}>
                {/* <span className={classes.profileName}>{clinic?.name}</span> */}
                <span className={classes.profileRole}>
                  <Typography>{fullname}</Typography>
                  {!(FACILITY_LIST_DRAWER.includes(window.location.pathname)) && user?.practitionerProfile?.service?.map((data) => {
                    if (userRole === data?.practitionerRole?.id) {
                      return data?.practitionerRole?.name;
                    }
                  })}
                </span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {!isMenuOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </div>
              <div className={clsx(classes.profileMenu, isMenuOpen ? "openProfileMenuDropdown" : "profileMenuDropdown")}>
                {showfacility && <div style={{ cursor: 'default', borderBottom: '1px solid #E6E6E6' }}>
                  <span style={{ color: '#828282', paddingTop: '10%' }}>Facility</span>
                  <div style={{ display: 'flex', flexDirection: "row", padding: '5%', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div style={{
                      height: "40px", width: "40px", backgroundImage: `url(${details?.logo})`, borderRadius: '2rem', marginRight: '10px', backgroundSize: "cover",
                      backgroundPosition: "center"
                    }}></div>
                    <div style={{ color: '#333333', wordWrap: 'break-word' }}>{details?.name}</div>
                  </div>
                </div>}
                {showSwitch && rolesList?.length >= 2 && <div style={{ borderBottom: '1px solid #E6E6E6' }} onMouseEnter={() => { submenuView('role', true) }}
                  onMouseLeave={() => { submenuView('role', false) }}>
                  <div style={{ color: '#828282', padding: '16px', paddingLeft: "8px" }}
                  >Roles
                    <div className={clsx(classes.subMenuRoles, rolesVisible ? "openProfileMenuDropdown" : "profileMenuDropdown")}>
                      {rolesList?.map((data, i) => {
                        return (
                          <div style={{ listStyleType: 'none', marginLeft: '0', color: '#000000', padding: '8px', display: 'flex', justifyContent: "space-between" }} onClick={() => {
                            if(ws?.readyState === 0 || ws?.readyState === 1)
                            {closeInstant();}
                            dispatch(switchUserRole(data?._id));
                            history.replace('/');
                            // setTimeout(()=>{
                            //   history.go(0)
                            // },100)
                          }}>
                            {data?.name}
                            {userRole && userRole.includes(data?._id) ? <img src={tick} /> : ''}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>}
                {showfacility && facilityList?.length >= 2 && <div style={{ color: '#828282', padding: '16px', borderBottom: '1px solid #E6E6E6' }} onMouseEnter={() => { submenuView('facility', true) }} onMouseLeave={() => { submenuView('facility', false) }}>Change Facility
                  <div className={clsx(classes.subMenufacility, facilityVisible ? "openProfileMenuDropdown" : "profileMenuDropdown")}>
                    {facilityList?.map((data, i) => {
                      return (
                        <div key={i} className={details?.id.includes(data?.id) ? classes.activeback : facilityList.length - 1 === i ? classes.grey : clsx(classes.borderbottom, classes.grey)} style={{ listStyleType: 'none', marginLeft: '0', color: '#000000', padding: '8px', display: 'flex', justifyContent: "space-between", alignItems: 'center' }} onClick={() => {
                          if(ws?.readyState === 0 || ws?.readyState === 1)
                            {closeInstant();}
                          dispatch(switchUserRole(data?.roles[0]?._id));
                          dispatch(setClinicDetails(data))
                          history.replace('/');
                          // setTimeout(()=>{
                          //   history.go(0)
                          // },100)
                        }}>
                          <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center' }}>
                            <div style={data?.logo ? {
                              height: "40px", width: "40px", backgroundImage: `url(${data?.logo})`, borderRadius: '2rem', marginRight: '10px', backgroundSize: "cover",
                              backgroundPosition: "center"
                            } : {}}></div>
                            {!data?.logo && <span style={{ marginRight: '25px' }}><LocalHospitalIcon /></span>}
                            <div>{data?.name}</div>
                          </div>
                          <div>{details && details?.id.includes(data?.id) ? <img src={tick} height={'20px'} /> : ''}</div>
                        </div>
                      )
                    })}
                  </div>
                </div>}
                <div onClick={() => history.push("/logout")} style={{ color: '#000000', padding: '16px' }}>Logout</div>
              </div>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>}
      {renderMobileMenu}
      <div className={clsx(classes.root)}>
        {!DRAWER_EXCLUDED_PATHS.includes(window.location.pathname) && <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx(classes.ScrollDrawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={open ? classes.toolbar : classes.toolbarClose}>
            {securraLogo}
            {open && <IconButton onClick={handleDrawerClose}>
              <img src={MenuUIIcon} />
            </IconButton>}
          </div>
          {/* <Divider /> */}
          <List>
            {!open && MENU.map((text, index) => {
              return (
                <List
                  key={index}
                  title={text.label}
                  className={clsx(classes.MobileMenuLink, classes.menuLink, open === false ? classes.customhoverlink : "", text.disabled ? 'disabled' : '')}
                >

                  <ListItem
                    button
                    disableRipple={text.disabled}
                    disableTouchRipple={text.disabled}
                    disableFocusRibble={text.disabled}
                    selected={findSelected(text.url)}
                    className={clsx(classes.menuItem, 'menu-item-mobile')}
                  >
                    <ListItemIcon>
                      <Icon>{text.icon}</Icon>
                    </ListItemIcon>
                    <List component="ul" className={clsx(classes.nested, classes.MobileMenuDropdown, 'onhover-open')}>
                      <Link
                        to={text.url}
                        disablePadding
                        component={!text.disabled ? RouterLink : undefined}
                        onClick={() => handleMenuClick()}
                      >
                        <ListItemText className={classes.MenuTopPrimaryWrap} classes={{ primary: classes.MenuTopPrimary }} primary={text.label} />
                      </Link>
                      {text.submenu.map((subMenuItem, submenuIndex) => (
                        <List component="li" disablePadding className={classes.nestedOnMobile}>
                          <Link
                            component={RouterLink}
                            to={subMenuItem.url}
                            key={submenuIndex}
                            title={subMenuItem.label}
                            className={classes.menuLink}
                            onClick={() => handleMenuClick()}
                          >
                            <ListItemText primary={subMenuItem.label} />
                          </Link>
                        </List>
                      ))}
                    </List>
                    {/* <ListItemText primary={text.label} /> */}
                  </ListItem>
                </List>
              )
            })}
            {open && MENU.map((text, index) => {
              return (
                <Link
                  component={!text.disabled ? RouterLink : undefined}
                  to={text.url}
                  key={index}
                  title={text.label}
                  className={`${classes.menuLink} ${open === false ? classes.customhoverlink : ""} ${text.disabled ? 'disabled' : ''}`}
                  onClick={() => handleMenuClick()}
                >
                  <ListItem
                    button
                    disableRipple={text.disabled}
                    disableTouchRipple={text.disabled}
                    disableFocusRibble={text.disabled}
                    selected={findSelected(text.url)}
                    className={`${classes.menuItem} ${text.disabled ? 'disabled' : ''}`}
                    onClick={() => handleExpand(text)}
                  >
                    <ListItemIcon>
                      <Icon>{text.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText primary={text.label} />
                  </ListItem>
                  <Collapse
                    in={text.submenu.length > 0 && open}
                    timeout="auto"
                    className='collaps-menu'
                  >
                    <Collapse in={isExpand ? isExpand.includes(text.url) : false} timeout="auto">
                      <List className={clsx(classes.nested)}>
                        {text.submenu.map((subMenuItem, submenuIndex) => (
                          <Link
                            component={RouterLink}
                            to={subMenuItem.url}
                            key={submenuIndex}
                            title={subMenuItem.label}
                            className={classes.menuLink}
                            onClick={() => handleMenuClick()}
                          >
                            <ListItem
                              button
                              selected={findSelected(subMenuItem.url)}
                              className={classes.menuItem}
                            >
                              <ListItemIcon>
                                <Icon>{subMenuItem.icon}</Icon>
                              </ListItemIcon>
                              <ListItemText primary={subMenuItem.label} />
                            </ListItem>
                          </Link>
                        ))}
                      </List>
                    </Collapse>
                  </Collapse>
                </Link>
              )
            })}
          </List>
          {
            open &&
            <div className={classes.menuBottom}>
              <img src={menulogo} alt="securra" style={{ width: "160px" }} />
              <span className={classes.version}>v {process.env.REACT_APP_DEPLOYED_VERSION}</span>
            </div>
          }
        </Drawer>}
        {FACILITY_LIST_DRAWER.includes(window.location.pathname) && FACILITY_VIEW_ROLE && <Drawer
          variant="permanent"
          className={clsx(classes.Sliderdrawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx(classes.ScrollDrawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}>
          <div className={classes.root}>
            <div className={''}>
              {/* <Grid container> */}
              <div className={classes.baseContainer}>
                <img src={logo} alt="star-health" className={classes.logo}></img>
              </div>
              {/* </Grid> */}
            </div>
          </div>
        </Drawer>}
        <main className={hideToolbar ? clsx(window.location.pathname !== '/createfacility' ? classes.content : classes.contentWhite, classes.listedContent) : window.location.pathname !== '/createfacility' ? classes.content : classes.contentWhite}>
          <div className={classes.mainRoot} />
          <div className={hideToolbar ? null : classes.toolbar} />
          {children}
        </main>
      </div>
      {/* {
        isChatWidgetEnabled && <ChatWidget />
      } */}
      {/* <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={''}
    //  className={clsx(classes.popoverRoot, props.popoverClass)}
      anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
      }}
      transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
      }}>
      <div>HI</div>
      </Popover>  */}
    </div>
  );
}
