import { makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import waveWhite from "../../../assets/images/audio-wave.png"
import waveGrey from "../../../assets/images/wave-incoming.png"
import pauseIcon from "../../../assets/images/pause.png"
import playIcon from "../../../assets/images/play.png"
import pauseIncomingIcon from "../../../assets/images/pause-incoming.png"
import playIncomingIcon from "../../../assets/images/play-incoming.png"

import { IconButton } from '@material-ui/core';

const useStyles = makeStyles({
    wave : {
        width:"230px !important",
        filter:"unset !important"
    },
    payPauseIcon : {
        filter : "unset !important"
    },
    audioPlayerContainer:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        '& span:not(.MuiIconButton-label)':{
            minWidth:50,
            maxWidth:50,
        }
    },
    sliderOutgoing:{
        width: '100%',
        margin: 'auto 20px auto 0',
        display: 'block',
        maxWidth: '66%',
        "&::-webkit-slider-runnable-track" : {
            width: "100%",
            height: "5px",
            cursor: "pointer",
            animate: "0.2s",
            boxShadow: "none",
            background: "#8499CD",
            borderRadius: "25px",
            border: "none",
            outline:'none'
        },
        "&::-webkit-slider-thumb": {
            boxShadow: "none",
            height: "5px",
            width: "5px",
            marginTop:-5,
            borderRadius: "6px",
            filter: 'brightness(0) invert(1)',
            fill: '#fff',
            cursor: "pointer",
            '-webkit-appearance': "none",
          }
    },
    sliderIncoming:{
        width: '100%',
        margin: 'auto 20px auto 0',
        display: 'block',
        maxWidth: '66%',
        "&::-webkit-slider-runnable-track" : {
            width: "100%",
            height: "5px",
            cursor: "pointer",
            animate: "0.2s",
            boxShadow: "none",
            background: "#9CB2C4",
            borderRadius: "25px",
            border: "none",
            outline:'none'
        },
        "&::-webkit-slider-thumb": {
            boxShadow: "none",
            height: "5px",
            width: "5px",
            marginTop:-5,
            borderRadius: "6px",
            cursor: "pointer",
            '-webkit-appearance': "none",
            filter: 'brightness(0) saturate(100%) invert(48%) sepia(29%) saturate(409%) hue-rotate(164deg) brightness(93%) contrast(93%)'
          }
    },
    timer:{
        textAlign: 'center',
        color: '#5E7D95'        
    }
    
});

const formatTime = function (time) {
    return [
        Math.floor((time % 3600) / 60), // minutes
        ('00' + Math.floor(time % 60)).slice(-2) // seconds
    ].join(':');
};

export default function TestAudio({ url , index, isPlaying, setIsPlaying, incomingAudio}) {
    const audioPlayer = useRef();
    const classes = useStyles();
    
    const [currentTime, setCurrentTime] = useState(0);
    const [seekValue, setSeekValue] = useState(0);

    const play = () => {
        setIsPlaying(index)
        audioPlayer.current.play();
    };

    const pause = () => {
        setIsPlaying(null)
        audioPlayer.current.pause();
    };

    const onPlaying = () => {
        
        setCurrentTime(audioPlayer.current.currentTime);
        setSeekValue(
            (audioPlayer.current.currentTime / audioPlayer.current.duration) * 100
        );
    };

    useEffect(()=>{
        if(seekValue===100){
            setSeekValue(0)
            setCurrentTime(0)
            audioPlayer.current.currentTime = 0
            setIsPlaying(null)
        }
    },[seekValue])
    return (
        <>
            <div className={classes.audioPlayerContainer}>
                <div>
                    {isPlaying === index ? 
                        <IconButton onClick={pause}>
                            <img src={incomingAudio ? pauseIncomingIcon : pauseIcon} className={classes.payPauseIcon}/>
                        </IconButton> : 
                        <IconButton onClick={play}>
                            <img src={incomingAudio ? playIncomingIcon :playIcon} className={classes.payPauseIcon}/>
                        </IconButton>
                        }
                </div>
                {isPlaying !== index ?  <img className={classes.wave} src={incomingAudio ? waveGrey : waveWhite} />  :
                <input
                    type="range"
                    min="0"
                    max="100"
                    step="1"
                    value={seekValue}
                    className={incomingAudio ? classes.sliderIncoming : classes.sliderOutgoing}
                    onChange={(e) => {
                        const seekto = audioPlayer.current.duration * (+e.target.value / 100);
                        audioPlayer.current.currentTime = seekto;
                        setSeekValue(e.target.value);

                    }}
                /> }
                <audio
                    src={url}
                    ref={audioPlayer}
                    onTimeUpdate={onPlaying}
                >
                </audio>
                <span className={incomingAudio ? classes.timer : ""}>{formatTime(currentTime)}</span>
                
            </div>


        </>
    );
}